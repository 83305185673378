import { Component, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateComponent } from 'libs/shared/src/lib/component/ui/date-component/date-component.component';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OneilDateComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: OneilDateComponent,
    },
  ],
})
export class OneilDateComponent extends DateComponent {
  constructor() {
    super();
  }
}
