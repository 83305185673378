import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, from, Observable, of, zip } from 'rxjs';
import { catchError, expand, flatMap, groupBy, map, mergeAll, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { plainToInstance } from 'class-transformer';
import { MovieDataProvider } from './movie.data-provider';
import { RegionDataProvider } from './region.data-provider';
import { ScreeningHttpService } from '../http/screening.http.service';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { MovieHttpService } from '../http/movie.http.service';
import { GenreHttpService } from '../http/genre.http.service';
import { CinemaHttpService } from '../http/cinema.http.service';
import { ScreenheadHttpService } from '../http/screenhead.http.service';
import { EventDataProvider } from './event.data-provider';
import { ScreeningRequestModel } from '../model/request/screening.request.model';
import { MovieRequestModel } from '../model/request/movie.request.model';
import { MovieCopyRequestModel } from '../model/request/movie-copy.request.model';
import { OccupancyViewModel } from '../model/view-model/screening/occupancy/occupancy.view.model';
import { OccupancyApiModel } from '../model/api-model/screening/occupancy/occupancy.api.model';
import { TicketViewModel } from '../model/view-model/shared/ticket/ticket.view.model';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';
import { ScreeningViewModel } from '../model/view-model/screening/screening.view.model';
import { ScreeningApiModel } from '../model/api-model/screening/screening.api.model';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { ScreeningPeriodEnum } from '../model/enum/screening-period.enum';
import { IGroupingOption, IGroupingOrderGroup, IMoviePackage, IScreeningItem, IScreeningOption } from '../interfaces';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { RegionViewModel } from '../model/view-model/region/region.view.model';
import { ScreeningDetails } from 'libs/webcomponent/src/lib/wp-model/adapters';
import { ScreenheadApiModel } from '../model/api-model/screen/screen-head.api.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { ScreenheadViewModel } from '../model/view-model/screen/screen-head.view.model';
import { CinemaApiModel } from '../model/api-model/cinema/cinema.api.model';
import { ScreeningType } from '../enum/screening-type';
import { convertDayToMillis } from '../date/date.helper';
import { EventRequestModel } from '../model/request/event.request.model';
import { DateTimeService } from '../service/datetime.service';
import { GenreApiModel } from '../model/api-model/genre/genre.api.model';
import { GaTicketViewModel } from '../model/view-model/screening/ga/ga-ticket.view.model';
import { GaTicketApiModel } from '../model/api-model/screening/ga/ga-ticket.api.model';
import { SeparatorViewModel } from '../model/view-model/screening/separator/separator.view.model';
import { SeparatorApiModel } from '../model/api-model/screening/separator/separator.api.model';
import { ScreeningAvailabilityStatus } from '../enum/screening-availability-status.enum';
import { GenreViewModel } from '../model/view-model/genre/genre.view.model';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { ScreeningObject } from '../model/screening-object.model';
import { TimeRangeObject } from '../date/time.model';
import { flatten } from 'lodash-es';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ScreeningDataProvider {
  environment: any;

  constructor(
    private screeningHttpService: ScreeningHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private movieHttpService: MovieHttpService,
    private genreHttpService: GenreHttpService,
    private cinemaHttpService: CinemaHttpService,
    private screenheadHttpService: ScreenheadHttpService,
    private eventDataProvider: EventDataProvider,
    private movieDataProvider: MovieDataProvider,
    private regionDataProvider: RegionDataProvider,
    private dateTimeService: DateTimeService
  ) {}

  list(
    screeningRequestModel: ScreeningRequestModel,
    movieCopyRequestModel: MovieCopyRequestModel,
    movieRequestModel: MovieRequestModel
  ): Observable<MoviePrintViewModel[]> {
    const result = forkJoin([
      this.screeningHttpService.listByApiModel(screeningRequestModel).pipe(map((models) => models.map((m) => new ScreeningViewModel(m)))),
      this.movieCopyHttpService.listViaApiModel(movieCopyRequestModel).pipe(map((models) => models.map((m) => new MoviePrintViewModel(m)))),
      this.movieHttpService.listViaApiModel(movieRequestModel).pipe(map((models) => models.map((m) => new MovieViewModel(m)))),
    ]);

    return result.pipe(
      map(([screeningsResponse, movieCopiesResponse, moviesResponse]: [ScreeningViewModel[], MoviePrintViewModel[], MovieViewModel[]]) => {
        return movieCopiesResponse.filter((item) => {
          item.movie = moviesResponse.find((movie) => movie.id === item.movieId);
          item.screenings = screeningsResponse
            .sort((s) => this.dateTimeService.convertToCinemaTimeZone(s.screeningTimeFrom).toMillis())
            .filter((screening) => screening.moviePrintId === item.id);
          return item;
        });
      })
    );
  }

  getOccupancyList(cinemaId: string, id: string): Observable<OccupancyViewModel> {
    return this.screeningHttpService.getOccupancyList<OccupancyApiModel>(cinemaId, id).pipe(
      catchError((err) => {
        throw err;
      }),
      map((res: OccupancyApiModel) => new OccupancyViewModel(res))
    );
  }

  getScreeningsByMovieId(cinemaId: string, movieId: string, dateTimeFrom: string, dateTimeTo: string): Observable<ScreeningViewModel[]> {
    return this.screeningHttpService
      .getScreeningsByMovieIdViaApiModel(cinemaId, movieId, dateTimeFrom, dateTimeTo)
      .pipe(map((models) => models.map((model) => new ScreeningViewModel(model))));
  }

  getTicketListViaApiModel(cinemaId: string, id: string, seatIds: Array<string>): Observable<TicketViewModel[]> {
    return this.screeningHttpService
      .getTicketList<TicketApiModel>(cinemaId, id, seatIds)
      .pipe(map((models) => models.map((model) => new TicketViewModel(model))));
  }

  getTicketList(cinemaId: string, id: string, seatIds: Array<string>): Observable<TicketViewModel[]> {
    return this.screeningHttpService.getTicketList<TicketApiModel>(cinemaId, id, seatIds).pipe(
      map((ticketApiModels: TicketApiModel[]) => {
        return ticketApiModels.map((ticketApiModel) => new TicketViewModel(ticketApiModel));
      })
    );
  }

  getTicketListGeneralAdmission(cinemaId: string, screeningId: string): Observable<GaTicketViewModel[]> {
    return this.screeningHttpService.getTicketListGeneralAdmission<GaTicketApiModel>(cinemaId, screeningId).pipe(
      map((gaTicketApiModels: GaTicketApiModel[]) => {
        return gaTicketApiModels.filter((c: GaTicketViewModel) => c.price != 0).map((gaTicketApiModel) => new GaTicketViewModel(gaTicketApiModel));
      })
    );
  }

  getRowSeparators(cinemaId: string, id: string): Observable<SeparatorViewModel[]> {
    return this.screeningHttpService.getRowSeparators<SeparatorApiModel>(cinemaId, id).pipe(
      map((models: SeparatorApiModel[]) => {
        return models.map((model) => new SeparatorViewModel(model));
      })
    );
  }

  findScreeningByIdViaApiModel(cinemaId: string, id: string): Observable<ScreeningViewModel> {
    return this.screeningHttpService.findByIdViaApiModel(cinemaId, id).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object)),
      map((res) => new ScreeningViewModel(res))
    );
  }

  findById(cinemaId: string, id: string): Observable<MoviePrintViewModel> {
    return this.screeningHttpService.findByIdViaApiModel(cinemaId, id).pipe(
      flatMap((screeningResponse) => {
        return this.movieCopyHttpService.findByIdViaApiModel(screeningResponse.moviePrintId).pipe(
          switchMap((moviePrintApiModel: MoviePrintApiModel) =>
            forkJoin([this.movieHttpService.findByIdViaApiModel(moviePrintApiModel.movieId), this.genreHttpService.list()]).pipe(
              map(([movieResponse, genreResponse]: [MovieApiModel, GenreApiModel[]]) => {
                const moviePrintViewModel = new MoviePrintViewModel(moviePrintApiModel);
                moviePrintViewModel.screenings = [new ScreeningViewModel(screeningResponse)];
                moviePrintViewModel.movie = new MovieViewModel(movieResponse);

                const includeGenres = genreResponse.filter((genreResponseModel: GenreApiModel) => {
                  return movieResponse.genres.map((m) => m.id).includes(genreResponseModel.id);
                });

                moviePrintViewModel.movie.genres = includeGenres.map((m) => new GenreViewModel(m));
                return moviePrintViewModel;
              })
            )
          )
        );
      })
    );
  }

  // via API model
  findByIdViaApiModel(cinemaId: string, id: string) {
    return this.screeningHttpService.findByIdViaApiModel(cinemaId, id).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object)),
      mergeMap((screeningResponse) => {
        return this.movieCopyHttpService.findByIdViaApiModel(screeningResponse.moviePrintId).pipe(
          switchMap((movieCopyResponse: MoviePrintApiModel) =>
            this.movieDataProvider.getMovieById(movieCopyResponse.movieId).pipe(
              map((movie: MovieViewModel) => {
                return {
                  copy: new MoviePrintViewModel(movieCopyResponse),
                  screenings: new ScreeningViewModel(screeningResponse),
                  screen: null,
                  movie: movie,
                  movieInfo: null,
                };
              })
            )
          )
        );
      })
    );
  }

  listNearestByMovieId(movieId: string, cinemaId: string, dateFrom: string, dateTo: string) {
    return this.screeningHttpService
      .getScreeningsByMovieIdViaApiModel(cinemaId, movieId, dateFrom, dateTo)
      .pipe(map((models: ScreeningApiModel[]) => models.map((model: ScreeningApiModel) => this.makeScreeningItem(model))));
  }

  listNearestByEventId(eventId: string, cinemaId: string, dateFrom: DateTime, dateTo: DateTime) {
    const screeningRequestModel = new ScreeningRequestModel().deserialize(cinemaId, dateFrom, dateTo);
    let screeningsSource = this.screeningHttpService
      .listByApiModel(screeningRequestModel)
      .pipe(map((res) => plainToInstance(ScreeningApiModel, res as object[])));

    return this.eventDataProvider.listViaApiModel(new EventRequestModel(cinemaId, dateFrom, dateTo), eventId).pipe(
      map((events) => {
        return events.map((event) => this.makeScreeningItemByEvent(event));
      })
    );
  }

  getOccupancyListViaApiModel(cinemaId: string, id: string): Observable<OccupancyViewModel> {
    return this.screeningHttpService.getOccupancyList<OccupancyApiModel>(cinemaId, id).pipe(
      catchError((err) => {
        throw err;
      }),
      map((occupancyApiModel: OccupancyApiModel) => new OccupancyViewModel(occupancyApiModel))
    );
  }

  listByRegion(screeningRequestModel: ScreeningRequestModel) {
    return this.screeningHttpService.listByRegion(screeningRequestModel).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object[], { strategy: 'excludeAll' })),
      map((models: ScreeningApiModel[]) => models.map((model: ScreeningApiModel) => new ScreeningViewModel(model)))
    );
  }

  getGroupedByCinemaId(screeningRequestModel: ScreeningRequestModel) {
    return this.screeningHttpService.listByRegion(screeningRequestModel).pipe(
      map((res) => plainToInstance(ScreeningApiModel, res as object[], { strategy: 'excludeAll' })),
      mergeAll(),
      groupBy((item) => item.cinemaId),
      mergeMap((group) => zip(of(group.key), group.pipe(toArray())))
    );
  }

  getGrouped(regionId: string, startAt: DateTime, period: ScreeningPeriodEnum): Observable<IMoviePackage[]> {
    const dateFrom = startAt ? startAt : this.dateTimeService.local();
    const dateTo = period === ScreeningPeriodEnum.WEEK ? dateFrom.plus({ days: 7 }) : dateFrom;
    const screeningRequestModel = new ScreeningRequestModel().forRegion(regionId, dateFrom, dateTo);

    return this.getGroupedByCinemaId(screeningRequestModel).pipe(
      mergeMap((groupByCinemaId) => {
        const cinemaId = groupByCinemaId[0],
          screenings = groupByCinemaId[1];
        const movieCopyRequestModel = new MovieCopyRequestModel().deserialize(cinemaId, dateFrom, dateTo);
        const movieRequestModel = new MovieRequestModel().deserialize(cinemaId, dateFrom, dateTo);

        return forkJoin({
          movieCopies: this.movieCopyHttpService.listViaApiModel(movieCopyRequestModel),
          movies: this.movieDataProvider.getMovies(movieRequestModel),
          cinemas: this.cinemaHttpService.listViaApiModel(),
          genres: this.genreHttpService.list(),
        }).pipe(
          map((source) => {
            const cinema = source.cinemas.find((o) => o.id === cinemaId);

            return source.movieCopies.map((moviePrint) =>
              this.makeMoviePackage(
                moviePrint,
                source.movies.find((movie) => movie.id === moviePrint.movieId),
                cinema,
                screenings
              )
            );
          }),
          catchError((o) => {
            return of([] as IMoviePackage[]);
          })
        );
      })
    );
  }

  getScreeningDetailsById(cinemaId: string, screeningId: string, events?: EventViewModel[], region?: RegionViewModel): Observable<ScreeningDetails> {
    const event = events?.find((x) => x.screeningId === screeningId);
    const screening = event
      ? of(event)
      : this.screeningHttpService
          .findByIdViaApiModel(cinemaId, screeningId)
          .pipe(map((res) => plainToInstance(ScreeningApiModel, res as object, { strategy: 'excludeAll' })));

    return forkJoin({ screening }).pipe(
      switchMap((predata) => {
        return forkJoin({
          moviePrint: predata.screening instanceof EventViewModel ? of(null) : this.movieCopyHttpService.findByIdViaApiModel(predata.screening.moviePrintId),
          movie:
            predata.screening instanceof EventViewModel
              ? of(
                  Object.assign(new MovieViewModel(), {
                    genres: predata.screening.genres,
                    title: predata.screening.title,
                    duration: predata.screening.duration,
                    posters: predata.screening.posters,
                    ratings: predata.screening.ratings,
                  })
                )
              : this.movieDataProvider.getMovieById(predata.screening.movieId),
          event: of(event),
          cinema: this.cinemaHttpService.findByIdViaApiModel(predata.screening.cinemaId),
          region: !region ? this.regionDataProvider.findByCinemaId(predata.screening.cinemaId) : of(region),
          screen: this.screenheadHttpService
            .findById<ScreenheadApiModel>(predata.screening.cinemaId, predata.screening.screenId)
            .pipe(catchError((err) => of(null))),
        }).pipe(
          map(
            (data) =>
              new ScreeningDetails(
                data.movie,
                data.event,
                predata.screening instanceof EventViewModel ? null : new MoviePrintViewModel(data.moviePrint),
                data.region,
                new CinemaViewModel(data.cinema),
                new ScreeningViewModel(
                  predata.screening instanceof EventViewModel
                    ? Object.assign(new ScreeningApiModel(), {
                        id: predata.screening.screeningId,
                        screeningTimeFrom: predata.screening.timeFrom,
                        availabilityStatus: predata.screening.availabilityStatus,
                      })
                    : predata.screening
                ),
                new ScreenheadViewModel(data.screen)
              )
          )
        );
      })
    );
  }

  private makeScreeningItem(model: ScreeningApiModel): IScreeningItem {
    return {
      id: model.id,
      cinemaId: model.cinemaId,
      screenId: model.screenId,
      startAt: DateTime.fromISO(model.screeningTimeFrom, { setZone: true }),
      saleTimeTo: DateTime.fromISO(model.saleTimeTo, { setZone: true }),
      reservationTimeTo: DateTime.fromISO(model.reservationTimeTo),
      printType: model.printType,
      content: model,
      inactive: model.availabilityStatus === ScreeningAvailabilityStatus.ForPreview,
    } as IScreeningItem;
  }

  private makeScreeningItemByEvent(model: EventViewModel): IScreeningItem {
    return {
      id: model.id,
      cinemaId: model.cinemaId,
      screenId: model.screenId,
      startAt: model.timeFrom,
      saleTimeTo: model.saleTimeTo,
      reservationTimeTo: model.reservationTimeTo,
      printType: model.movieCopy?.printType,
      content: model,
      inactive: model.availabilityStatus === ScreeningAvailabilityStatus.ForPreview,
    } as IScreeningItem;
  }

  private makeMoviePackage(moviePrintApi: MoviePrintApiModel, movie: MovieViewModel, cinemaApi: CinemaApiModel, screeningApiModelList: ScreeningApiModel[]) {
    return {
      moviePrint: new MoviePrintViewModel(moviePrintApi),
      movie: movie,
      cinema: new CinemaViewModel(cinemaApi),
      screenings: screeningApiModelList.filter((o) => o.moviePrintId === moviePrintApi.id).map((o) => this.makeScreeningItem(o)),
    } as IMoviePackage;
  }

  getScreenings(regionId: string, startAt: DateTime, period: ScreeningPeriodEnum, option?: IScreeningOption) {
    const dateFrom = startAt.startOf('day').plus({ hours: option?.cinemaDayOffset ?? 0 });
    const dateTo = startAt
      .endOf('day')
      .plus({ hours: option?.cinemaDayOffset ?? 0 })
      .plus({ days: period });

    return this.getScreeningsObservable(regionId, dateFrom, dateTo, option);
  }

  getScreeningsByTimeRange(regionId: string, startAt: DateTime, period: ScreeningPeriodEnum, timeRangeObject: TimeRangeObject, option?: IScreeningOption) {
    const dateFrom = startAt.startOf('day').plus({ hours: timeRangeObject?.from?.hour ?? 0, minute: timeRangeObject?.from?.minute ?? 0 });
    const dateTo = startAt
      .startOf('day')
      .plus({ hours: timeRangeObject?.to?.hour ?? 0, minute: timeRangeObject?.to?.minute ?? 0 })
      .plus({ days: period });

    return this.getScreeningsObservable(regionId, dateFrom, dateTo, option).pipe(
      tap((data: ScreeningObject[]) => {
        data.forEach((s) => {
          s.screenings = s.screenings.filter(
            (s) =>
              this.dateTimeService.convertToCinemaTimeZone(s.screeningTimeFrom) >=
                this.dateTimeService.convertToCinemaTimeZone(
                  DateTime.fromObject({
                    year: DateTime.fromISO(s.screeningTimeFrom).year,
                    month: DateTime.fromISO(s.screeningTimeFrom).month,
                    day: DateTime.fromISO(s.screeningTimeFrom).day,
                    hour: timeRangeObject?.from?.hour,
                    minute: timeRangeObject?.from?.minute,
                  })
                ) &&
              this.dateTimeService.convertToCinemaTimeZone(s.screeningTimeFrom) <=
                this.dateTimeService.convertToCinemaTimeZone(
                  DateTime.fromObject({
                    year: DateTime.fromISO(s.screeningTimeFrom).year,
                    month: DateTime.fromISO(s.screeningTimeFrom).month,
                    day: DateTime.fromISO(s.screeningTimeFrom).day,
                    hour: timeRangeObject?.to?.hour,
                    minute: timeRangeObject?.to?.minute,
                  })
                )
          );
        });
      })
    );
  }

  private getScreeningsObservable(regionId: string, dateFrom: DateTime, dateTo: DateTime, option: IScreeningOption) {
    const screeningRequestModel = new ScreeningRequestModel().forRegion(regionId, dateFrom, dateTo, option);

    let screeningsSource = this.screeningHttpService
      .listByRegion(screeningRequestModel)
      .pipe(map((res) => plainToInstance(ScreeningApiModel, res as object[])));

    return this.createScreeningsStructure(screeningsSource, dateFrom, dateTo, option);
  }

  public createScreeningsStructure(screeningsSource: Observable<ScreeningApiModel[]>, dateFrom: DateTime, dateTo: DateTime, option: IScreeningOption) {
    let movieId: string = null;
    let eventId: string = null;

    if (option && option.id) {
      if (option.type === ScreeningType.MOVIE) {
        movieId = option.id;
      } else if (option.type === ScreeningType.EVENT) {
        eventId = option.id;
      }
    }

    return screeningsSource.pipe(
      map((screenings) =>
        screenings
          .filter((f) => DateTime.fromISO(f.screeningTimeFrom) >= dateFrom && DateTime.fromISO(f.screeningTimeFrom) <= dateTo)
          .sort((a, b) => DateTime.fromISO(a.screeningTimeFrom).valueOf() - DateTime.fromISO(b.screeningTimeFrom).valueOf())
      ),
      switchMap((allScreenings) => {
        const uniqueCinemaIds = [...new Set(allScreenings.map((o) => o.cinemaId))];
        const uniqueDays = [
          ...new Map<number, { start: DateTime; end: DateTime }>(
            allScreenings.map((o) => [
              convertDayToMillis(DateTime.fromISO(o.screeningTimeFrom)),
              {
                start: DateTime.fromISO(o.screeningTimeFrom)
                  .startOf('day')
                  .plus({ hours: option?.cinemaDayOffset ?? 0 }),
                end: DateTime.fromISO(o.screeningTimeFrom)
                  .endOf('day')
                  .plus({ hours: option?.cinemaDayOffset ?? 0 }),
              },
            ])
          ),
        ];

        return this.cinemaHttpService.listViaApiModel().pipe(
          mergeMap((cinemas) => cinemas.filter((c) => uniqueCinemaIds.includes(c.id))),
          mergeMap((cinema) => {
            //cinema
            const movieCopyRequestModel = new MovieCopyRequestModel().deserialize(cinema.id, dateFrom, dateTo);
            const movieRequestModel = new MovieRequestModel().deserialize(cinema.id, dateFrom, dateTo);
            const screeningsByCinema = allScreenings.filter((s) => s.cinemaId === cinema.id);
            const eventRequestModel = new EventRequestModel(cinema.id, dateFrom, dateTo, false);

            return forkJoin({
              events: movieId
                ? of([] as EventViewModel[])
                : eventId
                ? this.eventDataProvider.listViaApiModel(eventRequestModel, eventId)
                : this.eventDataProvider.getEvents(eventRequestModel, eventId),
              movies: eventId
                ? of([] as MovieViewModel[])
                : movieId
                ? this.movieDataProvider.getMovieById(movieId).pipe(toArray())
                : this.movieDataProvider.getMovies(movieRequestModel),
              moviePrints: this.movieCopyHttpService.listViaApiModel(movieCopyRequestModel),
              screens: this.screenheadHttpService.list<ScreenheadApiModel>(cinema.id).pipe(map((screens) => screens.sort((a, b) => a.number - b.number))),
            }).pipe(
              mergeMap((data) => {
                return from(data.screens).pipe(
                  mergeMap((screen) => {
                    const screeningsByScreen = screeningsByCinema.filter((s) => s.screenId === screen.id);
                    const eventsByScreen = data.events.filter((e) => e.screenId === screen.id);

                    return from(uniqueDays).pipe(
                      mergeMap((dayRange) => {
                        const dayRangeContent = dayRange[1];
                        //day
                        const screeningsByDay = screeningsByScreen
                          .filter(
                            (o) =>
                              DateTime.fromISO(o.screeningTimeFrom) >= dayRangeContent.start && DateTime.fromISO(o.screeningTimeFrom) <= dayRangeContent.end
                          )
                          .sort();

                        const uniqueMovieIds = [...new Set(screeningsByDay.map((o) => o.movieId))];
                        const movies = data.movies.filter((o) => uniqueMovieIds.includes(o.id));
                        const events = [
                          ...eventsByScreen
                            .filter((o) => o.timeFrom >= dayRangeContent.start && o.timeFrom <= dayRangeContent.end)
                            .reduce((a, c) => {
                              a.set(c.id, c);
                              return a;
                            }, new Map())
                            .values(),
                        ];

                        return from([...movies, ...events]).pipe(
                          mergeMap((obj) => {
                            if (obj.isEvent) {
                              const screeningsByEvent = eventsByScreen
                                .filter((f) => f.id === obj.id && f.timeFrom >= dayRangeContent.start && f.timeFrom <= dayRangeContent.end)
                                .map((event) =>
                                  Object.assign(new ScreeningApiModel(), {
                                    id: event.screeningId,
                                    cinemaId: cinema.id,
                                    saleTimeTo: event['saleTimeTo'],
                                    reservationTimeTo: event['reservationTimeTo'],
                                    screeningTimeFrom: event['screeningTimeFrom'],
                                    eventId: event.id,
                                  })
                                )
                                .sort((a, b) => a.saleTimeTo.toMillis() - b.saleTimeTo.toMillis());
                              const cinemaModel = new CinemaViewModel(cinema);
                              const screenModel = new ScreenheadViewModel(screen);
                              return of({
                                showid: obj.id,
                                cinemaid: cinema.id,
                                screenid: screen.id,
                                dayRange,
                                cinema: cinemaModel,
                                show: obj,
                                screen: screenModel,
                                screenings: screeningsByEvent,
                              });
                            } else {
                              //movie
                              const screeningsByMovie = screeningsByDay.filter((c) => c.movieId === obj.id);
                              const uniqueMoviePrintIds = [...new Set(screeningsByMovie.map((o) => o.moviePrintId))];
                              const moviePrints = data.moviePrints.filter((o) => uniqueMoviePrintIds.includes(o.id));
                              return moviePrints.map((moviePrint) => {
                                const cinemaModel = new CinemaViewModel(cinema);
                                const screenModel = new ScreenheadViewModel(screen);
                                const moviePrintModel = new MoviePrintViewModel(moviePrint);
                                return {
                                  showid: obj.id,
                                  cinemaid: cinema.id,
                                  screenid: screen.id,
                                  movieprintid: moviePrintModel.id,
                                  dayRange,
                                  cinema: cinemaModel,
                                  show: obj,
                                  moviePrint: moviePrintModel,
                                  screen: screenModel,
                                  screenings: screeningsByMovie.filter((c) => c.moviePrintId === moviePrint.id),
                                };
                              });
                            }
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      }),
      toArray()
    );
  }

  isFamilyAgeRestrictions = (show: MovieViewModel | EventViewModel) => {
    return (
      show.ratings.filter((rating) => {
        if (!rating.value) {
          return false;
        }

        const split = rating.value.split('/');

        if (!split[0]) {
          return false;
        }

        return parseInt(split[0], 10) <= 12;
      }).length > 0
    );
  };

  isFamilyTime = (show: ScreeningApiModel) => {
    return DateTime.fromISO(show.screeningTimeFrom).hour > 6 && DateTime.fromISO(show.screeningTimeFrom).hour < 18;
  };

  getGroupByOption(source: ScreeningObject[], option: any, previousOption?: any, previousGroup?: FilterableGroup): Observable<FilterableGroup[]> {
    if (option) {
      return from(source).pipe(
        groupBy(option.groupingKey, { element: option.groupingElement }),
        mergeMap((group) =>
          zip(of(group.key), group.pipe(toArray())).pipe(
            map((o) => {
              return new FilterableGroup(
                o[0],
                option.groupingType,
                option.groupIndex,
                option.showTitle,
                option.groupingTitle(o[1][0]),
                previousOption?.showTitle
                  ? [...(previousGroup?.parentGroupTitleArray ?? []), option.groupIndex > 2 ? previousGroup?.groupTitle : ''].filter((s) => s)
                  : previousGroup?.parentGroupTitleArray,
                flatten(o[1]),
                option.groupingAttributes(o[1][0]),
                this.groupingOptionsArray.indexOf(option) === this.groupingOptionsArray.length - 2
              );
            })
          )
        ),
        toArray()
      );
    } else {
      return of(null);
    }
  }

  private groupingOptionsArray: IGroupingOption[];
  private readonly GROUPING_OPTIONS_ARRAY = [
    {
      isConstant: true,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'movie',
      groupingKey: (o) => o.show.id,
      groupingTitle: (o) => o.show.title,
      showTitle: false,
      groupingElement: undefined,
      groupingAttributes: (o) => {
        return [
          o.show.isEvent ? 'event' : 'movie',
          ...o.show.genres.map((g) => g.id.toLowerCase()),
          this.isFamilyAgeRestrictions(o.show) ? 'family' : 'adult',
          o.moviePrint?.subtitles ? 'subtitles' : undefined,
        ];
      },
    },
    {
      isConstant: false,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'cinema',
      groupingKey: (o) => o.cinema.id,
      groupingTitle: (o) => o.cinema.name,
      showTitle: false,
      groupingElement: undefined,
      groupingAttributes: (o) => {
        return [o.cinema.id.toLowerCase(), o.cinema.name.toLowerCase()];
      },
    },
    {
      isConstant: false,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'screen',
      groupingKey: (o) => o.screen.id,
      groupingTitle: (o) => o.screen.name,
      showTitle: false,
      groupingElement: undefined,
      groupingAttributes: (o) => {
        return [...o.screen.feature.split(',').map((o) => o.trim().toLowerCase())];
      },
    },
    {
      isConstant: false,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'moviePrint',
      groupingKey: (o) => (o.show.isEvent ? o.showid : o.moviePrint.id),
      groupingTitle: (o) => (o.show.isEvent ? '' : o.moviePrint.printType),
      showTitle: false,
      groupingElement: undefined,
      groupingAttributes: (o) => {
        if (!o.moviePrint?.language) {
          console.log();
        }
        return [o.moviePrint?.language.toLowerCase(), o.moviePrint?.printType.toLowerCase(), o.moviePrint?.speakingType.toLowerCase()];
      },
    },
    {
      isConstant: false,
      isDefault: false,
      groupIndex: 0,
      groupingType: 'release',
      groupingKey: (o) => (o.show.isEvent ? '' : o.moviePrint.release),
      groupingTitle: (o) => (o.show.isEvent ? '' : o.moviePrint.release),
      showTitle: false,
      groupingElement: undefined,
      groupingAttributes: (o) => {
        return [o.moviePrint?.release.trim().toLowerCase()];
      },
    },
    {
      isConstant: true,
      isDefault: true,
      groupIndex: 0,
      groupingType: 'day',
      groupingKey: (o) => o.dayRange[0],
      groupingTitle: (o) => '',
      showTitle: true,
      groupingElement: (show) =>
        show.screenings.map(
          (model: ScreeningApiModel) => new FilterableGroupItem(this.makeScreeningItem(model), [this.isFamilyTime(model) ? 'family' : 'adult'], show)
        ),
      groupingAttributes: (o) => {
        return [this.isFamilyTime(o) ? 'family' : 'adult'];
      },
    },
  ];

  getNextGroupingOption(groupingType?: string) {
    if (!groupingType) {
      return this.groupingOptionsArray[0];
    }
    const i = this.groupingOptionsArray.map((e) => e.groupingType).indexOf(groupingType);
    return this.groupingOptionsArray[i + 1];
  }

  getCurrentGroupingOption(groupingType?: string) {
    if (!groupingType) {
      return this.groupingOptionsArray[0];
    }
    const i = this.groupingOptionsArray.map((e) => e.groupingType).indexOf(groupingType);
    return this.groupingOptionsArray[i];
  }

  groupScreenings(source: ScreeningObject[], groupingOrder?: IGroupingOrderGroup[]) {
    this.groupingOptionsArray = this.createGroupingOptionsArray(groupingOrder);

    return of(
      source
        .filter((m) => m.screenings.length > 0)
        .sort((a, b) => {
          return a.show.priority - b.show.priority || a.show.title.localeCompare(b.show.title);
        })
    ).pipe(
      switchMap((screeningObjects) =>
        this.getGroupByOption(screeningObjects, this.getNextGroupingOption()).pipe(
          expand((filterableGroups) => {
            if (!filterableGroups) {
              return EMPTY;
            }

            return from(filterableGroups).pipe(
              switchMap((group) =>
                this.getGroupByOption(group.items, this.getNextGroupingOption(group.groupType), this.getCurrentGroupingOption(group.groupType), group).pipe(
                  tap((t) => {
                    group.subGroups = t;
                  })
                )
              )
            );
          }),
          toArray()
        )
      ),
      map((allGroups) => allGroups[0]),
      tap((groups) => {
        this.sortScreenings(groups);
      })
    );
  }

  createGroupingOptionsArray(groupingOrders?: IGroupingOrderGroup[]): IGroupingOption[] {
    if (!groupingOrders?.length) {
      let defaultGroups = this.GROUPING_OPTIONS_ARRAY.filter((f) => f.isDefault);
      defaultGroups.forEach((g, i) => {
        g.groupIndex = i;
      });
      return defaultGroups;
    }

    let result = [];

    for (let i = 0; i < this.GROUPING_OPTIONS_ARRAY.length; i++) {
      if (!groupingOrders?.length || this.GROUPING_OPTIONS_ARRAY[i].isConstant) {
        result.push(this.GROUPING_OPTIONS_ARRAY[i]);
      }

      if (i === 0) {
        for (let j = 0; j < groupingOrders?.length ?? 0; j++) {
          let groupOption = this.GROUPING_OPTIONS_ARRAY.filter((g) => g.groupingType === groupingOrders[j].group)[0];
          if (groupOption) {
            groupOption.showTitle = groupingOrders[j].showDescription === 'true';
            result.push(groupOption);
          }
        }
      }
    }

    result.forEach((g, i) => {
      g.groupIndex = i;
    });

    return result;
  }

  sortScreenings(groups: FilterableGroup[]) {
    groups.forEach((g) => {
      if (g.subGroups) {
        this.sortScreenings(g.subGroups);
      } else {
        (g.items as any[]).sort((a, b) => {
          return DateTime.fromISO(a.content.startAt).toMillis() - DateTime.fromISO(b.content.startAt).toMillis();
        });
      }
    });
  }
}

export interface IFilterableGroupItem {
  content: any;
  attributes: string[];
  collapse: boolean;
  visible: boolean;
}

export class FilterableGroup {
  subGroups?: FilterableGroup[];
  collapse: boolean = false;
  visible: boolean = true;
  constructor(
    public key: any,
    public groupType: string,
    public groupIndex: number,
    public showTitle: boolean,
    public groupTitle: string,
    public parentGroupTitleArray: string[],
    public items?: ScreeningObject[],
    public attributes?: string[],
    public isOneBeforeLast?: boolean
  ) {}
}

export class FilterableGroupItem implements IFilterableGroupItem {
  constructor(public content: any, public attributes: string[] = [], public screeningObject?: ScreeningObject) {}
  collapse: boolean = false;
  visible: boolean = true;
}
