import {Injectable} from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { WordpressDataProvider } from 'libs/webcomponent/src/lib/data-provider/wordpress.data-provider';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CmsHelper } from '../helper/cms.helper';
import { RegionHttpService } from '../http/region.http.service';
import { RegionApiModel } from '../model/api-model/region/region.api.model';
import { RegionViewModel } from '../model/view-model/region/region.view.model';

@Injectable({
  providedIn: 'root'
})
export class RegionDataProvider {
  constructor(
    private httpService: RegionHttpService,
    private wordpressDataProvider: WordpressDataProvider,
    private cmsHelper: CmsHelper
  ) {
  }

  list(): Observable<RegionViewModel[]> {
    if (this.cmsHelper.canUseCms) {
      this.wordpressDataProvider.getRegions();
    }

    return this.httpService.list()
      .pipe(
        map(result => plainToInstance(RegionApiModel, result as object[])),
        map((models: RegionApiModel[]) => models.map((model: RegionApiModel) => new RegionViewModel(model)))
      );
  }

  findById(id: string): Observable<RegionViewModel> {
    if (this.cmsHelper.canUseCms) {
      this.wordpressDataProvider.getRegionById(id);
    }

    return this.httpService.findById(id)
      .pipe(
        map(result => plainToInstance(RegionApiModel, result as object)),
        map((model: RegionApiModel) => new RegionViewModel(model))
      );
  }

  findByCinemaId(id: string): Observable<RegionViewModel> {
    return this.list().pipe(
      map(regions => regions.find(region => region.cinemaIds.indexOf(id) >= 0))
    )
  }
}
