<div class="steps-bar topTab">
  <div class="topTabLinks row">
    <ng-container *ngFor="let i of getSteps()">
      <ng-container [ngTemplateOutlet]="stepTemplate" [ngTemplateOutletContext]="{ value: i }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template let-value="value" #stepTemplate>
  <div class="col-3 step step-{{value}}" [ngClass]="{'current': step === value, 'completed': step > value}">
    <span class="d-block d-sm-inline">{{value}}. </span>
    <span>{{ getTranslationSection() + '.' + value + '.label' | translate }}</span>
  </div>
</ng-template>