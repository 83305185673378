import { Exclude, Expose, Type } from 'class-transformer';
import { UserApiModel } from '../api-model/user/user.api.model';

@Exclude()
export class AuthStateModel {
  @Expose()
  public token: string;

  @Expose()
  public refreshToken: string;

  @Expose()
  @Type(() => UserApiModel)
  public user: UserApiModel;
}
