import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreLibraryModule } from 'libs/core/src/lib/core-library.module';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { CoreComponent } from './component/core.component';
import { MainComponent } from './component/layout/main/main.component';
import { LoadingComponent } from './component/loading/loading.component';
import { PaymentMethodComponent } from './component/payment/proxy/component/payment-method/payment-method.component';
import { PaymentProxyComponent } from './component/payment/proxy/payment-proxy.component';
import { BasysPaymentProviderComponent } from './component/payment/proxy/provider/basys/basys-payment-provider.component';
import { ConotoxiaPaymentProviderComponent } from './component/payment/proxy/provider/conotoxia/conotoxia-payment-provider.component';
import { HyperpayPaymentProviderComponent } from './component/payment/proxy/provider/hyperpay/hyperpay-payment-provider.component';
import { IntercardPaymentProviderComponent } from './component/payment/proxy/provider/intercard/intercard-payment-provider.component';
import { PayuBlikPaymentProviderComponent } from './component/payment/proxy/provider/payu-blik/payu-blik-payment-provider.component';
import { PayuPaymentProviderComponent } from './component/payment/proxy/provider/payu/payu-payment-provider.component';
import { SandboxPaymentProviderComponent } from './component/payment/proxy/provider/sandbox/sandbox-payment-provider.component';
import { WorldPayPaymentProviderComponent } from './component/payment/proxy/provider/worldpay/worldpay-payment-provider.component';
import { ProviderContainerComponent } from './component/tag-manager/provider-container/provider-container.component';
import { HomePageComponent } from './page/home/home.component';
import { WorldpayRedirectHubComponent } from './page/payment/worldpay-redirect-hub/worldpay-redirect-hub.component';
import { ScreeningPageComponent } from './page/screening/screening.component';
import { ProviderContainerComponent as PaymentProviderContainerComponent } from './component/payment/proxy/provider-container/provider-container.component';
import { InternationalPhoneComponent } from './component/international-phone/international-phone.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppContributorComponent } from './component/app-contributor/app-contributor.component';
import { BackgroundComponent } from './component/order/background/background.component';
import { TagManagerComponent } from './component/tag-manager/tag-manager.component';
import { PopupComponent } from './component/popup/popup.component';
import { MessageComponent } from './component/message/message.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DropdownComponent } from './component/ui/dropdown/dropdown.component';
import { TimeZoneTestPageComponent } from './page/test/tz.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ShareButtonsComponent } from './component/share-buttons/share-buttons.component';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VideoPlayerComponent } from './component/video-player/video-player.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImdbComponent } from './component/imdb/imdb.component';
import { UserStatusComponent } from './component/user/user-status/status.component';
import { SocialLoginModule } from './module/auth/sociallogin.module';
import { ConfirmModalComponent } from './component/modal/confirm-modal/confirm-modal.component';
import { AccountItemsComponent } from './component/account-items/account-items.component';
import { NgxMaskModule } from 'ngx-mask';
import { DateComponent } from './component/ui/date-component/date-component.component';
import { TabsComponent } from './component/ui/tabs/tabs.component';
import { TabComponent } from './component/ui/tabs/tab/tab.component';
import { GiftCardOnlineComponent } from './component/gift-card/gift-card-online/gift-card-online.component';
import { GiftCardPhysicalComponent } from './component/gift-card/gift-card-physical/gift-card-physical.component';
import { NumericStepperComponent } from './component/ui/numeric-stepper/numeric-stepper.component';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { GiftCardPurchaseComponent } from './component/gift-card/gift-card-purchase/gift-card-purchase.component';
import { SimpleCountdownComponent } from './component/simple-countdown/simple-countdown.component';
import { CorvusPayPaymentProviderComponent } from './component/payment/proxy/provider/corvuspay/corvuspay-payment-provider.component';
import { BlueWhaleComponent } from './component/tag-manager/provider/blue-whale/blue-whale.components';
import { DatePickerComponent } from './component/ui/date-picker/date-picker.component';
import { FlagComponent } from './component/flag/flag.component';
import { PhoneComponent } from './component/ui/phone-component/phone-component.component';
import { PrintMovieDetailComponent } from './component/print-movie-detail/print-movie-detail.component';
import { RadioButtonComponent } from './component/ui/radio-button/radio-button.component';
import { FiservPaymentProviderComponent } from './component/payment/proxy/provider/fiserv/fiserv-payment-provider.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    CoreComponent,
    HomePageComponent,
    ScreeningPageComponent,
    MainComponent,
    ProviderContainerComponent,
    LoadingComponent,
    WorldpayRedirectHubComponent,
    PaymentMethodComponent,
    PayuPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    BasysPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    FiservPaymentProviderComponent,
    PaymentProxyComponent,
    PaymentProviderContainerComponent,
    InternationalPhoneComponent,
    AppContributorComponent,
    BackgroundComponent,
    TagManagerComponent,
    PopupComponent,
    MessageComponent,
    DropdownComponent,
    TimeZoneTestPageComponent,
    ShareButtonsComponent,
    VideoPlayerComponent,
    ImdbComponent,
    UserStatusComponent,
    ConfirmModalComponent,
    AccountItemsComponent,
    DateComponent,
    TabsComponent,
    TabComponent,
    GiftCardPurchaseComponent,
    GiftCardOnlineComponent,
    GiftCardPhysicalComponent,
    NumericStepperComponent,
    SimpleCountdownComponent,
    CorvusPayPaymentProviderComponent,
    BlueWhaleComponent,
    DatePickerComponent,
    FlagComponent,
    PhoneComponent,
    PrintMovieDetailComponent,
    RadioButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    CollapseModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    ShareIconsModule,
    ShareButtonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    TranslateModule.forChild(),
    SocialLoginModule,
    NgxMaskModule.forChild(),
    NgxBootstrapSliderModule,
  ],
  entryComponents: [
    PayuPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    BasysPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    CorvusPayPaymentProviderComponent,
    BlueWhaleComponent,
    FiservPaymentProviderComponent,
  ],
  exports: [
    MainComponent,
    ProviderContainerComponent,
    WorldpayRedirectHubComponent,
    PaymentProxyComponent,
    AppContributorComponent,
    BackgroundComponent,
    TagManagerComponent,
    PopupComponent,
    MessageComponent,
    DropdownComponent,
    LottieModule,
    CollapseModule,
    ShareButtonsComponent,
    VideoPlayerComponent,
    ImdbComponent,
    UserStatusComponent,
    SocialLoginModule,
    ConfirmModalComponent,
    DateComponent,
    TabsComponent,
    TabComponent,
    GiftCardOnlineComponent,
    GiftCardPhysicalComponent,
    NumericStepperComponent,
    DatePickerComponent,
    FlagComponent,
    RadioButtonComponent,
  ],
})
export class SharedLibraryModule {
  public static forRoot(environment: any, providers: Provider[] = []): ModuleWithProviders<SharedLibraryModule> {
    return {
      ngModule: SharedLibraryModule,
      providers: [
        {
          provide: ENVIRONMENT_TOKEN,
          useValue: environment,
        },
        ...[providers],
      ],
    };
  }
}
