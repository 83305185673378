import { Component, OnInit } from '@angular/core';
import { RefundModalComponent } from 'libs/shared/src/lib/component/modal/refund/refund/refund.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund.component.html',
})
export class OneilRefundModalComponent extends RefundModalComponent implements OnInit {
  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }
}
