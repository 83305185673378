import {Injectable} from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TotalizerService {

  private totalizer$: ReplaySubject<number> = new ReplaySubject<number>();

  private totalPrice = 0;
  private optionalExtraFeePrice = 0;

  public constructor() {
  }

  private emitTotalizer() {
    const total = this.totalPrice + this.optionalExtraFeePrice;
    this.totalizer$.next(total);
  }

  public clear() {
    this.totalPrice = 0;
    this.optionalExtraFeePrice = 0;
    this.emitTotalizer();
  }
  public setTotalPrice(value: number) {
    this.totalPrice = value;
    this.emitTotalizer();
  }
  public setOptionalExtraFeePrice(value: number) {
    this.optionalExtraFeePrice = value;
    this.emitTotalizer();
  }

  public getTotalPrice() {
    return this.totalizer$;
  }
}
