import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SimpleCountdownComponent } from 'libs/shared/src/lib/component/simple-countdown/simple-countdown.component';

@UntilDestroy()
@Component({
  selector: 'app-simple-countdown',
  templateUrl: './simple-countdown.component.html',
})
export class OneilSimpleCountdownComponent extends SimpleCountdownComponent {}
