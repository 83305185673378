import { AfterViewInit, Component, DoCheck, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';

@Component({
  template: '',
})
export class AppScreenSeatsMultiSeatComponent implements OnInit, DoCheck, AfterViewInit {
  @ViewChild('seatComponent', { read: ViewContainerRef })
  public seatComponent: ViewContainerRef;

  @Input() seat: SeatViewModel;
  @Input() seats: SeatViewModel[][];
  @Input() selectedSeatIds: Array<string>;
  @Input() seatsLimit: number;
  @Output() seatClickEvent = new EventEmitter<SeatViewModel>();
  @Output() seatsNumber: number;

  seatsToRender: SeatViewModel[];
  groupOverridenColor: string;
  isEnabled: boolean;
  content: string;
  translationContent: string;
  tooltipEnabled: boolean;
  public occupiedStatus = OccupiedStatus;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected translateService: TranslateService) {
    this.tooltipEnabled = environment.screen.enableSeatTooltip;
  }

  ngOnInit() {
    if (MobileDeviceUtils.isMobile()) {
      this.tooltipEnabled = false;
    }
    this.setDisabledStatus();
    const seatsToRender: Array<SeatViewModel> = new Array<SeatViewModel>();
    seatsToRender.push(this.seat);

    let seatsNumber = 1;
    if (this.seat.groupConnectedSeats) {
      for (const connectedSeat of this.seat.groupConnectedSeats) {
        seatsToRender.push(connectedSeat);
        seatsNumber++;
      }
    }

    this.seatsNumber = seatsNumber;
    this.seatsToRender = seatsToRender;
    this.setContent();
  }

  ngAfterViewInit(): void {
    if (this.tooltipEnabled === true && this.seatComponent) {
      (this.seatComponent.element.nativeElement as HTMLElement).setAttribute('data-tooltip-nns', '1');
    }
  }

  onOccupiedChange() {
    this.seatClickEvent.emit(this.seat);
  }

  ngDoCheck() {
    this.setGroupColor();
  }

  setDisabledStatus() {
    const occupancyDisabled = this.seat.occupied === OccupiedStatus.Occupied;
    const blockedGroup = this.seat.hasAssignedGroupType('blocked');
    this.isEnabled = !(occupancyDisabled || blockedGroup);
  }

  setGroupColor() {
    if (this.seat.occupied === OccupiedStatus.Free && this.seat.groupId != null) {
      this.groupOverridenColor = this.seat.groupColor;
    } else {
      this.groupOverridenColor = null;
    }
  }

  getSeatClass(seat): string[] {
    return [seat.occupied, ...seat.groupTypes, seat.wheelchairSeat ? 'wheelchair' : ''];
  }

  setContent() {
    if (this.seat.occupied === OccupiedStatus.Locked) {
      return;
    }
    if (this.seat.occupied === OccupiedStatus.Occupied) {
      this.content = this.translateService.instant('screen.seats.seatTooltipOccupied');
      return;
    }
    const symbols = this.seat.groupConnectedSeats ? this.seat.groupConnectedSeats.map((o) => o.symbol) : [];
    this.content = this.translateService.instant('screen.seats.multiseatTooltip', {
      rowSymbol: this.seat.rowSymbol,
      colSymbol: [this.seat.symbol].concat(symbols).join(','),
    });
  }
}
