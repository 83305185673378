import { CateringAggregationArticleViewModel } from "../catering/catering-aggregation.view.model";

export class OrderSummaryArticleCombinationItemViewModel {
  public articleCombination: CateringAggregationArticleViewModel = null;
  public quantity: number = 0;
  public description: string = null;
  public price: number = 0;
  public unitPriceWithSeparatedItems: number = 0;
  public unitPriceWithoutSeparatedItems: number = 0;

  getPrice() {
    return this.price * this.quantity;
  }
}
