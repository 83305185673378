import { Component, forwardRef, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent } from 'libs/shared/src/lib/component/ui/dropdown/dropdown.component';

export const DROPDOWN_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OneilDropdownComponent),
  multi: true
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  providers: [DROPDOWN_VALUE_ACCESSOR]
})
export class OneilDropdownComponent extends DropdownComponent {
}
