import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { flatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CardHttpService } from '../http/card.http.service';
import { UserHttpService } from '../http/user.http.service';
import { OrderDataProvider } from './order.data-provider';
import { CardLoginPageModel } from '../model/page/user/card/card-login.page.model';
import { CardLoginRequestModel } from '../model/request/card-login.request.model';
import { CardLoginResponseModel } from '../model/response/card-login.response.model';
import { UserApiModel } from '../model/api-model/user/user.api.model';
import { UserViewModel } from '../model/view-model/user/user.view.model';
import { CardViewModel } from '../model/view-model/card/card.view.model';
import { CardApiModel } from '../model/api-model/card/card.api.model';
import { CardTypeViewModel } from '../model/view-model/card/card-type.view.model';
import { CardTypeApiModel } from '../model/api-model/card/card-type.api.model';
import { OrderHttpService } from '../http/order.http.service';
import { PurchaseCardTypeRequestModel } from '../model/request/purchase-card-type.request.model';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { UseCardRequestModel } from '../model/request/use-card.request.model';
import { CardTopUpApiModel } from '../model/api-model/card/card-top-up.api.model';
import { OrderStateService } from '../state/order.state.service';

@Injectable({
  providedIn: 'root',
})
export class CardDataProvider {
  constructor(
    private cardHttpService: CardHttpService,
    private userHttpService: UserHttpService,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService
  ) {}

  public login(request): Observable<CardLoginPageModel> {
    const loginResult = this.cardHttpService.login(plainToInstance(CardLoginRequestModel, request as Object, { groups: ['default'] }));
    return loginResult.pipe(
      flatMap((loginResult: CardLoginResponseModel) => {
        return this.userHttpService.getCurrent().pipe(
          map((userCurrent: UserApiModel) => {
            const obj = new CardLoginPageModel();
            obj.token = loginResult.token;
            obj.user = new UserViewModel(userCurrent);
            return obj;
          })
        );
      })
    );
  }

  info(searchValue: string): Observable<CardViewModel> {
    return this.cardHttpService.info(searchValue).pipe(map((result: CardApiModel) => new CardViewModel(result)));
  }

  public types(cinemaId: string = null): Observable<CardTypeViewModel[]> {
    return this.cardHttpService.types(cinemaId).pipe(map((result: CardTypeApiModel[]) => result.map((type) => new CardTypeViewModel(type))));
  }

  public addCardToOrder(cinemaId: string, orderId: string, request: PurchaseCardTypeRequestModel): Observable<any> {
    return this.cardHttpService.addCardToOrder(cinemaId, orderId, request).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public assignCardToOrder(cinemaId: string, orderId: string, cardId: string): Observable<OrderViewModel> {
    return this.cardHttpService.assignCardToOrder(cinemaId, orderId, cardId).pipe(map((order) => new OrderViewModel(order)));
  }

  public deleteCardFromOrder(cinemaId: string, orderId: string): Observable<OrderViewModel> {
    return this.cardHttpService.deleteCardFromOrder(cinemaId, orderId).pipe(map((order) => new OrderViewModel(order)));
  }

  public deleteSubscription(cardId: string): Observable<any> {
    return this.cardHttpService.deleteSubscription(cardId);
  }

  public useCards(cinemaId: string, orderId: string, request: UseCardRequestModel[]): Observable<OrderViewModel> {
    return this.cardHttpService.useCard(cinemaId, orderId, request).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      mergeMap(() => this.orderDataProvider.findById(cinemaId, orderId))
    );
  }

  public topUp(cinemaId: string, orderId: string, request: CardTopUpApiModel[]) {
    return this.cardHttpService.topUp(cinemaId, orderId, request).pipe(
      switchMap(() => this.orderDataProvider.findById(cinemaId, orderId)),
      tap((order) => {
        this.orderStateService.setOrder(order);
      })
    );
  }
}
