import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { OrderTicketsSummaryComponent } from 'libs/shared/src/lib/component/order/tickets-summary/tickets-summary.component';
import { environment } from 'apps/oneil/src/environments/environment';
import { AppService } from 'libs/core/src/lib/service/app.service';

@Component({
  selector: 'app-order-tickets-summary',
  templateUrl: './tickets-summary.component.html',
})
export class OneilOrderTicketsSummaryComponent extends OrderTicketsSummaryComponent {
  env = environment;

  constructor(
    protected route: ActivatedRoute,
    protected translateService: TranslateService,
    protected orderStateService: OrderStateService,
    protected totalizerService: TotalizerService,
    protected appService: AppService
  ) {
    super(environment, route, translateService, orderStateService, totalizerService, appService);
  }
}
