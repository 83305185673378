import { Component, OnInit } from '@angular/core';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { environment } from 'apps/oneil/src/environments/environment';
import { BasketListComponent } from 'libs/shared/src/lib/component/basket/basket-list/basket-list.component';
import { StateService } from 'libs/core/src/lib/state/state.service';

@Component({
  selector: 'app-basket-list',
  templateUrl: './basket-list.component.html',
})
export class OneilBasketListComponent extends BasketListComponent implements OnInit {
  constructor(protected totalizerService: TotalizerService, protected stateService: StateService) {
    super(environment, totalizerService, stateService);
  }
  public afterOnInit() {}
}
