export function getKeyByValue<K, V>(map: Map<K, V>, value: V): K | undefined {
  for (const [key, val] of map.entries()) {
    if (val === value) {
      return key;
    }
  }
  return undefined;
}

export function getKeyByValueBitwise<K, V>(map: Map<K, number>, value: number): K | undefined {
  for (const [key, val] of map.entries()) {
    if ((val & value) === val) {
      return key;
    }
  }
  return undefined;
}
