<form *ngIf="basketPageModel" [formGroup]="personalForm">
  <div class="col-sm-12 personal-label personal-title px-0 font-600-28 f-roboto">
    {{ "personal.personal.title" | translate }}
  </div>
  <div class="form-group required row align-items-center">
    <label for="firstname"
           class="col-sm-3 col-form-label pl-0">{{ "personal.personal.form.firstname" | translate }}</label>
    <div class="col-sm-9 pr-0">
      <input type="text" class="form-control" id="firstname" formControlName="firstname" letterOnly
             excludepattern="[^A-Za-ząĄżŻźŹćĆńŃśŚęĘóÓłŁ]*" />
    </div>
    <div class="help-block with-errors col-12 text-right" appFormErrors="userFirstName" [errors]="formErrors"></div>
    <div class="help-block with-errors col-12 text-right" *ngIf="!personalForm.get('firstname').valid">
      <p *ngIf="personalForm.get('firstname')?.errors?.required && formSubmitAttempt === true">
        {{ "errors.10001" | translate }}
      </p>
      <p *ngIf="personalForm.get('firstname')?.errors?.maxlength && formSubmitAttempt === true">
        {{ "errors.10106" | translate }}
      </p>
    </div>
  </div>
  <div class="form-group required row align-items-center">
    <label for="lastname"
           class="col-sm-3 col-form-label pl-0">{{ "personal.personal.form.lastname" | translate }}</label>
    <div class="col-sm-9 pr-0">
      <input type="text" class="form-control" id="lastname" formControlName="lastname" letterOnly
             excludepattern="[^A-Za-ząĄżŻźŹćĆńŃśŚęĘóÓłŁ -]*" />
    </div>
    <div class="help-block with-errors col-12 text-right" appFormErrors="userLastName" [errors]="formErrors"></div>
    <div class="help-block with-errors col-12 text-right" *ngIf="!personalForm.get('lastname').valid">
      <p *ngIf="personalForm.get('lastname')?.errors?.required && formSubmitAttempt === true">
        {{ "errors.10002" | translate }}
      </p>
      <p *ngIf="personalForm.get('lastname')?.errors?.maxlength && formSubmitAttempt === true">
        {{ "errors.10107" | translate }}
      </p>
    </div>
  </div>
  <div class="form-group required row align-items-center">
    <label for="email" class="col-sm-3 col-form-label pl-0">{{ "personal.personal.form.email" | translate }}</label>
    <div class="col-sm-9 pr-0">
      <input type="text" class="form-control" id="email" formControlName="email" />
    </div>
    <div class="help-block with-errors col-12 text-right" appFormErrors="userEmail" [errors]="formErrors"></div>
    <div class="help-block with-errors col-12 text-right" *ngIf="!personalForm.get('email').valid">
      <p *ngIf="personalForm.get('email')?.errors?.required && formSubmitAttempt === true">
        {{ "errors.10003" | translate }}
      </p>
      <p *ngIf="personalForm.get('email')?.errors?.email && formSubmitAttempt === true">
        {{ "errors.10102" | translate }}
      </p>
    </div>
  </div>
  <div class="form-group required row align-items-center">
    <label for="email"
           class="col-sm-3 col-form-label pl-0">{{ "personal.personal.form.emailRepeat" | translate }}</label>
    <div class="col-sm-9 pr-0">
      <input type="text" class="form-control" id="emailRepeat" formControlName="emailRepeat" autocomplete="off" />
    </div>
    <div class="help-block with-errors col-12 text-right" appFormErrors="userEmail" [errors]="formErrors"></div>
    <div class="help-block with-errors col-12 text-right" *ngIf="!personalForm.get('emailRepeat').valid">
      <p *ngIf="personalForm.get('emailRepeat')?.errors?.required && formSubmitAttempt === true">
        {{ "errors.10003" | translate }}
      </p>
      <p *ngIf="personalForm.get('emailRepeat')?.errors?.email && formSubmitAttempt === true">
        {{ "errors.10102" | translate }}
      </p>
      <p *ngIf="personalForm.get('emailRepeat')?.errors?.notMatch && formSubmitAttempt === true">
        {{ "errors.30004" | translate }}
      </p>
    </div>
  </div>
  <div class="form-group required row align-items-center">
    <label for="email" class="col-sm-3 col-form-label pl-0">{{ "personal.personal.form.phone" | translate }}</label>
    <div class="col-sm-9 pr-0">
      <input type="text" matInput mask="000 000 000" class="form-control" id="phone" formControlName="phone" />
      <div class="col-form-label p-0">
        <a href=""><u>{{ "personal.personal.phoneHint" | translate }}</u></a>
      </div>
    </div>
    <div class="help-block with-errors col-12 text-right" appFormErrors="userPhone" [errors]="formErrors"></div>
    <div class="help-block with-errors col-12 text-right" *ngIf="!personalForm.get('phone').valid">
      <p *ngIf="personalForm.get('phone')?.errors?.required && formSubmitAttempt === true">
        {{ "errors.10005" | translate }}
      </p>
      <p *ngIf="personalForm.get('phone')?.errors?.phone && formSubmitAttempt === true">
        {{ "errors.10103" | translate }}
      </p>
      <p *ngIf="personalForm.get('phone')?.errors['Mask error'] && formSubmitAttempt === true">
        {{ "errors.10103" | translate }}
      </p>
    </div>
  </div>
  <div class="form-group required row align-items-center">
    <label for="taxId" class="col-sm-3 col-form-label pl-0">{{ "personal.personal.form.taxNumber" | translate }}</label>
    <div class="col-sm-9 pr-0">
      <input type="text" matInput mask="0000000000" class="form-control" id="taxId" formControlName="taxId" />
    </div>
    <div class="help-block with-errors col-12 text-right" appFormErrors="taxId" [errors]="formErrors"></div>
    <div class="help-block with-errors col-12 text-right" *ngIf="!personalForm.get('taxId').valid">
      <p *ngIf="personalForm.get('taxId')?.errors?.maxLength && formSubmitAttempt === true">
        {{ "errors.10104" | translate }}
      </p>
      <p *ngIf="personalForm.get('taxId')?.errors['Mask error'] && formSubmitAttempt === true">
        {{ "errors.10104" | translate }}
      </p>
    </div>
  </div>

  <ng-container *ngIf="!isLogged">
    <ng-container *ngIf="personalForm.get('autoRegisterAccount').value; else withoutRegistration">
      <ng-container *ngTemplateOutlet="passwordInputs"></ng-container>
    </ng-container>

    <ng-template #withoutRegistration>
      <div class="form-group required row align-items-center">
        <div class="d-flex align-items-center">
          <div class="checkbox">
            <label for="manualRegisterAccount">
              <input type="checkbox" class="form-checkbox form-radio" id="manualRegisterAccount"
                     formControlName="manualRegisterAccount" />
              <span [className]="personalForm.get('manualRegisterAccount').value ? 'checkbox-active cr' : 'cr'">
                <i class="cr-icon fa fa-check"></i>
              </span>
            </label>
          </div>
          <span>{{ "login.form.manualRegisterAccount" | translate }}</span>
        </div>
      </div>
      <ng-container *ngIf="personalForm.get('manualRegisterAccount').value">
        <ng-container *ngTemplateOutlet="passwordInputs"></ng-container>
      </ng-container>
    </ng-template>

    <ng-template #passwordInputs>
      <div class="form-group required row align-items-center">
        <label for="registerAccountPassword"
               class="col-sm-3 col-form-label pl-0">{{ "login.form.password" | translate }}</label>
        <div class="d-flex col-sm-9 pr-0 position-relative">
          <app-password-input id="registerAccountPassword"
                              formControlName="registerAccountPassword"></app-password-input>
        </div>
        <div class="help-block with-errors col-12 text-right"></div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="!personalForm.get('registerAccountPassword').valid">
          <p *ngIf="
              personalForm.get('registerAccountPassword')?.errors?.required &&
              (personalForm.get('registerAccountPassword')?.touched || formSubmitAttempt === true)
            ">
            {{ "errors.10012" | translate }}
          </p>
          <p *ngIf="personalForm.get('registerAccountPassword')?.errors?.notMatch && formSubmitAttempt === true">
            {{ "errors.10006" | translate }}
          </p>
        </div>
      </div>
      <div class="form-group required row align-items-center">
        <label for="registerAccountPasswordRepeat"
               class="col-sm-3 col-form-label pl-0">{{ "login.form.repeatPassword" | translate }}</label>
        <div class="d-flex col-sm-9 pr-0">
          <app-password-input id="registerAccountPasswordRepeat"
                              formControlName="registerAccountPasswordRepeat"></app-password-input>
        </div>
        <div class="help-block with-errors col-12 text-right"></div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="!personalForm.get('registerAccountPasswordRepeat').valid">
          <p *ngIf="
              personalForm.get('registerAccountPasswordRepeat')?.errors?.required &&
              (personalForm.get('registerAccountPasswordRepeat')?.touched || formSubmitAttempt === true)
            ">
            {{ "errors.10012" | translate }}
          </p>
          <p *ngIf="personalForm.get('registerAccountPasswordRepeat')?.errors?.notMatch && formSubmitAttempt === true">
            {{ "errors.10006" | translate }}
          </p>
        </div>
      </div>
    </ng-template>
  </ng-container>
</form>