import { Injectable } from '@angular/core';
import { storageKey } from '../../app.const';
import { FlowType } from '../model/component/steps/flow-type.model';
import { StateService } from '../state/state.service';
import { OrderStateService } from '../state/order.state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class StepsService {
  private _flowType: FlowType = FlowType.Standard;

  public set FlowType(value: FlowType) {
    this._flowType = value;
    this.stateService.setItem(storageKey.flowType, value);
  }

  public get FlowType(): FlowType {
    return this._flowType;
  }

  public constructor(private stateService: StateService, private orderStateService: OrderStateService) {
    const flowType: string = this.stateService.getItem(storageKey.flowType);
    if (flowType) {
      this.FlowType = flowType as FlowType;
    }

    orderStateService.state$.pipe(untilDestroyed(this)).subscribe((orderState) => {
      if (orderState?.order?.isOnlyGiftCardOrder()) {
        this._flowType = FlowType.GiftCard;
      }
    });
  }
}
