<div class="navigation-container screen-navigation-container">
  <div class="d-flex justify-content-between">
    <button class="btn btn-default" (click)="onPreviousClick()">
      <span> {{ 'screen.navigation.previous' | translate }} </span>
    </button>
    <button class="btn btn-lg btn-next" [ngClass]="{ 'btn-disabled': isNextButtonDisabled }"
            [disabled]="isNextButtonDisabled" (click)="onNextClick()">
      <span *ngIf="!isLoadingData"> {{ 'screen.navigation.next' | translate }} </span>
      <app-loading *ngIf="isLoadingData" [config]="config" backdrop-position="relative" size="small"></app-loading>
    </button>
  </div>
</div>