export function isNullOrEmpty(value: any): boolean {
  return !value || value.length === 0;
}

export function getTopLevelDomain(url: string): string {
  let hostname;

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  if (url.indexOf('www.') > -0) {
    hostname = url.split('www.')[1];
  }

  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];

  hostname = hostname.split('.');

  if (hostname.length > 2) {
    hostname.shift();
  }

  return hostname.join('.');
}

export function use<T>(sourceValue: T, defaultValue: T): T {
  return sourceValue ?? defaultValue;
}
