// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  app: {
    mode: 'webpage',
    assetsPath: '',
  },
  projectName: 'oneil',
  siteUrl: 'https://oneilcinemas.com/{0}',
  siteUrlSecond: '',
  apiUrl: 'http://localhost:8000/api',
  backUrl: 'http://localhost:4200/#/screening',
  homepageUrl: 'http://localhost:4200/#/screening',
  internationalPhone: {
    definitionUrl: '/assets/data/phone/list.json',
  },
  metadata: {
    title: 'O`neil Cinemas',
  },
  startOnPlayground: false,
  tagManager: {
    providers: [],
  },
  paymentManager: {
    basys: {
      pubApiKey: null,
      host: null,
    },
  },
  virtualCinema: {
    enabled: false,
    list: [
      {
        name: null,
        translate: true,
        constraints: {
          screenId: null,
        },
      },
    ],
  },
  activeLogger: true,
  constants: {
    checkByButton: false,
    eventScreenEnabled: false,
    clearMessageOnPageChange: true,
    saveCurrentUrlToCookie: true,
    tokenToCookie: true,
    moviePosterPlaceholder: '/assets/images/poster_default.png',
    moviePicturePlaceholder: '/assets/images/picture_default.png',
    moviePosterPlaceholderOnError: true,
    currency: '$',
    paymentChannel: 'web',
    paymentRedirectUrl: null,
    createOrderOnGeneralAdmission: true,
    deleteOrderOnWindowUnload: true,
    maxSelectedTickets: 10,
    maxPhoneNumberLength: null,
    phoneNumberTrimLeadingZeros: false,
    paymentDebug: false,
    country: 'US',
    language: {
      default: 'en',
      available: ['en'],
      cultureMap: [{ key: 'en', value: 'en-US' }],
    },
    allowedPages: [
      'screening',
      'screen',
      'personalAndPayment',
      'summary',
      'userRegister',
      'userLogin',
      'userRemind',
      'userSetPassword',
      'userMyProfile',
      'userAreaLogin',
      'userAreaMyProfile',
      'userAreaRegister',
      'userAreaUserRemind',
      'userAreaUserSetPassword',
      'userAreaUserRegisterConfirmation',
      'userAreaError',
      'userAreaMessage',
      'userLogout',
      'userAreaUserLogout',
      'userAreaMyBookings',
      'userMyBookings',
      'userPreferences',
      'userAreaUserPreferences',
      'user',
      'basket',
      'payment',
      'foodandbeverage',
      'tickets',
      'personal',
    ],
    excludeMessageOnPages: ['userRegister', 'userLogin', 'userAreaLogin', 'userAreaRegister', 'screen'],
    excludeTtlErrorOnPages: ['user', 'refund', 'summary', 'error'],
    disableOrderValidationOnPage: ['summary'],
    screenMovieComponentOnPage: ['screen', 'basket', 'payment', 'summary'],
    userAreaNavigation: {
      menu: [
        {
          pageIdentify: 'userAreaMyProfile',
          label: 'user.navigation.user-area.my-profile',
          url: 'user-area/user-my-profile',
        },
        {
          pageIdentify: 'userAreaMyBookings',
          label: 'user.navigation.user-area.my-bookings',
          url: 'user-area/user-my-bookings',
        },
        {
          pageIdentify: 'userAreaUserPreferences',
          label: 'user.navigation.user-area.preferences',
          url: 'user-area/user-preferences',
        },
      ],
    },
    autoSelectPaymentMethod: 'fiserv',
    allowedPaymentMethod: ['fiserv'],
    countdownStartInit: 600,
    enableMultipleScreeningSales: false,
    basketMaxScreenings: 1,
    cartEnabled: false,
    defaultCinemaId: null,
    defaultRegionId: null,
    voucherSaleEnabled: false,
    voucherLimitPerTransaction: 1,
    forceOptionalFees: true,
    recaptcha: {
      enabled: false,
      siteKey: 'YOUR_SITE_KEY',
    },
    phoneProviderNumbers: [],
    expiryLimit: {
      years: 1,
    },
    userDataChecklist: [],
    localStorageStrategy: {
      maxAge: 86400000,
      maxCacheCount: 5,
    }, // used in decorator! can't override by config!
    reservationTimeControlMode: 'reduce',
    enableFB: false,
  },
  screen: {
    enableSeatTooltip: false,
    showPopupOnSelectedSeat: true,
    reduceRequestsForScreeningItems: true,
    reduceRequestsForTicket: true,
    periodBetweenRequestsInScreen: 500,
    removeOrderWhenUserLeavePage: true,
    seatPopup: {
      groupTypesFilter: ['wheelchair'],
    },
  },
  header: {
    actionButtonList: {
      action1: {
        action: {
          nextRouteFor: 'foodandbeverage',
        },
      },
    },
  },
  pages: {
    optionalExtraFeeSelection: 'all',
    screening: {
      enabled: true,
      days: 7,
      defaultStartDate: null,
      showOnlyGeneralAdmission: false,
      navigation: {
        previous: '',
        next: '',
      },
    },
    screen: {
      maxSeats: 5,
      navigation: {
        previous: 'screening',
        next: 'basket',
      },
      autoChooseTicket: false,
      withTicketCompose: false,
      priceSeparators: false,
      showNoticeOnCollisionWithOtherScreenings: false, // applies only for general admission, cart need to be enabled
      redirectToErrorPageSwapScreen: true,
      legend: {
        sort: ['free', 'occupied', 'mine', 'wheelchair', 'wheelchairwithseat', 'handicapplace', 'handicapseat', 'couch', 'companion', 'exit'],
      },
    },

    personalAndPayment: {
      navigation: {
        previous: 'screen',
        next: 'order/summary',
      },
    },
    paymentstate: {
      navigation: {
        next: 'order/summary',
      },
    },
    basket: {
      enabledHeliosVoucher: false,
      maxScreenings: 1,
      maxSeats: 1,
      showAgreements: false,
      autoStartPayment: false,
      enabledWarningModalOnNextAction: true,
      ticketList: {
        sortStrategy: 'price_desc', // none, price_desc, price_asc,
        defaultTicket: 'first_price_desc', // first, first_price_desc, first_price_asc, last
      },
      navigation: {
        previous: 'screen',
        next: 'foodandbeverage',
        target: {
          skip: 'personal',
          continue: 'foodandbeverage',
        },
      },
    },
    foodandbeverage: {
      showScreeningInfo: true,
      navigation: {
        previous: 'basket',
        next: 'payment',
      },
      target: {},
    },
    personal: {
      navigation: {
        previous: (previous) => {
          return previous.giftCardTransaction === true ? 'gc' : 'basket';
        },
        next: 'payment',
      },
    },
    payment: {
      navigation: {
        previous: (params) => {
          return params.cateringSaleEnabled === true ? 'foodandbeverage' : 'personal';
        },
        next: 'order/summary',
      },
    },
    tickets: {
      navigation: {
        previous: 'screening',
        next: 'payment',
      },
    },
  },
  theme: {
    name: 'oneil',
    assetsDir: 'assets',
  },
  imdb: {
    url: 'https://www.imdb.com/title/',
  },
  scripts: {
    apiKeys: {},
  },
  loader: {
    backdropBorderRadius: '0px',
    backdropBackgroundColour: 'rgba(40, 40, 40, 0.3)',
    fullScreenBackdrop: true,
    animationType: 'lottie',
    primaryColour: '#00AEEF',
    secondaryColour: '#FFB100',
    tertiaryColour: '#00AEEF',
    style: null,
    size: null,
    lottie: {
      path: '/assets/lottie/option1.json',
    },
  },
  virtualPass: ['apple'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
