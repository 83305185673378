export enum WpOptionEnum {
  ENVELOPE_ITEM_PRICE = 'EnvelopeItemPrice',
  ENVELOPE_ITEM_ID = 'EnvelopeItemId',
  AGREEMENT_GROUP_ID_AGREEMENTS = 'AgreementGroupId-Agreements',
  VOUCHER_GROUP_ID_PASSES = 'VoucherGroupId-Passes',
  VOUCHER_GROUP_ID_FLAT_RATES = 'VoucherGroupId-FlatRates',
  DEFAULT_TICKET_ID = 'DefaultTicketID',
  INSURANCE_ID = 'InsuranceID',
  BRIGHTCOVE_ACCOUNT_ID = 'BrightcoveAccountID',
  BRIGHTCOVE_PLAYER_ID = 'BrightcovePlayerID',
  ORDER_AGREEMENT = 'OrderAgreement',
  CINEMA_DAY_OFFSET = 'CinemaDayOffset',
  BEST_SEATS_QUANTITY = 'BestSeatsQuantity',
  INTERNAL_PAYMENT_TYPE_ID_FOR_GIFTCARD = 'InternalPaymentTypeIdForGiftCard',
  INTERNAL_PAYMENT_TYPE_ID_FOR_PREPAID = 'InternalPaymentTypeIdForPerPaid',
  DISPLAY_MANDATORY_EXTRA_FEES = 'DisplayMandatoryExtraFees',
}
