import { Injectable } from '@angular/core';
import { mergeMap, Observable, tap } from 'rxjs';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { OrderHelper } from '../helper/order.helper';
import { PurchaseCardTypeRequestModel } from '../model/request/purchase-card-type.request.model';
import { FbItemViewModel } from '../model/view-model/order/fb-item/fb-item.view.model';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { OrderStateService } from '../state/order.state.service';

@Injectable({
  providedIn: 'root',
})
export class GiftCardService {
  constructor(
    protected orderStateService: OrderStateService,
    protected cardDataProvider: CardDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected orderHelper: OrderHelper
  ) {}

  addToCart(model: PurchaseCardTypeRequestModel, addEnvelopes: boolean, envelopeItemId?: string): Observable<OrderViewModel> {
    if (!this.orderStateService.getState().cinema || !this.orderStateService.getState().cinema.id) {
      return;
    }

    const action = (order: OrderViewModel) =>
      this.cardDataProvider.addCardToOrder(this.orderStateService.getState()?.cinema?.id, order.id, model).pipe(
        mergeMap((a) => {
          if (!addEnvelopes || !envelopeItemId) {
            return this.orderDataProvider.findById(this.orderStateService.getState()?.cinema?.id, order.id); // TODO: Remove when API returns in response of addCardToOrder
          }

          const item = new FbItemViewModel();
          item.articleId = envelopeItemId;
          item.quantity = model.quantity;
          return this.orderDataProvider.postCateringItem(this.orderStateService.getState()?.cinema.id, order.id, item);
        })
      );

    return this.orderHelper.createOrderIfNeeded(this.orderStateService.getState(), action, true).pipe(
      tap({
        next: (o) => this.orderStateService.setOrder(o),
        error: (error) => this.orderStateService.setOrder(null),
      })
    );
  }
}
