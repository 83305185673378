import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NavigationEventType } from 'libs/core/src/lib/enum/navigation-event-type.enum';
import { CartService } from '../../cart/service/cart.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';

@Component({
  template: '',
})
export class ScreenNavigationComponent implements OnInit, OnDestroy {
  private cartSubscription: Subscription = Subscription.EMPTY;
  private _screeningId: string = null;
  loaderEnum: typeof LoaderEnum = LoaderEnum;

  @Output() navigationEvent = new EventEmitter<NavigationEventType>();
  @Input() public totalPrice: number;
  @Input() public isNextButtonDisabled = true;
  @Input() public ticketsChanged = false;
  @Input() public isLoadingData = false;
  @Input() public step: number = null;

  public routeName: string = null;
  public cartEnabled = false;
  public isScreeningAlreadyAddedToCart = false;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected cartService: CartService, protected route: ActivatedRoute) {
    this.cartEnabled = environment.constants.cartEnabled === true;
  }

  @Input()
  public set ScreeningId(value: string) {
    this._screeningId = value;
    this.checkCart();
  }

  public ngOnInit(): void {
    this.routeName = this.route.snapshot.data['pageIdentify'];
    if (this.routeName !== 'screen') {
      this.cartEnabled = false;
    }

    if (this.cartEnabled === true) {
      this.cartSubscription = this.cartService.cartState.subscribe(() => {
        this.checkCart();
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.cartSubscription !== Subscription.EMPTY) {
      this.cartSubscription.unsubscribe();
      this.cartSubscription = Subscription.EMPTY;
    }
  }

  public onPreviousClick() {
    this.navigationEvent.emit(NavigationEventType.PREVIOUS);
  }

  public onNextClick() {
    if (this.environment.constants.checkByButton && this.isNextButtonDisabled) {
      return;
    }

    this.navigationEvent.emit(NavigationEventType.NEXT);
  }

  public onSummaryClick() {
    if (this.totalPrice > 0) {
      this.navigationEvent.emit(NavigationEventType.SUMMARY_SHOW);
    }
  }

  public onAddToCartClick(event: Event): void {
    if (this.isNextButtonDisabled) {
      return;
    }

    this.navigationEvent.emit(NavigationEventType.ADD_TO_CART);
  }

  public onRemoveFromCartClick(event: Event): void {
    this.navigationEvent.emit(NavigationEventType.REMOVE_FROM_CART);
  }

  private checkCart(): void {
    if (this.cartEnabled === true) {
      this.isScreeningAlreadyAddedToCart = this.cartService.isScreeningAddedToCart(this._screeningId);
    }
  }
}
