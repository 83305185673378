import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';

@Component({
  template: ''
})
export class ScreeningSelectCinemaComponent implements OnInit {
  @Input() cinemaList: CinemaViewModel[];
  @Input() cinema: CinemaViewModel;
  @Output() onCinemaChangeEvent = new EventEmitter<CinemaViewModel>();

  constructor() { }

  ngOnInit() {}

  cinemaSelectCompare(a: CinemaViewModel, b: CinemaViewModel) {
    return a && b && a.id === b.id;
  }

  onChange(cinema: CinemaViewModel) {
    this.onCinemaChangeEvent.emit(cinema);
  }
}
