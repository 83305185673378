<div class="d-flex" *ngIf="screen && cinema" [smooth-in]="screen && cinema">
    <div class="book-movie-image">
        <img height="200" [src]="screen?.posterUrl" [alt]="screen?.movieName">
    </div>
    <div class="book-movie-extend">
        <div class="book-movie-title">
            {{ screen?.movieName }}
            <span
                  class="book-movie-cert">{{ screen?.getRatingByCinemaGroupId(cinema?.groupId).length > 0 ? '(' + screen?.getRatingByCinemaGroupId(cinema?.groupId, 'value') + ')' : '' }}
            </span>
        </div>
        <div class="book-movie-extend-label mt-1">
            {{ 'book-movie-list.starts' | translate | uppercase }}
            {{ screen?.screeningTimeFrom | dateTime: 'DATE_MDY' }},
            {{ screen?.screeningTimeFrom | dateTime: 'MIN_12H' }}
        </div>
        <div class="book-movie-extend-label">
            {{ cinema?.name }} - {{ screen?.name }}
        </div>
        <app-movie-attributes [release]="screen?.movieCopyRelease"></app-movie-attributes>
    </div>
</div>