<div class="d-flex justify-content-between align-items-center w-100">
  <div class="d-flex pl-3">
    <span class="fa fa-chevron-left" [ngStyle]="{'visibility': canLeft() ? 'visible' : 'hidden'}"
          (click)="leftArrow()"></span>
  </div>
  <div class="items m-2" #elements>
    <ng-container *ngFor="let item of items | slice: elementsOffset: elementsOffset + maxElements">
      <img lazyimg class="pointer"
           [ngClass]="{'selected': selectedItem?.key === item.key, 'empty-card': !item?.graphic}" [src]="item.graphic"
           [alt]="" (click)="select(item)">
    </ng-container>
  </div>

  <div class="d-flex pr-3">
    <span class="fa fa-chevron-right" [ngStyle]="{'visibility': canRight() ? 'visible' : 'hidden'}"
          (click)="rightArrow()"></span>
  </div>
</div>