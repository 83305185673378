import {DateTime} from 'luxon';

export class EventRequestModel {
  dateTimeFrom: string;
  dateTimeTo: string;

  constructor(public cinemaId: string, public dateFrom?: DateTime, public dateTo?: DateTime, public convertDates: boolean = true) {
    if (dateFrom) {
      this.dateTimeFrom = convertDates ? dateFrom.startOf('day').toISO({includeOffset: false}) : dateFrom.toISO({includeOffset: false});
    }
    if (dateTo) {
      this.dateTimeTo = convertDates ? dateTo.endOf('day').toISO({includeOffset: false}) : dateTo.toISO({includeOffset: false});
    }
  }
}
