import {
  AfterViewInit,
  Component,
  DoCheck,
  OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AppScreenSeatsMultiSeatComponent } from 'libs/shared/src/lib/component/screen/seats/component/multiseat/multiseat.component';
import { environment } from 'apps/oneil/src/environments/environment';

@Component({
  selector: 'app-screen-seats-multiseat',
  templateUrl: './multiseat.component.html'
})
export class OneilAppScreenSeatsMultiSeatComponent extends AppScreenSeatsMultiSeatComponent implements OnInit, DoCheck, AfterViewInit {
  constructor(
    protected translateService: TranslateService
  ) {
    super(environment,
      translateService
    )
  }
}
