import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MovieResponseModel } from '../model/response/movie.response.model';
import { MovieRequestModel } from '../model/request/movie.request.model';
import {Cacheable, LocalStorageStrategy} from 'ts-cacheable';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';

@Injectable({
  providedIn: 'root'
})
export class MovieHttpService {
  constructor(
    private http: HttpClient
  ) {}

  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 5
    }
  )
  list(model: MovieRequestModel): Observable<MovieResponseModel[]> {
    return this.http.get<MovieResponseModel[]>(`/movie`, {params: this.getParams(model)});
  }

  @Cacheable()
  findById(id: string): Observable<MovieResponseModel> {
   return this.http.get<MovieResponseModel>(`/movie/${id}`);
  }

  //via API model
  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 5
    }
  )
  listViaApiModel(model: MovieRequestModel): Observable<MovieApiModel[]> {
    return this.http.get<MovieApiModel[]>(`/movie`, { params: this.getParams(model) });
  }

  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 5
    }
  )
  findByIdViaApiModel(id: string): Observable<MovieApiModel> {
    return this.http.get<MovieApiModel>(`/movie/${id}`);
  }

  private getParams(model: MovieRequestModel) {
    const params = {dateTimeFrom: model.dateTimeFrom, dateTimeTo: model.dateTimeTo};
    if (model.cinema) {
      params['cinemaId'] = model.cinema;
    }  
    if (model.premiere) {
      params['premiere'] = model.premiere;
    }
    return params;
  }
}
