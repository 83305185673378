<div class="d-flex flex-column gap-2">
  <div class="d-flex flex-column" [ngClass]="{'gap-05': isLogged && items?.vouchers?.length > 0}">
    <h4 class="m-0" [ngClass]="{'mb-2': isLogged}">{{'voucher.title-singular' | translate}}</h4>
    <app-account-items-vouchers-list *ngIf="isLogged" [vouchers]="items?.vouchers"
                                     (itemSelected)="useItem($event)"></app-account-items-vouchers-list>
    <div class="d-flex align-items-baseline gap-03">
      <h6 *ngIf="isLogged" class="d-flex m-0">
        {{ (items?.vouchers?.length > 0 ? ('voucher.expand' | translate) : ('voucher.click_to' | translate)) }}
      </h6>
      <ng-container [ngTemplateOutlet]="signinbutton"></ng-container>
      <h6 *ngIf="!isLogged || items?.vouchers?.length > 0" class="d-flex align-items-center m-0">
        {{ 'shared.or' | translate }}
      </h6>
      <button class="p-0 btn btn-aslink" (click)="isVoucherCollapsed = !isVoucherCollapsed">
        {{ 'voucher.expand_button' | translate }}
      </button>
    </div>
    <div [collapse]="isVoucherCollapsed">
      <app-voucher-input (onSubmit)="useVoucher($event)"></app-voucher-input>
    </div>
  </div>

  <div class="d-flex flex-column" [ngClass]="{'gap-05': isLogged && items?.giftCards?.length > 0}">
    <h4 class="m-0" [ngClass]="{'mb-2': isLogged }">
      {{'gift-card.title-singular' | translate}}
    </h4>
    <app-account-items-cards-list *ngIf="isLogged" [cards]="items?.giftCards"
                                  (itemSelected)="useItem($event)"></app-account-items-cards-list>
    <div class="d-flex align-items-baseline gap-03">
      <h6 *ngIf="isLogged" class="d-flex m-0">
        {{ (items?.giftCards?.length > 0 ? ('gift-card.expand' | translate) : ('gift-card.click_to' | translate)) }}
      </h6>
      <ng-container [ngTemplateOutlet]="signinbutton"></ng-container>
      <h6 *ngIf="!isLogged || items?.giftCards?.length > 0" class="d-flex align-items-center m-0">
        {{ 'shared.or' | translate }}
      </h6>
      <button class="p-0 btn btn-aslink" (click)="isGiftCardCollapsed = !isGiftCardCollapsed">
        {{ 'gift-card.expand_button' | translate }}
      </button>
    </div>
    <div [collapse]="isGiftCardCollapsed">
      <app-card-input (onSubmit)="useCardByNumber($event)"></app-card-input>
    </div>
  </div>

  <div class="d-flex flex-column" [ngClass]="{'gap-05': isLogged && items?.prepaids?.length > 0}">
    <h4 class="m-0" [ngClass]="{'mb-2': isLogged}">{{'prepaid.title' | translate}}</h4>
    <app-account-items-cards-list *ngIf="isLogged" [cards]="items?.prepaids"
                                  (itemSelected)="useItem($event)"></app-account-items-cards-list>
    <div class="d-flex align-items-baseline gap-03">
      <h6 *ngIf="isLogged" class="d-flex m-0">
        {{ (items?.prepaids?.length > 0 ? ('prepaid.expand' | translate) : ('prepaid.click_to' | translate)) }}
      </h6>
      <ng-container [ngTemplateOutlet]="signinbutton"></ng-container>
      <h6 *ngIf="!isLogged || items?.prepaids?.length > 0" class="d-flex align-items-center m-0">
        {{ 'shared.or' | translate }}
      </h6>
      <button class="p-0 btn btn-aslink" (click)="isPrepaidCollapsed = !isPrepaidCollapsed">
        {{ 'prepaid.expand_button' | translate }}
      </button>
    </div>
    <div [collapse]="isPrepaidCollapsed">
      <app-card-input (onSubmit)="useCardByNumber($event)"></app-card-input>
    </div>
  </div>
</div>

<app-user-loyalty-popups></app-user-loyalty-popups>

<ng-template #signinbutton>
  <button *ngIf="!isLogged" class="btn btn-aslink p-0 m-0 text-white" (click)="showLoginPopup(loginPopupTemplate)">
    {{ 'login.signin' | translate }}
  </button>
</ng-template>

<ng-template #loginPopupTemplate>
  <div class="modal-body text-center">
    <app-login [modal]="true"></app-login>
  </div>
</ng-template>