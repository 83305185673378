import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Cacheable, LocalStorageStrategy} from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export class ScreenheadHttpService {
  constructor(
    private http: HttpClient
  ) {
  }

  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 5
    }
  )
  list<T>(cinemaId: string): Observable<Array<T>> {
    return this.http.get<Array<T>>(`/cinema/${cinemaId}/screenhead`);
  }

  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 5
    }
  )
  findById<T>(cinemaId: string, screenId: string): Observable<T> {
    return this.http.get<T>(`/cinema/${cinemaId}/screenhead/${screenId}`);
  }
}
