<div class="d-flex align-items-center status-bar gap-1 fs-1">
  <ng-container *ngIf="user; else loginTemplate">
    <div class="d-none d-md-flex text-right" style="margin-top: 2px;">
      {{ 'login.welcome' | translate }}
      <span class="font-weight-bold ml-2">
        <a [routerLink]="['/user']" fragment='home'>{{ user.firstName }}</a>
      </span>
    </div>
    <div class="d-flex d-md-none">
      <a [routerLink]="['/user']" fragment='home'>
        <i class="fas fa-user" style="color:#00aeef; font-size: 1.8rem; margin-top: 2px;"></i>
      </a>
    </div>
    <div class="d-flex pointer">
      <a (click)="signOut()">
        <div class="logout-icon"></div>
      </a>
    </div>
  </ng-container>
</div>

<ng-template #loginTemplate>
  <div class="d-flex flex-column" *ngIf="showLoginTemplate">
    <div class="d-none d-md-flex justify-content-center">
      {{ 'login.info-line-1' | translate }}
    </div>
    <div class="buttons">
      <button class="btn btn-aslink p-0 fs-1 m-0" (click)="showLoginPopup(loginPopupTemplate)">
        {{ 'login.signin' | translate }}
      </button>
      <span class="d-none d-sm-flex align-items-center justify-content-center">{{'shared.or' | translate}}</span>
      <button class="btn btn-aslink p-0 fs-1 m-0" (click)="redirectTo('register')">
        {{ "login.register" | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #loginPopupTemplate>
  <div class="modal-body text-center p-5">
    <h2>{{ 'login.signin' | translate }}</h2>
    <app-login [modal]="true"></app-login>
  </div>
</ng-template>