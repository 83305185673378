import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';

@Directive({
  selector: '[appPlayWatermark]',
})
export class PlayWatermarkDirective implements OnInit {
  @Input() hasTrailer: boolean;

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: any, private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    if (this.hasTrailer) {
      const img = this.renderer.createElement('img');
      this.renderer.setAttribute(img, 'src', make_url(this.environment, '/assets/images/play-icon.svg'));
      this.renderer.setProperty(img, 'id', 'play-watermark');
      this.renderer.setAttribute(img, 'alt', 'play-watermark');
      this.renderer.setAttribute(img, 'title', 'play-watermark');
      this.renderer.appendChild(this.el.nativeElement, img);
    }
  }
}
