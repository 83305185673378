export enum ProviderEnum {
  GTAG = 'gtag',
  GTM = 'gtm',
  PAYU = 'payu',
  P24 = 'przelewy24',
  FACEBOOK = 'facebook',
  BASYS = 'basys',
  WORLDPAY = 'worldpay',
  DATATRANS = 'datatrans',
  CORVUSPAY = 'corvuspay',
  BLUE_WHALE = 'blue-whale',
  VIVAWALLET = 'vivawallet',
  FISERV = 'fiserv',
}
