<div class="row">
  <div class="col-4 pr-0" [class.error]="hasError">
    <!-- <label class="w-100 mb-0">
      {{"personal.register.day" | translate}}
      <input id="day" type="text" class="w-100" digitOnly (change)="onDayChange($event)" [ngModel]="day" mask="00"
        field-jumper next-focus-on='input[id=month]' autocomplete="off" [readonly]="true"
        onfocus="this.removeAttribute('readonly');" onfocusout="this.setAttribute('readonly','true');"
        ontouchstart="this.removeAttribute('readonly');" [disabled]="disabled">
    </label> -->
    <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" [selectedItemTemplate]="selectedItemTemplate"
                  [ngModel]="month" (optSelect)="onMonthChange($event)" [options]="months" pristineMessage="Month"
                  [disabled]="disabled">
    </app-dropdown>
  </div>
  <div class="col-4 px-1" [class.error]="hasError">
    <!-- <label class="w-100 mb-0">
      {{"personal.register.month" | translate}}
      <input id="month" type="text" class="w-100" digitOnly (change)="onMonthChange($event)" [ngModel]="month" mask="00"
             field-jumper next-focus-on='input[id=year]' previous-focus-on='input[id=day]' autocomplete="off"
             [readonly]="true" onfocus="this.removeAttribute('readonly');"
             onfocusout="this.setAttribute('readonly','true');" ontouchstart="this.removeAttribute('readonly');"
             [disabled]="disabled">
    </label> -->
    <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" [selectedItemTemplate]="selectedItemTemplate"
                  [ngModel]="day" (optSelect)="onDayChange($event)" [options]="days" pristineMessage="Day"
                  [disabled]="disabled">
    </app-dropdown>
  </div>
  <div class="col-4 pl-0" [class.error]="hasError">
    <!-- <label class="w-100 mb-0">
      {{"personal.register.year" | translate}}
      <input id="year" type="text" class="w-100" digitOnly (change)="onYearChange($event)" [ngModel]="year" mask="0000"
             field-jumper previous-focus-on='input[id=month]' autocomplete="off" [readonly]="true"
             onfocus="this.removeAttribute('readonly');" onfocusout="this.setAttribute('readonly','true');"
             ontouchstart="this.removeAttribute('readonly');" [disabled]="disabled">
    </label> -->
    <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" [selectedItemTemplate]="selectedItemTemplate"
                  [ngModel]="year" (optSelect)="onYearChange($event)" [options]="years" pristineMessage="Year"
                  [disabled]="disabled">
    </app-dropdown>
  </div>
</div>

<ng-template #dropdownItemTemplate let-option="option">
  {{option?.value}}
</ng-template>

<ng-template #selectedItemTemplate let-option="option">
  {{option?.value}}
</ng-template>