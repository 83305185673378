import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { UserApiModel } from 'libs/core/src/lib/model/api-model/user/user.api.model';
import { UserViewModel } from 'libs/core/src/lib/model/view-model/user/user.view.model';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { DateTime } from 'luxon';
import { forkJoin, merge, noop, Observable, of, Subject } from 'rxjs';

@Component({
  template: '',
})
export class UserProfileComponent implements OnInit {
  protected user: UserViewModel;
  public user$ = new Subject<UserViewModel>();
  public profileForm: FormGroup;
  public preferencesForm: FormGroup;

  constructor(
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected authStateService: AuthStateService,
    protected cinemaDataProvider: CinemaDataProvider
  ) {}

  ngOnInit() {
    this.authStateService.state$.subscribe((user) => {
      if (user?.token) {
        this.userDataProvider.get(user?.token).subscribe({
          next: (u) => {
            this.user = new UserViewModel(u);
            this.user.birthday = this.isDefaultBirthdayDate(this.user.birthday) ? null : this.user.birthday;
            this.user$.next(new UserViewModel(u));
          },
          complete: () => noop(),
        });
      }
    });
  }

  onAfterInit() {}

  public onSubmit() {
    if (!this.profileForm.valid) {
      console.log(this.profileForm.controls);
      return;
    }

    let user = { ...this.user.toApiModel(), ...this.profileForm.getRawValue() } as UserApiModel;
    user.birthday = this.profileForm.controls['birthday'].value.toISO();

    this.loadingService.showLoader(LoaderEnum.MAIN);

    forkJoin([this.userDataProvider.update(user, undefined), this.updateFavourites(this.profileForm)]).subscribe({
      complete: () => this.loadingService.hideLoader(LoaderEnum.MAIN),
    });
  }

  updateFavourites(form: FormGroup): Observable<any> {
    const favouriteCinemas$ = Array.isArray(form.controls['favouriteCinemas'].value)
      ? this.userDataProvider.setFavouriteCinemaList(form.controls['favouriteCinemas'].value.filter((f) => f.checked).map((m) => m.id))
      : of(null);

    const favouriteGenres$ = Array.isArray(form.controls['favouriteGenres'].value)
      ? this.userDataProvider.setFavouriteGenreList(form.controls['favouriteGenres'].value.filter((f) => f.checked).map((m) => m.id))
      : of(null);

    return merge(favouriteCinemas$, favouriteGenres$);
  }

  isDefaultBirthdayDate(date: DateTime) {
    return date.year === 1900;
  }
}
