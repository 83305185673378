import { Component, Inject, Input, OnInit } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { EmbedVideoService } from 'ngx-embed-video';
import brightcovePlayerLoader from '@brightcove/player-loader';
import { TrailerTypeEnum, TrailerViewModel } from 'libs/core/src/lib/model/view-model/movie/trailer.view.model';
import { storageKey } from 'libs/core/src/app.const';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() trailers: KeyValue<TrailerViewModel[]>[] = []; // key lang
  @Input() autostart = false;
  @Input() brightcoveAccountId: string = null;
  @Input() brightcovePlayerId: string = null;
  @Input() thumbnail: string = null;

  private brightcoveConfig: any = null;
  public preload = 'auto';
  public api: VgApiService;
  public trailerIndex = 0;
  public langIndex = 0;
  public currentLangTrailers: KeyValue<TrailerViewModel[]>;
  public currentTrailer: TrailerViewModel = null;
  public embeddedPlayer: string;
  public embeddedPlayerConfig: any = null;
  public playerType = 'standalone';

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected embedVideoService: EmbedVideoService,
    protected stateService: StateService,
    private sanitized: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.trailers.length > 0) {
      const trailerInUserLang = this.trailers.find((x) => x.key === this.stateService.getItem(storageKey.lang).toLowerCase());
      const langTrailers = trailerInUserLang || this.trailers[0];
      this.changeTrailerLanguage(langTrailers);
    }
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.playVideo.bind(this));
    this.api.getDefaultMedia().subscriptions.ended.subscribe(this.playVideo.bind(this));
  }

  playVideo() {
    if (this.autostart) {
      this.api.play();
    }
  }

  nextVideo() {
    this.trailerIndex += 1;
    if (this.trailerIndex === this.currentLangTrailers.value.length) {
      this.trailerIndex = 0;
    }
    this.initPlayer();
  }

  public changeTrailerLanguage(group: KeyValue<TrailerViewModel[]>): void {
    group.value = group.value.filter((o) => o.type !== TrailerTypeEnum.BRIGHTCOVE_URL);
    this.currentLangTrailers = group;
    this.trailerIndex = 0;
    this.initPlayer();
  }

  private setCurrentTrailer() {
    this.currentTrailer = this.currentLangTrailers.value[this.trailerIndex];
  }

  private initPlayer() {
    this.setCurrentTrailer();

    if (this.isStreamingResource()) {
      if (this.api) {
        this.api.currentTime = 0;
      }
      this.embeddedPlayer = null;
      return;
    }

    this.playerType = 'embeded';
    if (this.isBrightcove()) {
      this.embeddedPlayer = null;
      this.embedBrightcovePlayer();
    } else {
      this.embeddedPlayer = this.getEmbeddedHtml(this.currentTrailer.value);
    }
  }

  getEmbeddedHtml(url: string): any {
    let returnValue: any;
    const embedded = this.embedVideoService.embed(url);
    if (url.includes('vimeo')) {
      const regex = /vimeo.com\/video\/([0-9a-z\-_]+)/;
      const matches = regex.exec(embedded?.changingThisBreaksApplicationSecurity);
      returnValue = embedded?.changingThisBreaksApplicationSecurity.replace(matches[0], matches[0] + '?dnt=true');
    } else if (url.includes('youtube') || url.includes('youtu.be')) {
      returnValue = embedded?.changingThisBreaksApplicationSecurity.replace('youtube.com', 'youtube-nocookie.com');
    }

    return this.sanitized.bypassSecurityTrustHtml(returnValue);
  }

  private isStreamingResource(): boolean {
    return this.currentTrailer.type === TrailerTypeEnum.GENERIC && this.currentTrailer.value.indexOf('.mp4') > -1;
  }

  private isBrightcove() {
    return this.currentTrailer.type === TrailerTypeEnum.BRIGHTCOVE_ID || this.currentTrailer.type === TrailerTypeEnum.BRIGHTCOVE_URL;
  }

  private embedBrightcovePlayer() {
    if (!this.brightcoveAccountId) {
      return;
    }

    if (!this.brightcoveConfig) {
      this.brightcoveConfig = {
        refNode: '#embeded-player',
        accountId: this.brightcoveAccountId,
        playerId: this.brightcovePlayerId,
        options: {
          autoplay: this.autostart,
          controls: false,
          playsinline: true,
          preload: 'true',
        },
      };

      if (this.environment['brightcove']) {
        this.brightcoveConfig = Object.assign(this.brightcoveConfig, this.environment['brightcove']);
      }
    }

    setTimeout(() => {
      brightcovePlayerLoader.reset();
      brightcovePlayerLoader(Object.assign(this.brightcoveConfig, { videoId: this.currentTrailer.value }))
        .then(function (playerObj) {
          const player = playerObj.ref;
          if (player) {
            player.ready(() => {
              player.loadingSpinner.hide();
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }, 100);
  }
}
