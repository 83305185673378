import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { RegionViewModel } from 'libs/core/src/lib/model/view-model/region/region.view.model';
import { IGroupingOrderGroup, IMoviePackage, IScreeningItem, IScreeningOption, MyAccountPageChangeInterface } from 'libs/core/src/lib/interfaces';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { cookieKey, storageKey } from 'libs/core/src/app.const';
import { ScreeningDataProvider } from 'libs/core/src/lib/data-provider/screening.data-provider';
import { WordpressDataProvider } from '../data-provider/wordpress.data-provider';
import { MyAccountOption } from 'libs/core/src/lib/miscellaneous/my-account-option';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { MovieDataProvider } from 'libs/core/src/lib/data-provider/movie.data-provider';
import { TranslationService } from 'libs/core/src/lib/service/translation.service';
import { ScreeningPeriodEnum } from 'libs/core/src/lib/model/enum/screening-period.enum';
import { MovieRequestModel } from 'libs/core/src/lib/model/request/movie.request.model';
import { CmsNavigateEnum } from 'libs/core/src/lib/enum/cms-navigate.enum';
import { isNumber } from 'libs/core/src/lib/tool/number/number';
import { WpOptionEnum } from '../enum/wp-option.enum';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenStorageService } from 'libs/core/src/lib/service/token-storage.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { NavigationService } from 'libs/core/src/lib/service/navigation/navigation.service';
import { MyAccountPageEnum } from 'libs/core/src/lib/enum/my-account-page.enum';
import { TimeRangeObject } from 'libs/core/src/lib/date/time.model';

const compareByName = (a, b) => {
  return a.name < b.name ? 1 : a.name > b.name ? -1 : 0;
};

@Injectable({
  providedIn: 'root',
})
export class WebComponentsService {
  public static defaultOption = MyAccountPageEnum.OVER_VIEW;
  public availableOptions: MyAccountOption[] = [
    { page: MyAccountPageEnum.OVER_VIEW, useInMenu: true, order: 1 },
    { page: MyAccountPageEnum.TICKETS, useInMenu: true, order: 2 },
    { page: MyAccountPageEnum.RESERVATIONS, useInMenu: true, order: 3 },
    { page: MyAccountPageEnum.VOUCHERS, useInMenu: true, order: 4 },
    { page: MyAccountPageEnum.GIFT_CARDS, useInMenu: true, order: 5 },
    { page: MyAccountPageEnum.PREPAID_CARDS, useInMenu: true, order: 6 },
    { page: MyAccountPageEnum.CLUB_REWARDS, useInMenu: true, order: 7 },
    { page: MyAccountPageEnum.WATCH_LIST, useInMenu: true, order: 8 },
    { page: MyAccountPageEnum.SETTINGS, useInMenu: true, order: 9 },
    { page: MyAccountPageEnum.VOUCHERS_PASSES, useInMenu: true, order: 10 },
    { page: MyAccountPageEnum.MEMBER_GET_MEMBER, useInMenu: false, order: 11 },
    { page: MyAccountPageEnum.VOUCHERS_FLAT_RATES, useInMenu: false, order: 12 },
    { page: MyAccountPageEnum.REWARDS, useInMenu: false, order: 13 },
  ];

  private options: BehaviorSubject<MyAccountPageEnum[]> = new BehaviorSubject<MyAccountPageEnum[]>(null);
  public options$: Observable<MyAccountPageEnum[]>;

  private menus: BehaviorSubject<MyAccountPageEnum[]> = new BehaviorSubject<MyAccountPageEnum[]>(null);
  public menus$: Observable<MyAccountPageEnum[]>;

  private region: BehaviorSubject<RegionViewModel> = new BehaviorSubject<RegionViewModel>(null);
  public region$: Observable<RegionViewModel | null>;

  private regionSwitcherState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public regionSwitcherState$: Observable<boolean>;

  private regions: BehaviorSubject<RegionViewModel[]> = new BehaviorSubject<RegionViewModel[]>(null);
  public regions$: Observable<RegionViewModel[]>;

  private availableRegions: RegionViewModel[] = null;

  private myAccountPage: ReplaySubject<MyAccountPageChangeInterface> = new ReplaySubject<MyAccountPageChangeInterface>(1);
  public myAccountPage$: Observable<MyAccountPageChangeInterface>;

  private myAccountMenuState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public myAccountMenuState$: Observable<boolean>;

  private currentRegion: RegionViewModel;

  public wpConfig: KeyValue<string>[] = [];

  myAccountUrl: string;
  env: any;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) environment: any,
    private screeningDataProvider: ScreeningDataProvider,
    private wordpressDataProvider: WordpressDataProvider,
    private movieDataProvider: MovieDataProvider,
    private authStateService: AuthStateService,
    private translationService: TranslationService,
    private navigationService: NavigationService,
    private cookieService: CookieService,
    private tokenStorageService: TokenStorageService,
    private stateService: StateService
  ) {
    this.env = environment;

    console.log('constructor WebComponentsService');

    this.region$ = this.region.asObservable();
    this.regions$ = this.regions.asObservable();
    this.regionSwitcherState$ = this.regionSwitcherState.asObservable();
    this.myAccountPage$ = this.myAccountPage.asObservable();
    this.myAccountMenuState$ = this.myAccountMenuState.asObservable();

    this.options$ = this.options.asObservable();
    this.menus$ = this.menus.asObservable();

    this.myAccountUrl = environment.production ? `/${this.translationService.currentLang}${environment['cms']?.routes['myAccount']}` : '';

    forkJoin({
      config: this.wordpressDataProvider.getConfig(),
      regions: this.wordpressDataProvider.getRegions(),
    }).subscribe((x) => {
      this.wpConfig = x.config;

      x.regions.forEach((region) => {
        const regionDefinition = x.regions.find((y) => (y.id ? y.id.toLowerCase() : '') === region.id);
        if (regionDefinition && regionDefinition.defaultCinemaId) {
          region.defaultCinemaId = regionDefinition.defaultCinemaId.toLowerCase();
        }
      });

      this.availableRegions = x.regions.sort(compareByName);
      this.regions.next(this.availableRegions);
      this.setRegionById(this.getRegionId());

      this.availableOptions.forEach((option) => {
        option.useInMenu = this.getWpOption(option.page) == 1;
      });

      this.options.next(this.availableOptions.map((opt) => opt.page));
      this.menus.next(
        this.availableOptions
          .filter((o) => o.useInMenu)
          .sort((a, b) => a.order - b.order)
          .map((opt) => opt.page)
      );
    });
  }

  public getRegions(): RegionViewModel[] {
    return this.availableRegions;
  }

  public getRegionById(id: string): RegionViewModel {
    if (this.availableRegions && this.availableRegions.length) {
      return this.availableRegions.find((o) => o.id === id);
    }

    return null;
  }

  public getCurrentRegion() {
    return this.currentRegion;
  }

  public setRegionById(id: string) {
    this.setRegion(this.getRegionById(id));
  }

  public setRegion(region: RegionViewModel) {
    if (!region) {
      return;
    }

    this.currentRegion = region;
    this.authStateService.setItem(storageKey.chosenRegion, region.id);
    this.setRegionInCookies(region.id);
    this.availableRegions.forEach((o) => {
      o.isActive = o.id === region.id;
    });

    if (this.region.value !== region) {
      this.region.next(region);
    }
  }

  private setRegionInCookies(regionId) {
    if (this.cookieService.check(cookieKey.regionId)) {
      this.cookieService.delete(cookieKey.regionId);
    }
    this.cookieService.set(cookieKey.regionId, regionId, 365, '/');
  }

  // region switcher
  public openRegionSwitcher() {
    this.regionSwitcherState.next(true);
  }

  public closeRegionSwitcher() {
    this.regionSwitcherState.next(false);
  }

  public openMyAccountMenu() {
    this.myAccountMenuState.next(true);
  }

  public closeMyAccountMenu() {
    this.myAccountMenuState.next(false);
  }

  public openMyAccountPage(page: MyAccountPageEnum = WebComponentsService.defaultOption, additionalInfo: string = null) {
    if (window.location.href.includes(this.myAccountUrl) || !this.myAccountUrl) {
      this.myAccountPage.next({ page: page, additionalInfo: additionalInfo });
    } else {
      this.stateService.setItem(storageKey.myAccountSelectedOption, page);
      window.location.href = this.myAccountUrl;
    }

    this.closeMyAccountMenu();
  }

  public prepareList(date: DateTime = null, period: ScreeningPeriodEnum = ScreeningPeriodEnum.DAY): Observable<IMoviePackage[]> {
    if (!date) {
      date = DateTime.utc();
    }

    const regionId = this.getRegionId();
    return regionId ? this.screeningDataProvider.getGrouped(regionId, date, period) : of([]);
  }

  public getPosterWallMovies(days: number = 14) {
    const date = DateTime.utc();
    const request = new MovieRequestModel().deserialize(null, date, date.plus({ days }));
    return this.movieDataProvider.getMovies(request);
  }

  public getScreenings(date: DateTime = null, period: ScreeningPeriodEnum = ScreeningPeriodEnum.DAY, option?: IScreeningOption) {
    if (!date) {
      date = DateTime.utc();
    }

    option = this.setOption(option);

    const regionId = this.getRegionId();
    return regionId ? this.screeningDataProvider.getScreenings(regionId, date, period, option) : of([]);
  }

  public getScreeningsByTimeRange(
    date: DateTime = null,
    period: ScreeningPeriodEnum = ScreeningPeriodEnum.DAY,
    timeRangeObject: TimeRangeObject,
    option?: IScreeningOption
  ) {
    if (!date) {
      date = DateTime.utc();
    }

    option = this.setOption(option);

    const regionId = this.getRegionId();
    return regionId ? this.screeningDataProvider.getScreeningsByTimeRange(regionId, date, period, timeRangeObject, option) : of([]);
  }

  private setOption(option: IScreeningOption) {
    if (!option) {
      option = { cinemaDayOffset: this.getCinemaDayOffset() } as IScreeningOption;
    } else {
      option.cinemaDayOffset = this.getCinemaDayOffset();
    }
    return option;
  }

  private getRegionId() {
    return this.getChosenRegion() || this.getCurrentRegion()?.id;
  }

  public groupScreenings(data, groupingOrder?: IGroupingOrderGroup[]) {
    return this.screeningDataProvider.groupScreenings(data, groupingOrder);
  }

  public getChosenRegion(): string | null {
    return this.cookieService.get(cookieKey.regionId) || this.authStateService.getItem(storageKey.chosenRegion);
  }

  public removeChosenRegion(): void {
    this.stateService.removeItem(storageKey.chosenCinema);
  }

  public navigateToBack() {
    this.navigationService.back();
  }

  navigateTo(key: CmsNavigateEnum, value?: string | IScreeningItem): string | null {
    let path = `/${this.translationService.currentLang}${this.env['cms']?.routes[key]}`;

    if (typeof value !== 'string') {
      if (key === CmsNavigateEnum.screen) {
        path += `?id=${value.id}&cid=${value.cinemaId}`;

        if (value.content?.eventId || value.eventId) {
          path += `&eid=${value.content?.eventId ?? value.eventId}`;
        }
      }

      if (key === CmsNavigateEnum.orderStatus) {
        path += `?params=${value.id},${value.cinemaId}`;
      }
    } else {
      if (key === CmsNavigateEnum.movie || key === CmsNavigateEnum.event || key === CmsNavigateEnum.cinema || key === CmsNavigateEnum.slug) {
        path += `/${value}`;
      }
    }

    if (key === CmsNavigateEnum.reservation && !this.authStateService.userIsLoggedAndTokenIsValid()) {
      return path;
    }

    console.log('navigateTo:', path, window.location.href);
    window.location.href = path;

    return null;
  }

  public getWpOption(key: string, defaultValue?: string, asFloat: boolean = false): string | number {
    const value = this.wpConfig.find((x) => x.key === key);

    if (value) {
      if (asFloat) {
        return Number.parseFloat(value.value) || Number.parseFloat(defaultValue);
      }

      return value.value.toLocaleString();
    }

    return value ? value.value : defaultValue;
  }

  public getCinemaDayOffset(): number {
    let storageCinemaDayOffset = this.stateService.getItem(storageKey.cinemaDayOffset);

    if (!storageCinemaDayOffset) {
      const offset = this.getWpOption(WpOptionEnum.CINEMA_DAY_OFFSET);

      if (isNumber(offset)) {
        this.stateService.setItem(storageKey.cinemaDayOffset, offset.toString());
        storageCinemaDayOffset = this.stateService.getItem(storageKey.cinemaDayOffset);
      }
    }

    return isNumber(storageCinemaDayOffset) ? Number(storageCinemaDayOffset) : 0;
  }

  getAccessToken() {
    return this.tokenStorageService.getAccessToken();
  }

  getRefreshToken() {
    return this.tokenStorageService.getRefreshToken();
  }

  saveAccessToken(token) {
    this.tokenStorageService.saveAccessToken(token);
  }

  saveRefreshToken(token) {
    this.tokenStorageService.saveRefreshToken(token);
  }
}
