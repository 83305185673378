import { Component, OnInit } from '@angular/core';
import { RefundSuccessModalComponent } from 'libs/shared/src/lib/component/modal/refund/refund-success/refund-success.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-refund-success-modal',
  templateUrl: './refund-success.component.html',
})
export class OneilRefundSuccessModalComponent extends RefundSuccessModalComponent implements OnInit {
  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }
}
