import { CinemaViewModel } from '../view-model/cinema/cinema.view.model';
import { OrderViewModel } from '../view-model/order/order.view.model';

export class OrderStateModel {
  constructor(public cinema: CinemaViewModel = null, public order: OrderViewModel = null) {
  }

  public get cinemaId() {
    return this.cinema ? this.cinema.id : null;
  }

  public get orderId() {
    return this.order ? this.order.id : null;
  }
}
