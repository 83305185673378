import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TicketApiModel } from '../model/api-model/shared/ticket/ticket.api.model';

@Injectable({
  providedIn: 'root',
})
export class TicketHttpService {
  constructor(private http: HttpClient) {}

  getTickets(cinemaId: string, orderId: string): Observable<TicketApiModel[]> {
    return this.http.get<TicketApiModel[]>(`/cinema/${cinemaId}/order/${orderId}/tickets`);
  }
}
