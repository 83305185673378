import { Component } from '@angular/core';
import { CardDataProvider } from 'libs/core/src/lib/data-provider/card.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { ErrorHelper } from 'libs/core/src/lib/helper/error.helper';
import { CardPaymentService } from 'libs/core/src/lib/service/card-payment.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { OneilLoyaltyInputComponent } from '../../loyalty-input/loyalty-input.component';

@Component({
  selector: 'app-card-input',
  templateUrl: './card-input.component.html',
})
export class OneilCardInputComponent extends OneilLoyaltyInputComponent {
  constructor(
    protected loadingService: LoadingService,
    protected orderStateService: OrderStateService,
    protected cardPaymentService: CardPaymentService,
    protected cardDataProvider: CardDataProvider
  ) {
    super(loadingService, orderStateService);
  }

  submit() {
    if (!this.value || !this.orderState?.order) {
      return;
    }

    if (this.onSubmit.observed) {
      this.onSubmit.emit(this.value);
      return;
    }

    this.errors = [];
    this.loadingService.showLoader(LoaderEnum.MAIN);

    this.cardPaymentService.useCardByNumber(this.value).subscribe({
      next: (res) => {
        this.clear();
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
      error: (e) => {
        const error = ErrorHelper.getError(e);
        if (error) {
          switch (error.code) {
            case 123:
              this.errors = ['not_found'];
              break;
            case 208:
              this.errors = ['cannot_use'];
              break;
          }
        }

        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
    });
  }
}
