<div class="numeric-stepper d-flex align-items-center justify-content-between w-100">
  <i class="fa fa-minus px-3" [ngClass]="{'active': canBeDecrement(), 'pointer': canBeDecrement()}"
     (click)="subQuantity()"></i>
  <ng-container *ngIf="inputField">
    <input class="p-0 text-center" type="text" [ngModel]="quantity" (valueChange)="onQuantityValueChanged($event)"
           digitOnly [size]="maxQuantity.toString().length" [maxLength]="maxQuantity.toString().length">
  </ng-container>
  <ng-container *ngIf="!inputField">
    <span class="px-sm-2">{{ quantity }}</span>
  </ng-container>
  <i class="fa fa-plus px-3" [ngClass]="{'active': canBeIncrement(), 'pointer': canBeIncrement()}"
     (click)="addQuantity()"></i>
</div>
<ng-content></ng-content>