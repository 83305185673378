import { DateTime } from 'luxon';
import { IScreeningOption } from '../../interfaces';
import { ScreeningType } from '../../enum/screening-type';

export class ScreeningRequestModel {
  cinema: string;
  dateTimeFrom: string;
  dateTimeTo: string;
  regionId: string;
  movieId: string;

  deserialize(cinemaId: string, dateFrom: DateTime, dateTo?: DateTime) {
    this.cinema = cinemaId;
    this.dateTimeFrom = dateFrom.startOf('day').toISO({ includeOffset: false });
    this.dateTimeTo = dateTo ? dateTo.endOf('day').toISO({ includeOffset: false }) : dateFrom.endOf('day').toISO({ includeOffset: false });
    this.setDates(dateFrom, dateTo);
    return this;
  }

  forRegion(regionId: string, from: DateTime, to?: DateTime, option?: IScreeningOption) {
    this.regionId = regionId;
    this.movieId = option.type === ScreeningType.MOVIE ? option.id : null;
    this.setDates(from, to);
    return this;
  }

  private setDates(from: DateTime, to?: DateTime) {
    if (!to) {
      to = from;
    }
    this.dateTimeFrom = from.startOf('day').toISO({ includeOffset: false });
    this.dateTimeTo = to.endOf('day').toISO({ includeOffset: false });
  }
}
