import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { LoadingStatus } from 'libs/core/src/lib/model/loading/loading-status.enum';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { BackgroundService } from 'libs/core/src/lib/service/background.service';
import {Subject} from 'rxjs';
import { FormEventModel } from '../../page/refund/model/form-event.model';
import { FormDataBuilderInterface } from './interface/form-data.builder.interface';

@Component({
  template: ''
})
export abstract class RefundComponent implements OnInit {
  @Input() formErrors: string[];
  @Input() loadingStatus: LoadingStatus;
  @Input() formEvent: Subject<FormEventModel>;

  public refundForm: FormGroup;
  public formSubmitAttempt: boolean;

  constructor(
    protected appService: AppService,
    protected backgroundService: BackgroundService
  ) {
  }

  ngOnInit() {
    this.backgroundService.changeDeleteOrderOnWindowUnloadState(false);
    this.refundForm = this.getFrom();
    this.formEvent.subscribe(event => {
      if (event.action === 'submit') {
        this.formSubmitAttempt = true;
        if (this.refundForm.valid) {
          this.formEvent.next({action: 'validated', form: this.refundForm});
        }
      }
    });
  }

  getFrom(): FormGroup {
    const initialFormData: FormDataBuilderInterface = {};
    return this.getFormGroup(initialFormData);
  }

  abstract getFormGroup(formDataBuilderInterface: FormDataBuilderInterface): FormGroup

  cinemaSelectCompare(a: CinemaViewModel, b: CinemaViewModel) {
    return a && b && a.id === b.id;
  }
}
