import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-numeric-stepper',
  templateUrl: './numeric-stepper.component.html',
})
export class NumericStepperComponent implements OnInit {
  @Output() quantityEvent = new EventEmitter<number>();
  @Input() maxQuantity = 99;
  @Input() minQuantity = 1;
  @Input() inputField = false;
  @Input() quantity = 1;
  @Input() step = 1;

  constructor() {}

  ngOnInit(): void {}

  public onQuantityValueChanged(value): void {
    if (value && value !== this.quantity) {
      this.quantityEvent.emit((this.quantity = value));
    }
  }

  public addQuantity(): void {
    if (this.canBeIncrement()) {
      this.quantityEvent.emit((this.quantity += this.step));
    }
  }

  public subQuantity(): void {
    if (this.canBeDecrement()) {
      this.quantityEvent.emit((this.quantity -= this.step));
    }
  }

  public canBeDecrement(): boolean {
    return this.quantity - this.step >= this.minQuantity;
  }

  public canBeIncrement(): boolean {
    return this.quantity + this.step <= this.maxQuantity;
  }
}
