import { NotificationType } from './notification-type.enum';

export class NotificationModel {
  constructor(message: string, type: NotificationType) {
    this.message = message;
    this.type = type;
  }

  public message: string;
  public type: NotificationType;
}
