import { DateTime } from 'luxon';
import { GenreViewModel } from '../genre/genre.view.model';
import { ModelBuilder } from '../model.builder';
import { BannerViewModel } from '../banner.view.model';
import { TagGroupViewModel } from '../tag-group.view.model';
import { RatingViewModel } from '../rating.view.model';
import { EventItemViewModel } from './event-item.view.model';
import { TrailerViewModel } from '../movie/trailer.view.model';
import { AbstractViewModel } from '../../abstract.view.model';
import { EventApiModel } from '../../api-model/event/event.api.model';
import { MoviePrintViewModel } from '../movie/movie-print.view.model';
import { fromISOToDefaultZone } from '../../../date/date.helper';

export class EventViewModel extends AbstractViewModel<EventApiModel> {
  id: string;
  duration: number;
  description: string;
  ageLongDescription: string;
  releaseDate: DateTime;
  distributorId: string;
  distributor: string;
  genres: GenreViewModel[];
  trailers: string[];
  posters: string[];
  pictures: string[];
  posPosters: string[];
  banners: BannerViewModel[];
  ratings: RatingViewModel[];
  name: string;
  link: string;
  screeningId: string;
  screenId: string;
  cinemaId: string;
  timeFrom: DateTime;
  timeTo: DateTime;
  saleTimeTo: DateTime;
  reservationTimeTo: DateTime;
  availabilityStatus: number;
  externalId: number;
  items: EventItemViewModel[];
  tagGroups: TagGroupViewModel[];
  release: string;
  priority: number;

  likeByUser: boolean;
  likes: number;
  trailersGroupByLang: TrailerViewModel[] = [];
  screeningTimeFrom: DateTime;
  movieCopy: MoviePrintViewModel;
  firstPosterOrDefault: string = '';

  constructor(protected apiModel: EventApiModel = new EventApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.duration = this.apiModel.duration;
    this.description = this.apiModel.description;
    this.distributorId = this.apiModel.distributorId;
    this.distributor = this.apiModel.distributor;
    this.genres = this.apiModel.genres ? this.apiModel.genres.map((genre) => new GenreViewModel(genre)) : [];
    this.trailers = this.apiModel.trailers ?? [];
    this.posters = this.apiModel.posters ?? [];
    this.pictures = this.apiModel.pictures ?? [];
    this.posPosters = this.apiModel.posPosters;
    this.banners = this.apiModel.banners ? this.apiModel.banners.map((banner) => new BannerViewModel(banner)) : [];
    this.ratings = this.apiModel.ratings ? this.apiModel.ratings.map((rating) => new RatingViewModel(rating)) : [];
    this.name = this.apiModel.name;
    this.link = this.apiModel.link;
    this.screeningId = this.apiModel.screeningId;
    this.screenId = this.apiModel.screenId;
    this.cinemaId = this.apiModel.cinemaId;
    this.screeningTimeFrom = this.timeFrom = this.apiModel.timeFrom ? DateTime.fromISO(this.apiModel.timeFrom, { setZone: true }) : null;
    this.timeTo = this.apiModel.timeTo ? DateTime.fromISO(this.apiModel.timeTo, { setZone: true }) : null;
    this.saleTimeTo = this.apiModel.saleTimeTo ? DateTime.fromISO(this.apiModel.saleTimeTo, { setZone: true }) : null;
    this.reservationTimeTo = this.apiModel.reservationTimeTo ? DateTime.fromISO(this.apiModel.reservationTimeTo, { setZone: true }) : null;
    this.availabilityStatus = this.apiModel.availabilityStatus;
    this.externalId = this.apiModel.externalId;
    this.items = this.apiModel.items ? this.apiModel.items.map((item) => new EventItemViewModel(item)) : [];
    this.tagGroups = this.apiModel.tagGroups ? this.apiModel.tagGroups.map((tagGroup) => new TagGroupViewModel(tagGroup)) : [];
    this.release = this.apiModel.release;
  }

  toApiModel(): EventApiModel {
    return undefined;
  }

  get isEvent(): boolean {
    return true;
  }

  get title(): string {
    return this.name;
  }

  get picturesOrDefaults(): string[] {
    return this.pictures.length ? this.pictures : [ModelBuilder.makePicture(1200, 500)];
  }

  get ageRestriction(): string {
    if (!this.ratings || this.ratings.length === 0) {
      return '-';
    }

    return this.ratings.map((o) => o.value).join('/');
  }

  get slug(): string {
    if (!this.name) {
      return '';
    }

    const searchRegExp = /\s/g;
    return this.name.toLowerCase().replace(searchRegExp, '-');
  }

  get genreNames(): string {
    return this.genres.map((o) => o.name).join(', ');
  }
}
