import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Inject, Injectable } from '@angular/core';
import { RequestHandlerService } from '../request-handler.service';
import { CinemaViewModel } from '../../../model/view-model/cinema/cinema.view.model';
import { OrderStateService } from '../../../state/order.state.service';
import { CinemaDataProvider } from '../../../data-provider/cinema.data-provider';
import { OrderDataProvider } from '../../../data-provider/order.data-provider';
import { OrderValidatorService } from '../../validator/order-validator.service';
import { DomainService } from '../../domain.service';
import { UserAreaService } from '../../user-area.service';
import { AppService } from '../../app.service';
import { ENVIRONMENT_TOKEN } from '../../../injection.tokens';
import { RequestHandlerModel } from '../request-handler.model';
import { NavigationHelperService } from '../../navigation/navigation-helper.service';
import { AnalyticsHelperService } from '../../analytics-helper.service';
import { StateService } from '../../../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class CoreRequestHandlerService extends RequestHandlerService {
  private disableOrderValidationOnPage: Array<string> = new Array<string>();
  private cinema: CinemaViewModel;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected router: Router,
    protected route: ActivatedRoute,
    protected orderState: OrderStateService,
    protected cinemaDataProvider: CinemaDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected translate: TranslateService,
    protected orderValidator: OrderValidatorService,
    protected cookieService: CookieService,
    protected domainService: DomainService,
    protected userAreaService: UserAreaService,
    protected navigationHelperService: NavigationHelperService,
    protected appService: AppService,
    protected analyticsHelperService: AnalyticsHelperService,
    protected stateService: StateService
  ) {
    super(router, route, translate, cookieService, domainService, appService, stateService);
    this.disableOrderValidationOnPage = this.environment.constants.disableOrderValidationOnPage;
  }

  public handle(params: RequestHandlerModel) {
    super.handle(params);
    this.orderState.initData(params.orderId, params.cinemaId);
  }
}
