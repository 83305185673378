import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { CreatePaymentRedirectUrlDto } from './dto/create-payment-redirect-url.dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelperService {
  private paymentRedirectUrlPattern: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router, private navigationHelper: NavigationHelperService) {
    this.paymentRedirectUrlPattern = environment.constants.paymentRedirectUrl;
  }

  /**
   * Creates an payment redirect URI
   * @param request
   */
  public createPaymentRedirectUrl(request: CreatePaymentRedirectUrlDto, target: string = null): string {
    return this.paymentRedirectUrlPattern
      .replace('{cinemaId}', request.state.cinemaId)
      .replace('{orderId}', request.state.orderId)
      .replace('{target}', target ? encodeURIComponent(target) : this.createTarget(request))
      .replace('{paymentChannel}', request.paymentChannel);
  }

  public createTarget(request: CreatePaymentRedirectUrlDto): string {
    return encodeURIComponent(
      window.location.origin +
        '/#' +
        this.router
          .createUrlTree([this.navigationHelper.getNextRoute(request.route.snapshot)], {
            queryParams: {
              orderId: request.state.orderId,
              cinemaId: request.state.cinemaId,
            },
          })
          .toString()
    );
  }
}
