import {DateTime} from 'luxon';
import { CinemaApiModel } from '../api-model/cinema/cinema.api.model';

export class MovieRequestModel {
  cinema?: string;
  dateTimeFrom: string;
  dateTimeTo: string;
  premiere?: string;

  deserialize(cinema: CinemaApiModel | string | null, from: DateTime, to?: DateTime) {
    if (cinema) {
      this.cinema = typeof cinema === 'string' ? cinema : cinema.id;
    }
    this.setDates(from, to);

    return this;
  }

  private setDates(from:DateTime, to?:DateTime) {
    if (!to) {
      to = from;
    }
    this.dateTimeFrom = from.startOf('day').toISO({ includeOffset: false });
    this.dateTimeTo = to.endOf('day').toISO({ includeOffset: false });
  }
}
