<!-- ['facebook','twitter','pinterest','linkedin','reddit','tumblr','mix','viber','vk','telegram','messenger','whatsapp','xing','line','sms','email','print','copy'] -->
<div *ngIf="state$ | async; let state" class="sb-group sb-{{theme}}">
    <share-button *ngFor="let button of state.selectedButtons"
                  [button]="button"
                  [theme]="theme"
                  [url]="url"
                  [title]="title"
                  [description]="description"
                  [image]="image"
                  [tags]="tags"
                  [autoSetMeta]="autoSetMeta"
                  [showIcon]="showIcon"
                  [showText]="showText"
                  [size]="size"
                  (opened)="opened.emit($event)"
                  (closed)="closed.emit($event)"
                  [disabled]="disabled">
    </share-button>
</div>
