import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: DateTime | string, format: string, relativeCalendarDays: number = 0): string {
    const v = typeof value === 'string' ? DateTime.fromISO(value) : value;

    if (!(v instanceof DateTime)) {
      return;
    }

    const now = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const lang = this.translateService.currentLang;
    value = v.setLocale(lang);

    const relativePrefix =
      relativeCalendarDays > 0 && value >= now && now >= value.minus({ days: relativeCalendarDays })
        ? `${this.capitalized(value.toRelativeCalendar({ locale: lang }))}, `
        : '';
    return relativePrefix + this.getFormat(value, format);
  }

  getFormat(value: DateTime, format: string) {
    switch (format) {
      case 'DAY_SHORT':
        return value.toFormat('EEE');
      case 'MONTH_SHORT':
        return value.toFormat('MMM');
      case 'MONTH_WIDE':
        return value.toFormat('MMMM');
      case 'DATE_DMY':
        return value.toFormat('dd LLL yyyy');
      case 'DATE_DMY_FULL':
        return value.toFormat('dd LLLL yyyy');
      case 'DATE_MDY':
        return value.toFormat('d.M.y');
      case 'FULL_DATE':
        return value.toFormat('cccc, LLLL d, yyyy');
      case 'DATE_HUMAN':
        return value.toFormat('cccc dd.LL.yyyy');
      case 'DATE_HUMAN_COMA':
        return value.toFormat('cccc, dd.LL.yyyy');
      case 'DATE_HUMAN_COMA_MDY':
        return value.toFormat('cccc, LL/dd/yyyy');
      case 'DATE_HUMAN_COMA_SHORT':
        return value.toFormat('ccc, dd.LL.yyyy');
      case 'DATE_SHORT_DM':
        return value.toFormat('ccc, dd.LL.');
      case 'DATE_SHORT_MDY':
        return value.toFormat('LLL. d, y');
      case 'DATE_SHORT_DY':
        return value.toFormat('MMM d, y');
      case 'DATE_SHORT_DMY':
        return value.toFormat('dd.LL.yyyy');
      case 'DATE_SHORT_MDY_SLASH':
        return value.toFormat('LL/dd/yyyy');
      case '12H':
        return value.toFormat('hh:mm a');
      case 'MIN_12H':
        return value.toFormat('h:mm a');
      case '24H':
        return value.toFormat('HH:mm');
      case 'FULL_DATE_DAY_SHORT':
        return value.toFormat('EEE d.M.y');
      case 'MEDIUM_DATE':
        return value.toFormat('d MMM y');
      case 'LONG_DATE':
        return value.toFormat('d MMMM y');
      case 'PAYMENT_CARD_EXPIRED':
        return value.toFormat('MM/yy');
      default:
        return value.toLocaleString(DateTime[format]);
    }
  }

  capitalized(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
}
