import {Expose } from 'class-transformer';
import { ScreenColApiModel } from './screen-col.api.model';
import { ScreenRowApiModel } from './screen-row.api.model';
import { ScreenElementApiModel } from './screen-element.api.model';
import { SeatBlockedApiModel } from './seat/seat-blocked.api.model';
import { SeatGroupApiModel } from './seat/seat-group.api.model';
import { SeatApiModel } from './seat/seat.api.model';

export class ScreenApiModel {

  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  type: string;

  @Expose()
  feature: string;

  @Expose()
  number: number;

  @Expose()
  rows: ScreenRowApiModel[];

  @Expose()
  cols: ScreenColApiModel[];

  @Expose()
  seats: SeatApiModel[];

  @Expose()
  groups: SeatGroupApiModel[];

  @Expose()
  defaultSeatDescription: string;

  @Expose()
  defaultSeatGroupName: string;

  @Expose()
  blockedList: SeatBlockedApiModel[];

  @Expose()
  screenGroupId: string;

  @Expose()
  ffaNumber: string;

  @Expose()
  proCinema: string;

  @Expose()
  screenElements: ScreenElementApiModel[];
}


