import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { OrderHelper } from 'libs/core/src/lib/helper/order.helper';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { FormDataBuilderInterface } from 'libs/shared/src/lib/component/login/interface/form-data.builder.interface';
import { LoginComponent } from 'libs/shared/src/lib/component/login/login.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OneilFormBuilder } from './form.builder';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class OneilLoginComponent extends LoginComponent implements OnInit {
  showLoginPanel = false;
  isLogged = false;
  showResetPasswordSuccessPopup = false;

  constructor(
    protected authStateService: AuthStateService,
    protected userDataProvider: UserDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected orderStateService: OrderStateService,
    protected loadingService: LoadingService,
    protected modalService: BsModalService,
    protected orderHelper: OrderHelper,
    private router: Router
  ) {
    super(authStateService, userDataProvider, orderDataProvider, orderStateService, loadingService, modalService, orderHelper);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.authStateService.state$.subscribe((authState) => {
      this.isLogged = this.authStateService.userIsLoggedAndTokenIsValid();
    });

    this.remindPasswordProcessState.subscribe((success) => {
      if (success) {
        this.showResetPasswordSuccessPopup = true;
      }

      this.loadingService.hideLoader(LoaderEnum.MAIN);
    });
  }

  getFormGroup(formDataBuilderInterface: FormDataBuilderInterface): FormGroup {
    return new OneilFormBuilder().getForm(formDataBuilderInterface);
  }

  redirectTo(route: 'register' | 'resetPassword') {
    this.loadingService.hideLoader(LoaderEnum.MAIN);
    this.router.navigate(['user', route]);
  }

  forgotPasswordClick(event: Event) {
    event.preventDefault();
    this.formSubmitAttempt = true;

    const passwordField = this.loginForm.controls['password'];

    if (passwordField && passwordField.hasValidator(Validators.required)) {
      passwordField.removeValidators(Validators.required);
      passwordField.setErrors(null);
    }

    if (this.loginForm.valid) {
      this.loadingService.showLoader(LoaderEnum.MAIN);
      this.remindPassword();
    }
  }

  signInClick(event: Event) {
    event.preventDefault();
    this.formSubmitAttempt = true;

    const passwordField = this.loginForm.controls['password'];
    if (passwordField && !passwordField.hasValidator(Validators.required)) {
      passwordField.addValidators(Validators.required);
      passwordField.updateValueAndValidity();
    }

    this.onSubmit();
  }
}
