import { Expose, Type } from 'class-transformer';
import { ProviderConfigurationApiModel } from './provider-configuration.api.model';

export class PaymentConfigApiModel {
  @Expose()
  salesPointId: string;

  @Expose()
  postBackUrl: string;

  @Expose()
  environment: string;

  @Expose()
  merchantId: string;

  @Expose()
  googleMerchantId: string;

  @Expose()
  merchantName: string;

  @Expose()
  @Type(() => ProviderConfigurationApiModel)
  providerConfiguration: ProviderConfigurationApiModel;
}
