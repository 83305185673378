import { Component, OnInit } from '@angular/core';
import { CinemaDataProvider } from 'libs/core/src/lib/data-provider/cinema.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { UserHistoryComponent } from 'libs/shared/src/lib/component/user/user-history/user-history.component';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
})
export class OneilUserHistoryComponent extends UserHistoryComponent {
  constructor(protected cinemaDataProvider: CinemaDataProvider, protected loadingService: LoadingService, protected userDataProvider: UserDataProvider) {
    super(cinemaDataProvider, loadingService, userDataProvider);
  }
}
