export enum LoaderEnum {
  MAIN = 'main',
  ALL = 'all',
  LOGIN = 'login',
  LOYALTY = 'loyalty',
  NEXT_BUTTON = 'next',
  QUICKBUY = 'quick-buy',
  PAYMENT = 'payment',
  VOUCHER_SHOP = 'voucher_shop',
  VOUCHER_SHOP_ADD = 'voucher_shop_add',
}
