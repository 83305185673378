import { Injectable } from '@angular/core';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { RegionViewModel } from 'libs/core/src/lib/model/view-model/region/region.view.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WordpressHttpService } from '../http/wordpress.http.service';
import { CinemaAdapter, MembershipAdapter, PackageAdapter, RegionAdapter } from '../wp-model/adapters';
import { Like } from '../wp-model/like';
import { Membership } from '../wp-model/membership';
import { Package } from '../wp-model/package';
import { Post } from '../wp-model/post';
import { PostRequest } from '../wp-model/request/post-request';
import { SlideRequest } from '../wp-model/request/slide-request';

@Injectable({
  providedIn: 'root',
})
export class WordpressDataProvider {
  constructor(
    private httpService: WordpressHttpService,
    private membershipAdapter: MembershipAdapter,
    private regionAdapter: RegionAdapter,
    private cinemaAdapter: CinemaAdapter,
    private packageAdapter: PackageAdapter
  ) {}

  public getPostsByCategories(categories: string, postRequest: PostRequest) {
    return this.httpService.getPostsByCategories(categories, postRequest);
  }

  public getPosts(postRequest: PostRequest, useLang = true) {
    return this.httpService.getPosts(postRequest, useLang);
  }

  public getPost(slug: string, type = 'posts', useLang = true) {
    return this.httpService.getPost(slug, type, useLang);
  }

  public getRegions(): Observable<RegionViewModel[]> {
    return this.httpService.getRegions().pipe(
      map((data: Post[]) => data.map((item: Post) => this.regionAdapter.adapt(item))),
      map((regions) => regions.filter((o) => o.cinemaIds && o.cinemaIds.length))
    );
  }

  public getRegionById(id: string): Observable<RegionViewModel> {
    return this.getRegions().pipe(map((regions) => regions.find((o) => o.cinemas.map((cinema) => cinema.id).includes(id))));
  }

  public getCinemas(): Observable<CinemaViewModel[]> {
    return this.httpService.getCinemas().pipe(map((data: Post[]) => data.map((item: Post) => this.cinemaAdapter.adapt(item))));
  }

  public getCinemaById(id: string): Observable<CinemaViewModel> {
    return this.getCinemas().pipe(map((cinemas) => cinemas.find((o) => o.id === id)));
  }

  public getMovieById(id: string) {
    return this.httpService.getMovieById(id);
  }

  public getPostMovieById(id: string) {
    return this.httpService.getPostMovieById(id);
  }

  public getMovies() {
    return this.httpService.getMovies();
  }

  public getEventById(id: string) {
    return this.httpService.getEventById(id);
  }

  public toggleLike(id: string, isEvent = false): Observable<Like> {
    return this.httpService.toggleLike(id, isEvent);
  }

  public getMemberships(): Observable<Membership[]> {
    return this.httpService.getMemberships().pipe(map((items) => items.map((x) => this.membershipAdapter.adapt(x.custom_fields))));
  }

  public getConfig(): Observable<KeyValue<string>[]> {
    return this.httpService.getConfig();
  }

  public getSlider(slideRequest: SlideRequest) {
    return this.httpService.getSlider(slideRequest);
  }

  public getPackages(): Observable<Package[]> {
    return this.httpService.getPackages().pipe(map((items) => items.map((x) => this.packageAdapter.adapt(x.custom_fields))));
  }

  public getProductsListById(id: string) {
    return this.httpService.getProductsListById(id);
  }
}
