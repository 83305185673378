import {Expose, Type} from 'class-transformer';
import { VoucherItemMembershipPriceApiModel } from './voucher-item-membership-price.api.model';

export class VoucherItemApiModel {
  @Expose()
  id: string;

  @Expose()
  itemName: string;

  @Expose()
  itemId: string;

  @Expose()
  quantity: number;

  @Expose()
  itemTaxRate: number;

  @Expose()
  itemPrice: number;

  @Expose()
  value: number;

  @Expose()
  bookingId: string;

  @Expose()
  promotionId: string;

  @Expose()
  promotionName: string;

  @Expose()
  itemRedemptionPoints: number;

  @Expose()
  @Type(() => VoucherItemMembershipPriceApiModel)
  membershipPrices: VoucherItemMembershipPriceApiModel[] = [];
}
