import { identities } from 'libs/core/src/app.const';
import { CinemaViewModel } from '../../view-model/cinema/cinema.view.model';
import { ScreenViewModel } from '../../view-model/screen/screen.view.model';
import { ScreeningViewModel } from '../../view-model/screening/screening.view.model';
import { OrderSummaryArticleCombinationItemViewModel } from './order-summary-article-combination-item.view.model';
import { OrderSummaryFeeViewModel } from './order-summary-fee.view.model';
import { OrderSummaryGiftCardItemViewModel } from './order-summary-gift-card-item.view.model';
import { OrderSummaryItemViewModel } from './order-summary-item.view.model';
import { OrderSummaryPersonalViewModel } from './order-summary-personal.view.model';
import { OrderSummaryVoucherItemViewModel } from './order-summary-voucher-item.view.model';

export class OrderSummaryViewModel {
  private _items: OrderSummaryItemViewModel[] = [];
  private _voucherItems: OrderSummaryVoucherItemViewModel[] = [];
  private _giftCardItems: OrderSummaryGiftCardItemViewModel[] = [];
  private _cateringItems: OrderSummaryArticleCombinationItemViewModel[] = [];
  private _cinema: CinemaViewModel = null;
  private _screen: ScreenViewModel = null;
  private _screening: ScreeningViewModel = null;

  private _allItems: OrderSummaryItemViewModel[] = [];
  private _orderVoucherItems: OrderSummaryItemViewModel[] = [];

  private _personal: OrderSummaryPersonalViewModel;
  private _nettoPrice = 0;
  private _taxPrice = 0;
  private _totalPrice = 0;
  private _valueToPay = 0;
  private _subTotalPrice = 0;

  get orderVoucherItems(): OrderSummaryItemViewModel[] {
    return this._orderVoucherItems;
  }

  set orderVoucherItems(value: OrderSummaryItemViewModel[]) {
    this._orderVoucherItems = value;
  }

  get items(): OrderSummaryItemViewModel[] {
    return this._items;
  }

  set items(value: OrderSummaryItemViewModel[]) {
    this._items = value;
  }

  get allItems(): OrderSummaryItemViewModel[] {
    return this._allItems;
  }

  set allItems(value: OrderSummaryItemViewModel[]) {
    this._allItems = value;
  }

  get subTotalPrice() {
    return this._subTotalPrice;
  }

  set subTotalPrice(value: number) {
    this._subTotalPrice = value;
  }

  get voucherItems(): Array<OrderSummaryVoucherItemViewModel> {
    return this._voucherItems;
  }

  set voucherItems(value: Array<OrderSummaryVoucherItemViewModel>) {
    this._voucherItems = value;
  }

  get giftCardItems(): OrderSummaryGiftCardItemViewModel[] {
    return this._giftCardItems;
  }

  set giftCardItems(value: OrderSummaryGiftCardItemViewModel[]) {
    this._giftCardItems = value;
  }

  get cateringItems(): Array<OrderSummaryArticleCombinationItemViewModel> {
    return this._cateringItems;
  }

  set cateringItems(value: Array<OrderSummaryArticleCombinationItemViewModel>) {
    this._cateringItems = value;
  }

  get cinema(): CinemaViewModel {
    return this._cinema;
  }

  set cinema(value: CinemaViewModel) {
    this._cinema = value;
  }

  get screen(): ScreenViewModel {
    return this._screen;
  }

  set screen(value: ScreenViewModel) {
    this._screen = value;
  }

  get screening(): ScreeningViewModel {
    return this._screening;
  }

  set screening(value: ScreeningViewModel) {
    this._screening = value;
  }

  get personal(): OrderSummaryPersonalViewModel {
    return this._personal;
  }

  set personal(value: OrderSummaryPersonalViewModel) {
    this._personal = value;
  }

  get nettoPrice(): number {
    return this._nettoPrice;
  }

  set nettoPrice(value: number) {
    this._nettoPrice = value;
  }

  get taxPrice(): number {
    return this._taxPrice;
  }

  set taxPrice(value: number) {
    this._taxPrice = value;
  }

  get totalPrice(): number {
    return this._totalPrice;
  }

  set totalPrice(value: number) {
    this._totalPrice = value;
  }

  get valueToPay(): number {
    return this._valueToPay;
  }

  set valueToPay(value: number) {
    this._valueToPay = value;
  }

  public get optionalExtraFees(): Array<OrderSummaryFeeViewModel> {
    return this.extraFees(true);
  }

  public get requireExtraFees(): Array<OrderSummaryFeeViewModel> {
    return this.extraFees(false);
  }

  public get hasServiceFee(): boolean {
    return this.items.some((item) => item.ticket.extraFees.some((fee) => fee.extraFeeId.toLowerCase() === identities.ticketServiceFeeId));
  }

  private extraFees(isOptional: boolean): Array<OrderSummaryFeeViewModel> {
    const extraFees: Array<OrderSummaryFeeViewModel> = new Array<OrderSummaryFeeViewModel>();
    [...this.items, ...this.orderVoucherItems].forEach((item) => {
      const ticketItem = item;
      item.ticket.extraFees.forEach((extraFee) => {
        if (extraFee.isOptional !== isOptional) {
          return true;
        }

        const exist = extraFees.find((x) => x.id === extraFee.extraFeeId && x.price === extraFee.price && x.name === extraFee.name);
        if (exist === undefined) {
          const item = new OrderSummaryFeeViewModel({
            id: extraFee.extraFeeId,
            name: extraFee.name,
            price: extraFee.price,
            ticketIds: [ticketItem.ticket.id],
            limit: ticketItem.quantity,
          });
          extraFees.push(item);
        } else {
          exist.limit += ticketItem.quantity;
          exist.ticketIds.push(ticketItem.ticket.id);
        }
      });
    });

    return extraFees;
  }

  public get ticketsPriceWithoutExtraFees() {
    return this.items.reduce((sum: number, t: OrderSummaryItemViewModel) => t.ticket.price, 0);
  }
}
