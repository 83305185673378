import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { storageKey } from '../../app.const';
import { CinemaDataProvider } from '../data-provider/cinema.data-provider';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  cinemas: CinemaViewModel[];

  constructor(protected cinemaDataProvider: CinemaDataProvider, protected stateService: StateService) {
    this.cinemaDataProvider.listViaApiModel().subscribe((cinemas) => (this.cinemas = cinemas));
  }

  public isPastOrEqual(dt?: DateTime) {
    if (!dt) {
      return true;
    }

    return dt.toUTC().toMillis() <= DateTime.utc().toMillis();
  }

  public isPast(dt?: DateTime): boolean {
    if (!dt) {
      return true;
    }

    return dt.toUTC() < DateTime.utc();
  }

  public cinemaLocal(cinemaId?: string) {
    return DateTime.now().setZone(this.getCinema(cinemaId)?.timezone);
  }

  public local(cinemaId?: string, now: DateTime = DateTime.now()): DateTime {
    return now.setZone(this.getCinema(cinemaId)?.timezone, { keepLocalTime: true });
  }

  public convertToCinemaTimeZone(value: DateTime | string, cinemaId?: string): DateTime {
    let dt: DateTime = value instanceof DateTime ? value : DateTime.fromISO(value);
    return dt.setZone(this.getCinema(cinemaId)?.timezone);
  }

  public getCinema(cinemaId?: string) {
    return this.cinemas.find((f) => f.id === (cinemaId ?? this.stateService.getItem(storageKey.chosenCinema)));
  }
}
