import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import cloneDeep from 'lodash-es/cloneDeep';
import remove from 'lodash-es/remove';
import uniqBy from 'lodash-es/uniqBy';
import { ScreenHttpService } from '../http/screen.http.service';
import { ScreeningDataProvider } from './screening.data-provider';
import { EventDataProvider } from './event.data-provider';
import { SeatsOccupancyService } from '../service/seats-occupancy.service';
import { appProjectName, storageKey } from '../../app.const';
import { EventRequestModel } from '../model/request/event.request.model';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { ScreeningDetails } from 'libs/webcomponent/src/lib/wp-model/adapters';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { ScreeningViewModelAdapter } from '../model/view-model/screening/screening.view.model.adapter';
import { IScreenColModel, IScreenModel, IScreenRowModel, ISeatModel } from '../interfaces';
import { GroupModel } from '../model/group.model';
import { ScreeningModel } from '../model/screening.model';
import { OccupancyModel } from '../model/occupancy.model';
import { DateTimeService } from '../service/datetime.service';
import { ScreenApiModel } from '../model/api-model/screen/screen.api.model';
import { SeatApiModel } from '../model/api-model/screen/seat/seat.api.model';
import { ScreenColApiModel } from '../model/api-model/screen/screen-col.api.model';
import { ScreenRowApiModel } from '../model/api-model/screen/screen-row.api.model';
import { SeatGroupViewModel } from '../model/view-model/screen/seat/seat-group.view.model';
import { ScreenElementViewModel } from '../model/view-model/screen/screen-element.view.model';
import { SeatViewModel } from '../model/view-model/screen/seat/seat.view.model';
import { ScreenViewModel } from '../model/view-model/screen/screen.view.model';
import { OccupiedStatus } from '../enum/occupied-status.enum';
import { SeatGroupSalesMode } from '../enum/seat-group-sales-mode.enum';
import { AppService } from '../service/app.service';
import { StateService } from '../state/state.service';

export interface FindByScreeningOptions {
  disableOccupancy: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenDataProvider {
  constructor(
    private screenHttpService: ScreenHttpService,
    private screeningDataProvider: ScreeningDataProvider,
    private screenOccupancyService: SeatsOccupancyService,
    private eventDataProvider: EventDataProvider,
    private dateTimeService: DateTimeService,
    private appService: AppService,
    private stateService: StateService
  ) {}

  get multiSeatGroupTypes(): Array<string> {
    return ['couch', 'lounge'];
  }

  findByScreeningId(cinemaId: string, screeningId: string, options: FindByScreeningOptions = null): Observable<ScreenViewModel> {
    const dateSelected = this.dateTimeService.local();
    const eventRequestModel = new EventRequestModel(cinemaId, dateSelected.startOf('day'), dateSelected.plus({ months: 3 }).endOf('day'));

    //check if screeningId is an event
    return this.eventDataProvider.listViaApiModel(eventRequestModel).pipe(
      switchMap((events) => {
        const ev = events.find((o) => o.screeningId == screeningId);
        if (ev) {
          this.stateService.setItem(storageKey.chosenDate, ev.timeFrom.toISODate());
          this.stateService.setItem(storageKey.isEvent, 'true');
          this.stateService.setItem(storageKey.lastEventId, ev.id);
        }

        return this.findByScreeningIdViaApiModel(cinemaId, screeningId, ev?.id, options);
      })
    );
  }

  //via Api model
  findByScreeningIdViaApiModel(cinemaId: string, screeningId: string, eventId?: string, options?: FindByScreeningOptions): Observable<ScreenViewModel> {
    const dateSelected = this.dateTimeService.local();
    return forkJoin({
      event: eventId
        ? this.eventDataProvider.findByIdViaApiModel(cinemaId, eventId, screeningId, dateSelected.toISO(), dateSelected.plus({ days: 365 }).toISO())
        : of<EventViewModel>(null),
      screening: !eventId ? this.screeningDataProvider.getScreeningDetailsById(cinemaId, screeningId, null) : of<ScreeningDetails>(null),
    }).pipe(
      switchMap((data) => {
        const screenModel = this.screenHttpService.list(cinemaId, screeningId).pipe(map((s) => (s && s.length ? this.mapFromScreenModel(s[0]) : null)));

        return forkJoin({
          screenModel: screenModel,
          occupancy: options && options.disableOccupancy ? of(null) : this.screeningDataProvider.getOccupancyList(cinemaId, screeningId),
        }).pipe(
          switchMap((res) => {
            if (res.screenModel) {
              res.screenModel.makeScreenModelForMovie(
                eventId ? null : data.screening.moviePrint,
                eventId
                  ? Object.assign(new MovieViewModel(), {
                      title: data.event.name,
                      duration: data.event.duration,
                      posters: data.event.posters,
                      description: data.event.description,
                      ratings: data.event.ratings,
                    })
                  : data.screening.movie,
                eventId ? new ScreeningViewModelAdapter().adapt(data.event) : data.screening.screening,
                eventId ? data.event.genres : data.screening.movie.genres,
                res.occupancy
              );

              if (res.occupancy) {
                this.screenOccupancyService.markOccupiedSeats(res.screenModel, res.occupancy);
              }

              return of(res.screenModel);
            } else {
              return of(null);
            }
          })
        );
      })
    );
  }

  private mapFromScreenModel(model: ScreenApiModel) {
    //this.createFakeAuditorium(model, 16, 10); // only for auditorium size test

    const screenModel: ScreenApiModel = cloneDeep<ScreenApiModel>(model);
    const mappedRows = screenModel.rows.map((x) => ({ rowNum: x.coordinate, legendSymbol: x.legend }));

    screenModel.groups = uniqBy(screenModel.groups, (x) => x.id);

    const result = new ScreenViewModel();
    result.pseats = [];
    result.groups = [];
    result.rowSymbols = [];
    result.name = screenModel.name;
    result.feature = screenModel.feature;
    result.number = screenModel.number;
    result.type = screenModel.type;
    result.defaultSeatGroupDescription = screenModel.defaultSeatDescription;
    result.screenGroupId = screenModel.screenGroupId;

    if (typeof result.feature === 'string') {
      result.feature = result.feature.split(',').join(', ');
    }

    result.groups = screenModel.groups?.map((el) =>
      Object.assign(new SeatGroupViewModel(), {
        id: el.id,
        name: el.name,
        type: el.type,
        description: el.description,
        displayMode: el.displayMode,
        salesMode: el.salesMode,
        seatIds: el.seatIds,
        color: el.color,
      })
    );

    result.screenElements = screenModel.screenElements?.map((el) =>
      Object.assign(new ScreenElementViewModel(), { id: el.id, name: el.name, seatIds: el.seatIds })
    );

    //seats builder
    screenModel.rows.forEach((_) => {
      result.pseats.push(screenModel.cols.map((_) => new SeatViewModel()));
    });
    screenModel.seats.forEach((element) => {
      const coords = this.getCoordinates(screenModel, element);
      result.pseats[coords.y][coords.x] = this.createSeatModel(screenModel, element, coords.row, coords.col);
    });

    for (let y = 0; y < result.pseats.length; y++) {
      result.rowSymbols.push(mappedRows.find((x) => x.rowNum === y).legendSymbol);

      const row = result.pseats[y];
      const symbolOfFirstSeat = row.map((x) => x.symbol).find((s) => s.length > 0);
      const rowSort = isNaN(Number(symbolOfFirstSeat)) ? 1 : Number(symbolOfFirstSeat) > 1 ? -1 : 1;
      for (let x = 0; x < row.length; x++) {
        const seat = result.pseats[y][x];
        const seatGroups = result.groups.filter((group) => group.seatIds.includes(seat.id));
        const salesTogether = seatGroups.some((group) => group.salesMode == SeatGroupSalesMode.Together);

        //preparing multiseats
        if (seatGroups && salesTogether) {
          const seatsGroup = row.filter((x) => seatGroups.some((g) => g.seatIds.includes(x.id)));
          const seatsGroupSorted = seatsGroup.sort(seatInGroupComparer(rowSort));
          seatsGroupSorted[0].groupConnectedSeats = [];

          seatsGroup.forEach((seatInGroup) => {
            if (seatInGroup.id != seatsGroupSorted[0].id) {
              const element = screenModel.seats.find((x) => x.id === seatInGroup.id);
              const coords = this.getCoordinates(screenModel, element);
              const s = this.createSeatModel(screenModel, element, coords.row, coords.col);

              seatsGroupSorted[0].groupConnectedSeats.push(s);
              seatInGroup.id = undefined;
            }
          });
        }

        remove(row, (x) => x.id === undefined);
      }
    }

    return result;
  }

  public makeScreenModelForEvent(screenModel: ScreenViewModel, event: EventViewModel, selectedScreening?: ScreeningModel, occupancy?: OccupancyModel) {
    screenModel.isEvent = true;
    const movieCopyModel = event.movieCopy;

    if (selectedScreening) {
      screenModel.id = selectedScreening.id;
      screenModel.generalAdmission = selectedScreening.generalAdmission;
      screenModel.maxOccupancy = selectedScreening.maxOccupancy;
      screenModel.audience = selectedScreening.audience;
      screenModel.screeningDuration = selectedScreening.screeningDuration;
      screenModel.isScreenSwapping = selectedScreening.isScreenSwapping;
      screenModel.screeningAvailabilityStatus = selectedScreening.availabilityStatus;
    } else {
      screenModel.id = event.screeningId;
      screenModel.generalAdmission = false;
      screenModel.screeningDuration = event.duration;
      screenModel.isScreenSwapping = false;
      screenModel.screeningAvailabilityStatus = event.availabilityStatus;

      screenModel.maxOccupancy = occupancy ? occupancy.seatsLeft + occupancy.totalOccupied : 500;
    }

    screenModel.movieName = event.name;
    screenModel.movieDate = event.timeFrom;
    screenModel.pictureUrl = '';

    screenModel.movieDescription = event.description;
    screenModel.movieGenres = event.genres;
    screenModel.movieTags = event.tagGroups || [];
    screenModel.movieRating = event.ratings;

    screenModel.reservationTimeTo = event.reservationTimeTo;
    screenModel.saleTimeTo = event.saleTimeTo;
    screenModel.screeningTimeFrom = event.timeFrom;
    screenModel.screeningTimeTo = event.timeTo;
    screenModel.occupancy = occupancy;

    if (movieCopyModel) {
      if (movieCopyModel.movie) {
        screenModel.moviePremiere = movieCopyModel.movie.premiereDate;
        screenModel.movieDuration = movieCopyModel.movie.duration;
        screenModel.movieId = movieCopyModel.movie.id;
        screenModel.posterUrl = event.posters ? event.posters[0] : movieCopyModel.movie.posters ? movieCopyModel.movie.posters[0] : '';
      }

      screenModel.movieLanguage = movieCopyModel.language;
      screenModel.movieSpeakingType = movieCopyModel.speakingType;
      screenModel.moviePrintType = movieCopyModel.printType;
      screenModel.movieSoundType = movieCopyModel.soundType;
      if (movieCopyModel.subtitles.length > 0) {
        screenModel.movieSubtitles.push(movieCopyModel.subtitles);
      }
      if (movieCopyModel.subtitles2.length > 0) {
        screenModel.movieSubtitles.push(movieCopyModel.subtitles2);
      }
    }

    if (occupancy) {
      this.screenOccupancyService.markOccupiedSeats(screenModel, occupancy);
    }

    return screenModel;
  }

  findById(cinemaId: string, screenId: string): Observable<ScreenViewModel> {
    return this.screenHttpService.findById(cinemaId, screenId).pipe(
      map((x) => {
        return this.mapFromResponseModel(x);
      })
    );
  }

  findScreenByScreeningId(cinemaId: string, screeningId: string): Observable<ScreenViewModel> {
    return this.screenHttpService.list(cinemaId, screeningId).pipe(
      map((x: Array<ScreenApiModel>) => {
        const first: ScreenApiModel | undefined = x.shift();
        if (!first) {
          return null;
        }
        return this.mapFromResponseModel(first);
      })
    );
  }

  mapFromResponseModel(model: ScreenApiModel) {
    const screenModel: ScreenApiModel = cloneDeep<ScreenApiModel>(model);
    const mappedRows = screenModel.rows.map((x) => ({ rowNum: x.coordinate, legendSymbol: x.legend }));

    screenModel.groups = uniqBy(screenModel.groups, (x) => x.id);

    const result = new ScreenViewModel();
    result.seats = [];
    result.groups = [];
    result.rowSymbols = [];
    result.name = screenModel.name;
    result.feature = screenModel.feature;
    result.number = screenModel.number;
    result.type = screenModel.type;
    result.defaultSeatGroupDescription = screenModel.defaultSeatDescription;
    result.screenGroupId = screenModel.screenGroupId;

    if (typeof result.feature === 'string') {
      result.feature = result.feature.split(',').join(', ');
    }
    result.groups = screenModel.groups?.map((el) =>
      Object.assign(new SeatGroupViewModel(), { id: el.id, name: el.name, type: el.type, description: el.description })
    );
    result.screenElements = screenModel.screenElements?.map((el) =>
      Object.assign(new ScreenElementViewModel(), { id: el.id, name: el.name, seatIds: el.seatIds })
    );

    //seats builder
    screenModel.rows.forEach((_) => {
      result.pseats.push(screenModel.cols.map((_) => new SeatViewModel()));
    });
    screenModel.seats.forEach((element) => {
      const coords = this.getCoordinates(screenModel, element);
      result.seats[coords.y][coords.x] = this.createSeatModel(screenModel, element, coords.row, coords.col);
    });

    for (let y = 0; y < result.pseats.length; y++) {
      result.rowSymbols.push(mappedRows.find((x) => x.rowNum === y).legendSymbol);

      const row = result.pseats[y];
      const symbolOfFirstSeat = row.map((x) => x.symbol).find((s) => s.length > 0);
      const rowSort = isNaN(Number(symbolOfFirstSeat)) ? 1 : Number(symbolOfFirstSeat) > 1 ? -1 : 1;
      for (let x = 0; x < row.length; x++) {
        const seat = result.pseats[y][x];

        //preparing multiseats
        if (seat.groupId && seat.groupId.length > 0 && this.multiSeatGroupTypes.some((m) => seat.groupTypes.indexOf(m) >= 0)) {
          const seatsGroup = result.pseats[y].filter((x) => x.groupId === seat.groupId);
          if (seatsGroup.length === 1) {
            continue;
          }

          const seatsGroupSorted = seatsGroup.sort(seatInGroupComparer(rowSort));
          seatsGroupSorted[0].groupConnectedSeats = [];
          seatsGroup.forEach((seatInGroup) => {
            if (seatInGroup.id != seatsGroupSorted[0].id) {
              const element = screenModel.seats.find((x) => x.id === seatInGroup.id);
              const coords = this.getCoordinates(screenModel, element);
              seatsGroupSorted[0].groupConnectedSeats.push(this.createSeatModel(screenModel, element, coords.row, coords.col));
              seatInGroup.id = undefined;
            }
          });

          x += seatsGroup.length - 2;
        }

        remove(row, (x) => x.id === undefined);
      }
    }

    return result;
  }

  createSeatModel(screenModel: IScreenModel, element: SeatApiModel, row: ScreenRowApiModel, col: ScreenColApiModel): SeatViewModel {
    const seat = new SeatViewModel();
    seat.occupied = OccupiedStatus.Free;
    seat.id = element.id;
    seat.isPlaceholder = element.kind == '1';
    seat.wheelchairSeat = element.wheelchairSeat;

    seat.groupId = element.groupId;
    seat.symbol = element.symbol;
    seat.rowNumber = row.coordinate.toString();
    seat.rowSymbol = row.legend;
    seat.colNumber = col.coordinate.toString();
    seat.defaultGroupDescription = screenModel.defaultSeatDescription;
    seat.defaultSeatGroupName = screenModel.defaultSeatGroupName;

    const groups: Array<SeatGroupViewModel> = this.findAssignedGroupsForSeat(seat.id, screenModel.groups);

    if (groups.length > 0) {
      seat.groupTypes = groups
        .filter((group) => group.type !== 'wheelchair')
        .map((group: SeatGroupViewModel) => {
          seat.groupColor = group.color;
          return group.type;
        });
      seat.groups = groups;
      seat.groupDescriptionCollection = groups.filter((o) => o.description).map((x) => x.description);
    }

    const screenElement = screenModel.screenElements?.find((o) => o.seatIds.map((o) => o.toLowerCase()).indexOf(element.id) > -1);
    if (screenElement) {
      seat.screenElements = [screenElement.name.toLowerCase()];
    }

    return seat;
  }

  findAssignedGroupsForSeat(seatId: string, groups: Array<any>): Array<SeatGroupViewModel> {
    const res: Array<SeatGroupViewModel> = new Array<SeatGroupViewModel>();
    for (const group of groups) {
      if (group.seatIds.indexOf(seatId) >= 0) {
        res.push(group);
      }
    }
    return res;
  }

  private getCoordinates(screenModel: IScreenModel, element: SeatApiModel) {
    const row = screenModel.rows.find((x) => x.id === element.rowId);
    const col = screenModel.cols.find((x) => x.id === element.colId);
    return {
      x: col.coordinate,
      y: row.coordinate,
      row,
      col,
    };
  }

  private createFakeAuditorium(model: IScreenModel, rowsNumber: number, colsNumber: number) {
    const rows: IScreenRowModel[] = [];
    for (var y = 0; y < rowsNumber; y++) {
      rows.push({ id: `row_${y}`, legend: `${y + 1}`, coordinate: y });
    }

    model.rows = rows;

    const cols: IScreenColModel[] = [];
    for (var x = 0; x < colsNumber; x++) {
      cols.push({ id: `col_${x}`, coordinate: x });
    }
    model.cols = cols;

    const seats: ISeatModel[] = [];
    for (var x = 0; x < colsNumber; x++) {
      for (var y = 0; y < rowsNumber; y++) {
        seats.push({ id: `id_${x}_${y}`, colId: `col_${x}`, rowId: `row_${y}`, kind: '0', groupId: null, symbol: `${x + 1}` });
      }
    }

    model.seats = seats;
  }
}

function seatInGroupComparer(rowSort: number): (a: SeatViewModel, b: SeatViewModel) => number {
  return (a, b) => {
    return rowSort === 1
      ? isNaN(Number(a.symbol))
        ? a.symbol.localeCompare(b.symbol)
        : Number(a.symbol) - Number(b.symbol)
      : isNaN(Number(a.symbol))
      ? b.symbol.localeCompare(a.symbol)
      : Number(b.symbol) - Number(a.symbol);
  };
}
