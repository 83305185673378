import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, iif, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { storageKey } from '../../app.const';
import { CinemaDataProvider } from '../data-provider/cinema.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { CateringViewModel } from '../model/view-model/order/catering.view.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { StateService } from './state.service';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class CateringStateService {
  private _cateringSaleEnabled = false;
  private _cinemaId: string;
  private state = new BehaviorSubject<boolean>(null);
  public state$ = this.state.asObservable();

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderDataProvider: OrderDataProvider,
    private cinemaDataProvider: CinemaDataProvider,
    private stateService: StateService
  ) {
    this._cinemaId = this.stateService.getItem(storageKey.chosenCinema);
    this._cateringSaleEnabled =
      this.stateService.getItem(storageKey.cateringEnabled) === null ? false : JSON.parse(this.stateService.getItem(storageKey.cateringEnabled));
    this.state.next(this._cateringSaleEnabled);
  }

  public checkAvailability(forceChecking: boolean = false): void {
    if (!this.environment.constants.useFB) {
      return;
    }

    console.log('trying..');
    if (this.stateService.getItem(storageKey.cateringEnabled) === null || forceChecking) {
      console.log('checking...', 'previous: ' + this.stateService.getItem(storageKey.cateringEnabled), 'force: ' + forceChecking);

      of(this._cinemaId)
        .pipe(
          switchMap((cinemaId: string) => iif(() => cinemaId !== null, this.checkWithoutReceivingCinemaList(cinemaId), this.checkWithReceivingCinemaList()))
        )
        .subscribe();
    }
  }

  public checkAvailabilityWithCinemaContext(cinemaId: string): Promise<boolean> {
    return this.orderDataProvider
      .getCatering(cinemaId)
      .pipe(
        catchError(() => {
          return of(false);
        }),
        map((value) => value instanceof CateringViewModel && value.articles.length > 0)
      )
      .toPromise();
  }

  private checkWithReceivingCinemaList() {
    return this.cinemaDataProvider.listViaApiModel().pipe(
      map((cinemaList: CinemaViewModel[]) => (this._cinemaId = cinemaList[0]?.id)),
      switchMap((cinemaId: string) => this.checkWithoutReceivingCinemaList(cinemaId))
    );
  }

  private checkWithoutReceivingCinemaList(cinemaId: string) {
    return this.orderDataProvider.getCatering(cinemaId).pipe(
      catchError(() => {
        return of(false);
      }),
      tap((value) => {
        this._cateringSaleEnabled = value instanceof CateringViewModel && value.articles.length > 0;
        this.setCateringFlow(this._cateringSaleEnabled);
      })
    );
  }

  public setCateringFlow(enabled: boolean): void {
    console.log('checked...', 'previous: ' + this.stateService.getItem(storageKey.cateringEnabled), 'current: ' + this._cateringSaleEnabled.toString());
    this.stateService.setItem(storageKey.cateringEnabled, enabled.toString());
    this.state.next(this._cateringSaleEnabled);
  }

  get cateringSaleEnabled(): boolean {
    return this._cateringSaleEnabled;
  }

  get cinemaId(): string {
    return this._cinemaId;
  }

  set cinemaId(value: string) {
    this._cinemaId = value;
  }
}
