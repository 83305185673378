import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MovieCopyResponseModel } from '../model/response/movie-copy.response.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { MovieCopyRequestModel } from '../model/request/movie-copy.request.model';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';

@Injectable({
  providedIn: 'root',
})
export class MovieCopyHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable()
  list(model: MovieCopyRequestModel): Observable<MovieCopyResponseModel[]> {
    const params = { cinemaId: model.cinema, dateTimeFrom: model.dateTimeFrom, dateTimeTo: model.dateTimeTo };
    return this.http.get<MovieCopyResponseModel[]>(`/movieprint`, { params });
  }

  @Cacheable()
  findById(id: string): Observable<MovieCopyResponseModel> {
    return this.http.get<MovieCopyResponseModel>(`/movieprint/${id}`);
  }

  //via API model
  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
  })
  listViaApiModel(model: MovieCopyRequestModel): Observable<MoviePrintApiModel[]> {
    const params = model.cinema
      ? { cinemaId: model.cinema, dateTimeFrom: model.dateTimeFrom, dateTimeTo: model.dateTimeTo }
      : { dateTimeFrom: model.dateTimeFrom, dateTimeTo: model.dateTimeTo };
    return this.http.get<MoviePrintApiModel[]>(`/movieprint`, { params });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
  })
  findByIdViaApiModel(id: string): Observable<MoviePrintApiModel> {
    return this.http.get<MoviePrintApiModel>(`/movieprint/${id}`);
  }
}
