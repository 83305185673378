<div class="container">
  <div class="row">
    <div class="text-center col-12 p-0">
      <app-steps [step]="2"></app-steps>
    </div>
  </div>

  <div class="main-container">
    <div [smooth-in]="loadingService.loadingStatus === 'success'">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="mt-3 mb-4 my-sm-5">
            <app-book-movie-list [screen]="basketPageModel?.screen" [cinema]="orderState?.cinema"
                                 [movieCopy]="basketPageModel?.movieCopy"></app-book-movie-list>
          </div>
          <app-policy></app-policy>
          <app-login [modal]="false"></app-login>
        </div>
        <div class="col-12 col-md-6 my-2 my-md-5 text-right order-first order-md-last">
          <app-simple-countdown></app-simple-countdown>
        </div>
      </div>

      <ng-container *ngIf="basketPageModel">
        <div class="row mt-4 rgap-1">
          <div class="col-md-6">
            <app-sidebar>
              <div sidebar-title>
                {{ 'steps.2.label' | translate | uppercase }}
              </div>
              <div sidebar-body>
                <ng-container *ngIf="orderState">
                  <app-basket-list [basketPageModel]="basketPageModel" [orderModel]="configOrder" [vouchers]="vouchers"
                                   (changeEvent)="onChangedSeatTicket($event)">
                  </app-basket-list>
                </ng-container>
              </div>
            </app-sidebar>
          </div>
          <div class="col-md-6">
            <app-sidebar>
              <div sidebar-title>
                {{ 'order.title' | translate | uppercase}}
              </div>
              <div sidebar-body>
                <app-order-tickets-summary [order]="configOrder" [ticketList]="ticketList"
                                           [couldChangeOptionalFees]="true"
                                           [screeningId]="basketPageModel.screening?.id">
                </app-order-tickets-summary>
              </div>
            </app-sidebar>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-4">
            <app-screen-navigation (navigationEvent)="onNavigationClick($event)"
                                   [ScreeningId]="basketPageModel.screening?.id"
                                   [isNextButtonDisabled]="!ticketList || 0 === ticketList.length" [totalPrice]="0.00">
            </app-screen-navigation>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<app-popup [isVisible]="warningModalVisibled">
  <div class="row pb-4">
    <div class="col-12">
      <div class="question" [innerHTML]="'popups.deliver_to_seat_question' | translate"></div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-6">
      <button class="btn btn-lg btn-default" (click)="onModalClick('skip')">{{ 'popups.buttons.decline' | translate
        }}</button>
    </div>
    <div class="col-6">
      <button class="btn btn-lg btn-primary" (click)="onModalClick('continue')">{{ 'popups.buttons.accept' | translate
        }}</button>
    </div>
  </div>
</app-popup>