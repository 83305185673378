import { Component, OnInit } from '@angular/core';
import { ScreeningSelectCinemaComponent } from 'libs/shared/src/lib/component/screening/select-cinema/select-cinema.component';

@Component({
  selector: 'app-screening-select-cinema',
  templateUrl: './select-cinema.component.html',
})
export class OneilScreeningSelectCinemaComponent extends ScreeningSelectCinemaComponent implements OnInit {
  constructor() {
    super()
  }
}
