import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'apps/oneil/src/environments/environment';
import { CardDataProvider } from 'libs/core/src/lib/data-provider/card.data-provider';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { VirtualCinemaService } from 'libs/core/src/lib/service/virtual-cinema/virtual-cinema.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { OrderSummaryComponent } from 'libs/shared/src/lib/component/order/order-summary/order-summary.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-order-order-summary',
  templateUrl: './order-summary.component.html',
})
export class OneilOrderSummaryComponent extends OrderSummaryComponent {
  env = environment;

  constructor(
    protected translateService: TranslateService,
    protected virtualCinemaService: VirtualCinemaService,
    protected totalizerService: TotalizerService,
    protected orderStateService: OrderStateService,
    protected appService: AppService,
    protected voucherService: VoucherService,
    protected messageService: MessageService,
    protected dateTimeService: DateTimeService,
    protected modalService: BsModalService,
    protected loadingService: LoadingService,
    protected orderDataProvider: OrderDataProvider,
    protected cardDataProvider: CardDataProvider
  ) {
    super(
      environment,
      translateService,
      virtualCinemaService,
      totalizerService,
      orderStateService,
      appService,
      voucherService,
      messageService,
      dateTimeService,
      modalService,
      loadingService,
      orderDataProvider,
      cardDataProvider
    );
  }
}
