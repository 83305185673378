import {DateTime} from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { UserVoucherItemApiModel } from '../../../api-model/user-history/voucher-item/user-voucher-item.api.model';

export class UserVoucherItemViewModel extends AbstractViewModel<UserVoucherItemApiModel> {
  public id: string = null;
  public orderId: string = null;
  public itemId: string = null;
  public name: string = null;
  public price: number = null;
  public quantity: number = null;
  public pickupTime: DateTime = null;
  public additionalOrderId: string = null;
  public additionalOrderDate: DateTime = null;

  constructor(
    protected apiModel: UserVoucherItemApiModel = new UserVoucherItemApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.orderId = this.apiModel.orderId;
    this.itemId = this.apiModel.itemId;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.pickupTime = this.apiModel.pickupTime;
    this.additionalOrderId = this.apiModel.additionalOrderId;
    this.additionalOrderDate = this.apiModel.additionalOrderDate;
  }

  toApiModel(): UserVoucherItemApiModel {
    return undefined;
  }
}
