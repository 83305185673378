<div class="row  align-items-center mt-5 pl-3 pr-3">
  <div class="col-12 col-sm-12 text-sm-right text-center">

  </div>

  <div class="col-12 col-sm-12 header mb-3">
    <div class="row p-2">
      <div class="col-4 text-left">
        {{ 'order.ticketGeneralAdmission.header.tickets' | translate }}
      </div>
      <div class="col-3 text-right">
        {{ 'order.ticketGeneralAdmission.header.price' | translate }} (&nbsp;{{ currency }}&nbsp;)
      </div>
      <div class="col-5 text-right">
        {{ 'order.ticketGeneralAdmission.header.quantity' | translate }}
      </div>
    </div>
  </div>

  <ng-container *ngFor="let ticket of ticketList">

    <div class="col-12 col-sm-12 text-center">
      <div class="row ticket mt-2 mb-2 p-2">

        <div class="col-4 text-left">
          {{ ticket.name }}
        </div>

        <div class="col-3 text-right">
          <span class="font-weight-bold"> {{ ticket.price | number:'1.2-2' | money}}</span>
        </div>

        <div class="col-5 d-flex justify-content-end pr-0">
            <span class="increment" [ngClass]="{ 'disabled': limit === 0 || !selectedTickets[ticket.id] }" (click)="onDecrementClick(ticket)"><i class="fa fa-minus"></i></span>
            <b class="count px-3">{{ selectedTickets[ticket.id] ? selectedTickets[ticket.id] : 0 }}</b>
            <span class="increment" [ngClass]="{ 'disabled': limit === 0 || (ticketUsedByGroup[ticket.seatGroupId] >= ticketLimitByGroup[ticket.seatGroupId]) || ticket.availableAmount === 0  || sumAllTicketUsed >= limit}" (click)="onIncrementClick(ticket)"><i class="fa fa-plus"></i></span>
        </div>

      </div>


    </div>

  </ng-container>

  <div class="col-12 col-sm-12 text-center">
    <div class="row ticket mt-2 mb-2 p-2">

      <div class="col-7 text-left">
        <b>SUBTOTAL</b>
      </div>
      <div class="col-5 text-right">
        <b>{{ totalValue | number:'1.2-2' | money}}</b>
      </div>

    </div>

  </div>

</div>


