import {Component, Inject, OnInit} from '@angular/core';
import { OrderStateModel } from 'libs/core/src/lib/model/state/order.state.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  public orderState: Observable<OrderStateModel>;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected orderStateService: OrderStateService,
  ) {
  }

  ngOnInit() {
    this.orderState = this.orderStateService.state$;
  }
}
