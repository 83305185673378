import uniqBy from 'lodash-es/uniqBy';
import { appProjectName } from 'libs/core/src/app.const';
import { AppService } from '../../../service/app.service';
import { ViewModelBuilderInterface } from '../../builder/view-model-builder.interface';
import { CateringArticleGroupViewModel, CateringArticleViewModel, CateringArticleModifierViewModel, CateringViewModel } from '../../view-model/order/catering.view.model';
import { CateringAggregationArticleGroupViewModel, CateringAggregationArticleModifierItemViewModel, CateringAggregationArticleModifierViewModel, CateringAggregationArticleViewModel, CateringAggregationViewModel } from './catering-aggregation.view.model';

export class CateringAggregationBuilder implements ViewModelBuilderInterface<CateringAggregationViewModel> {
  private cateringSource: CateringViewModel = null;
  private appService: AppService = null;

  public constructor(cateringSource: CateringViewModel, appService: AppService) {
    this.cateringSource = cateringSource;
    this.appService = appService;
  }

  public build(): CateringAggregationViewModel {
    const groupCollection: Array<CateringAggregationArticleGroupViewModel> = this.cateringSource
      .groups.map(groupSource => this.buildGroupViewModel(groupSource));

    const articleCollection: Array<CateringAggregationArticleViewModel> = this.cateringSource
      .articles.map(articleSource => this.buildArticleViewModel(groupCollection, articleSource));

    const aggregation = new CateringAggregationViewModel();
    aggregation.groups = groupCollection.map(aggregationGroup => {
      aggregationGroup.articles = articleCollection.filter(aggregationArticle =>
        aggregationArticle.parentGroup && aggregationArticle.parentGroup.id === aggregationGroup.id
      );
      return aggregationGroup;
    });

    aggregation.articles = articleCollection;

    if (this.isMuviProject()) {
      aggregation.articles = uniqBy(aggregation.articles, x => x.id);
    }

    return aggregation;
  }

  /**
   * Builds group view model
   */
  private buildGroupViewModel(groupSource: CateringArticleGroupViewModel): CateringAggregationArticleGroupViewModel {
    const groupViewModel: CateringAggregationArticleGroupViewModel = new CateringAggregationArticleGroupViewModel();
    groupViewModel.id = groupSource.id;
    groupViewModel.description = groupSource.description;
    groupViewModel.graphicUrl = groupSource.graphicUrl;
    groupViewModel.name = groupSource.name;

    if (groupSource.parentGroupId) {
      const parentGroupSource: CateringArticleGroupViewModel | undefined = this.cateringSource
        .groups.find(element => element.id === groupSource.parentGroupId);

      groupViewModel.parentGroup = parentGroupSource ? this.buildGroupViewModel(parentGroupSource) : null;
    }

    return groupViewModel;
  }

  /**
   * Builds article view model
   */
  private buildArticleViewModel(
    groupAggregationCollection: Array<CateringAggregationArticleGroupViewModel>,
    articleSource: CateringArticleViewModel): CateringAggregationArticleViewModel {
    const articleViewModel: CateringAggregationArticleViewModel = new CateringAggregationArticleViewModel();
    articleViewModel.id = articleSource.id;
    articleViewModel.name = articleSource.name;
    articleViewModel.price = articleSource.price;
    articleViewModel.taxRate = articleSource.taxRate;
    articleViewModel.description = articleSource.description;
    articleViewModel.isLocked = articleSource.isLocked;
    articleViewModel.graphicUrl = articleSource.graphicUrl;

    if (articleSource.parentGroupId) {
      const parentGroup: CateringAggregationArticleGroupViewModel | undefined = groupAggregationCollection
        .find(element => element.id === articleSource.parentGroupId);

      articleViewModel.parentGroup = parentGroup ? parentGroup : null;
    }

    articleViewModel.subArticleList = articleSource.subArticleList.map(element => this.buildArticleViewModel(
      groupAggregationCollection, element
    ));

    articleViewModel.replacementList = articleSource.replacementList.map(element => this.buildArticleViewModel(
      groupAggregationCollection, element
    ));

    articleViewModel.modifierArticleList = articleSource.modifierCollectionList/*.filter(x => this.isMuviProject() ? x.type !== ModifierTypeEnum.NOTE : x)*/
      .map(element => this.buildArticleModifierViewModel(
        element, articleSource
      ));

    return articleViewModel;
  }

  private isMuviProject(): boolean {
    return this.appService.isProject(appProjectName.MUVI);
  }

  /**
   * Builds article modifier view model
   */
  private buildArticleModifierViewModel(
    modifierSource: CateringArticleModifierViewModel,
    articleSource: CateringArticleViewModel | null = null): CateringAggregationArticleModifierViewModel {
    const modifierViewModel: CateringAggregationArticleModifierViewModel = new CateringAggregationArticleModifierViewModel();
    modifierViewModel.id = modifierSource.id;
    modifierViewModel.type = modifierSource.type;
    modifierViewModel.name = modifierSource.name;
    modifierViewModel.isRequired = modifierSource.isRequired;
    modifierViewModel.multiChoice = modifierSource.multiChoice;
    modifierViewModel.multiChoiceMax = modifierSource.multiChoiceMax || modifierSource.itemCollection.length;
    modifierViewModel.multiChoiceMin = modifierSource.multiChoiceMin || (modifierViewModel.isRequired ? 1 : 0);
    modifierViewModel.separateItem = modifierSource.separateItem;
    modifierViewModel.itemCollection = modifierSource.itemCollection.map(element => {
      const itemViewModel: CateringAggregationArticleModifierItemViewModel = new CateringAggregationArticleModifierItemViewModel();
      itemViewModel.id = element.id;
      itemViewModel.name = element.name;
      itemViewModel.description = element.description;
      itemViewModel.type = modifierSource.type;
      itemViewModel.price = element.price * element.quantity;
      itemViewModel.quantity = element.quantity;
      itemViewModel.relatedModifiers = element.relatedItemList.map(elem => {
        const modifierViewModel: CateringAggregationArticleModifierViewModel = new CateringAggregationArticleModifierViewModel();
        modifierViewModel.id = elem;
        return modifierViewModel;
      });

      return itemViewModel;
    });

    return modifierViewModel;
  }
}
