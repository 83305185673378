import { DateTime, Settings } from 'luxon';

export abstract class DateHelper {
  public static getMiliseconds(lifetime: string): number {
    const multiplier: number = this.getMultiplier(lifetime);
    const prefix: number = Number.parseInt(lifetime.slice(0, lifetime.length - 1));
    return prefix * multiplier;
  }

  private static getMultiplier(format: string): number {
    if (format.endsWith('h')) {
      return 60 * 60 * 1000;
    }

    if (format.endsWith('s')) {
      return 1 * 1000;
    }

    if (format.endsWith('m')) {
      return 60 * 1000;
    }

    if (format.endsWith('d')) {
      return 24 * 60 * 60 * 1000;
    }
  }

  public static getWithSkippedTimezone(value: DateTime): DateTime {
    let stringify: string = value.toString();
    stringify = stringify.substring(0, 19);
    return DateTime.fromISO(stringify);
  }
}

export function getDates(startAt: DateTime, range = 7): DateTime[] {
  const a = new Array(range);
  for (let i = 0; i < range; ++i) {
    a[i] = startAt.startOf('day').plus({ days: i });
  }
  return a;
}

export function getTimes(startAt: DateTime, interval = 15): DateTime[] {
  const range = Math.floor((24 * 60) / (interval < 0 ? 1 : interval));
  const a = new Array(range);
  for (let i = 0; i < range; ++i) {
    a[i] = startAt.startOf('day').plus({ minutes: i * interval });
  }
  return a;
}

export function compareDateTime(date1: DateTime, date2: DateTime): number {
  return compareDate(date1.toJSDate(), date2.toJSDate());
}

export function compareDate(date1: Date, date2: Date): number {
  if (date1.getTime() === date2.getTime()) return 0;
  return date1 > date2 ? 1 : -1;
}

export function mergeToDate(time: string, date: DateTime): DateTime {
  const t = time.split(':');
  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
    hour: parseInt(t[0], 10),
    minute: parseInt(t[1], 10),
  });
}

export function convertDayToMillis(date: DateTime): number {
  return date.startOf('day').toMillis();
}

export function fromISOToDefaultZone(date: string): DateTime {
  return DateTime.fromISO(date, { setZone: true }).setZone(Settings.defaultZoneName, {
    keepLocalTime: true,
  });
}

export function getDropdownDays(min: number, max: number) {
  return range(min, max).map((m) => {
    return { id: m.toString().padStart(2, '0'), value: m };
  });
}

export function getDropdownMonths(min: number, max: number) {
  return range(min, max).map((m) => {
    return { id: m.toString().padStart(2, '0'), value: DateTime.local().set({ month: m, hour: 0, minute: 0, second: 0, millisecond: 0 }).monthLong };
  });
}

export function getDropdownYears() {
  let currentYear = DateTime.local().year;
  return range(currentYear - 100, currentYear)
    .map((m) => {
      return { id: m.toString(), value: m };
    })
    .reverse();
}

export function range(min: number, max: number) {
  return Array.from({ length: max - min + 1 }, (v, k) => k + min);
}

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
