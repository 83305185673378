import { inject, Injectable } from '@angular/core';
import { first, Observable, of, switchMap, tap } from 'rxjs';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { UserDataProvider } from '../data-provider/user.data-provider';
import { getKeyByValueBitwise } from '../helper/map.helper';
import { UseCardRequestModel } from '../model/request/use-card.request.model';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { PaymentMethodViewModel } from '../model/view-model/order/payment-method/payment-method.view.model';
import { OrderStateService } from '../state/order.state.service';
import { OrderHttpService } from '../http/order.http.service';
import { CardPaymentEnum } from '../enum/card-payment.enum';
import { CardViewModel } from '../model/view-model/account-items/card/card.view.model';
import { WebComponentsService } from 'libs/webcomponent/src/lib/service/webcomponents.service';
import { CmsHelper } from '../helper/cms.helper';
import { WpOptionEnum } from 'libs/webcomponent/src/lib/enum/wp-option.enum';

const paymentTypesMap = new Map<CardPaymentEnum, number>([
  [CardPaymentEnum.GiftCard, 32],
  [CardPaymentEnum.Prepaid, 4],
  [CardPaymentEnum.Discount, 2],
  [CardPaymentEnum.Loyalty, 1],
]);

@Injectable({
  providedIn: 'root',
})
export class CardPaymentService {
  paymentMethods: PaymentMethodViewModel[];
  internalPaymentTypes: Map<string, string> = null;

  constructor(
    protected orderStateService: OrderStateService,
    protected orderDataProvider: OrderDataProvider,
    protected userDataProvider: UserDataProvider,
    protected cardDataProvider: CardDataProvider,
    protected cmsHelper: CmsHelper
  ) {
    if (this.cmsHelper.canUseCms) {
      const webComponentsService = inject(WebComponentsService);

      const internalPaymentTypeIdForGiftCard = webComponentsService?.getWpOption(WpOptionEnum.INTERNAL_PAYMENT_TYPE_ID_FOR_GIFTCARD) as string;
      const internalPaymentTypeIdForPrePaid = webComponentsService?.getWpOption(WpOptionEnum.INTERNAL_PAYMENT_TYPE_ID_FOR_PREPAID) as string;

      this.internalPaymentTypes = new Map()
        .set(CardPaymentEnum.GiftCard, internalPaymentTypeIdForGiftCard)
        .set(CardPaymentEnum.Prepaid, internalPaymentTypeIdForPrePaid);
    }

    if (this.orderStateService.getState()?.cinemaId && this.orderStateService.getState()?.orderId) {
      this.orderDataProvider
        .getPaymentMethodList(this.orderStateService.getState().cinemaId, this.orderStateService.getState().orderId)
        .pipe(first())
        .subscribe((s) => (this.paymentMethods = s));
    }
  }

  canUse(cardType: number) {
    if (this.orderStateService.useIsDisabled()) {
      return false;
    }

    return this.cardPaymentAvailable(cardType);
  }

  cardPaymentAvailable(cardType: number) {
    if (!this.orderStateService.getState()?.cinemaId || !this.orderStateService.getState()?.orderId) {
      return false;
    }

    return this.paymentMethods?.some((p) => p.name === getKeyByValueBitwise(paymentTypesMap, cardType)) || !!this.getPaymentTypeIdForCms(cardType);
  }

  getPaymentTypeIdByCardType(cardType: number) {
    const result = this.paymentMethods?.find((p) => p.name === getKeyByValueBitwise(paymentTypesMap, cardType))?.id;
    return result ?? this.getPaymentTypeIdForCms(cardType);
  }

  getPaymentTypeIdForCms(cardType: number) {
    if (this.isPrepaid(cardType)) {
      return this.internalPaymentTypes.get(CardPaymentEnum.Prepaid);
    } else if (this.isGiftCard(cardType)) {
      return this.internalPaymentTypes.get(CardPaymentEnum.GiftCard);
    }

    return null;
  }

  useCard(cardId: string, cardType: number): Observable<OrderViewModel> {
    if (!this.orderStateService.getState()?.cinemaId || !this.orderStateService.getState()?.orderId) {
      return of(null);
    }

    const request = [
      ...this.orderStateService
        .getState()
        .order.paymentMethods?.filter((f) => f.cardId)
        .map((m) => new UseCardRequestModel(m.cardId, m.id)),
      this.makeUseCardRequestModel(cardId, cardType),
    ];

    OrderHttpService.cacheBuster$.next();
    return this.cardDataProvider.useCards(this.orderStateService.getState().cinema.id, this.orderStateService.getState().order.id, request).pipe(
      tap({
        next: (order) => {
          this.orderStateService.setOrder(order);
        },
      })
    );
  }

  useCards(request: UseCardRequestModel[]): Observable<OrderViewModel> {
    if (!this.orderStateService.getState()?.cinemaId || !this.orderStateService.getState()?.orderId) {
      return of(null);
    }

    const cardsFromOrder = this.orderStateService
      .getState()
      .order.paymentMethods?.filter((f) => f.cardId)
      .map((m) => new UseCardRequestModel(m.cardId, m.id));

    const fullRequest = [
      ...cardsFromOrder,
      ...request.filter((f) => !cardsFromOrder.some((c) => c.cardId === f.cardId)).map((m) => new UseCardRequestModel(m.cardId, m.typeId)),
    ];

    OrderHttpService.cacheBuster$.next();
    return this.cardDataProvider.useCards(this.orderStateService.getState().cinema.id, this.orderStateService.getState().order.id, fullRequest).pipe(
      tap({
        next: (order) => {
          this.orderStateService.setOrder(order);
        },
      })
    );
  }

  useCardByNumber(cardNumber: string) {
    return this.cardDataProvider.info(cardNumber).pipe(
      switchMap((cardInfo) => {
        const request = [
          ...this.orderStateService
            .getState()
            .order.paymentMethods?.filter((f) => f.cardId)
            .map((m) => new UseCardRequestModel(m.cardId, m.id)),
          this.makeUseCardRequestModel(cardInfo.id, cardInfo.type),
        ];

        return this.cardDataProvider.useCards(this.orderStateService.getState().cinemaId, this.orderStateService.getState().orderId, request).pipe(
          tap({
            next: (order) => {
              this.orderStateService.setOrder(order);
            },
          })
        );
      })
    );
  }

  public makeUseCardRequestModel(cardId: string, cardTypeNumber: number) {
    return new UseCardRequestModel(cardId, this.getPaymentTypeIdByCardType(cardTypeNumber));
  }

  public isPrepaid(type: number) {
    const prepaidType = paymentTypesMap.get(CardPaymentEnum.Prepaid);
    return (prepaidType & type) === prepaidType;
  }

  public isGiftCard(type: number) {
    const giftCardType = paymentTypesMap.get(CardPaymentEnum.GiftCard);
    return (giftCardType & type) === giftCardType;
  }

  public isDiscountCard(type: number) {
    const giftCardType = paymentTypesMap.get(CardPaymentEnum.Discount);
    return (giftCardType & type) === giftCardType;
  }

  public isLoyalty(type: number) {
    const loyaltyType = paymentTypesMap.get(CardPaymentEnum.Loyalty);
    return (loyaltyType & type) === loyaltyType;
  }

  public isEmpty(card: any) {
    if (card instanceof CardViewModel) {
      return card.valueBalance <= 0;
    }
  }
}
