import { Component, OnInit } from '@angular/core';
import { SidebarComponent } from 'libs/shared/src/lib/component/layout/template/sidebar/sidebar.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class OneilSidebarComponent extends SidebarComponent implements OnInit {
  constructor() {
    super()
  }
}
