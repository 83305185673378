import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { UserLoyaltyPopupTypeEnum } from 'libs/core/src/lib/model/enum/user-loyalty-popup-type';
import { AccountItemsViewModel } from 'libs/core/src/lib/model/view-model/account-items/account-items.view.model';
import { UserViewModel } from 'libs/core/src/lib/model/view-model/user/user.view.model';
import { CardPaymentService } from 'libs/core/src/lib/service/card-payment.service';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { LoyaltyService } from 'libs/core/src/lib/service/loyalty.service';
import { NavigationService } from 'libs/core/src/lib/service/navigation/navigation.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { forkJoin, tap } from 'rxjs';
import { IAccountItem } from '../../account-items/account-items.component';

@Component({
  template: '',
})
export class UserLoyaltyComponent implements OnInit {
  protected user: UserViewModel;
  protected accountItems: AccountItemsViewModel;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected authStateService: AuthStateService,
    protected voucherService: VoucherService,
    protected orderStateService: OrderStateService,
    protected router: Router,
    protected cardPaymentService: CardPaymentService,
    protected navigationService: NavigationService,
    protected loyaltyService: LoyaltyService
  ) {}

  ngOnInit() {
    this.authStateService.state$.subscribe((user) => {
      if (user?.token) {
        this.user = new UserViewModel(user?.user);

        this.userDataProvider
          .getAccountItemsViaApiModel()
          .pipe(
            tap((response) => {
              this.accountItems = response;
            })
          )
          .subscribe({ complete: () => this.loadingService.hideLoader(LoaderEnum.MAIN) });
      }
    });
  }

  useItem(item: IAccountItem) {
    switch (item.modelType) {
      case 'voucher':
        if (!this.orderStateService.getState()?.order) {
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherWarning);
          return;
        }
        this.loadingService.showLoader(LoaderEnum.MAIN);
        this.useVoucher(item?.model?.number);
        break;
      case 'card':
        if (!this.orderStateService.getState()?.order) {
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardWarning);
          return;
        }
        this.loadingService.showLoader(LoaderEnum.MAIN);
        this.useCard(item?.model);
        break;
      default:
        break;
    }
  }

  useCard(card) {
    this.cardPaymentService.useCard(card?.id, card?.type).subscribe({
      next: (res) => {
        if (!res?.hasCardPayment(card?.id)) {
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError);
          return;
        }

        this.router.navigate(['payment']);
      },
      error: (e) => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
        this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.CardError);
      },
      complete: () => {
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
    });
  }

  useVoucher(number: string) {
    this.voucherService
      .assignVoucherToOrderAndSetOrder(this.orderStateService.getState()?.cinemaId, this.orderStateService?.getState().orderId, number)
      .subscribe({
        next: (res) => {
          if (!res.hasVoucherNumber(number)) {
            this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError);
            return;
          }

          this.router.navigate(['payment']);
        },
        error: (e) => {
          this.loadingService.hideLoader(LoaderEnum.MAIN);
          this.loyaltyService.showError(UserLoyaltyPopupTypeEnum.VoucherError);
        },
        complete: () => {
          this.loadingService.hideLoader(LoaderEnum.MAIN);
        },
      });
  }

  findLoyaltyCard() {
    return this.accountItems?.cards?.find((f) => this.cardPaymentService.isLoyalty(f.type));
  }

  back(): void {
    this.navigationService.back();
  }
}
