<ng-container *ngIf="!isLogged">
  <h4 *ngIf="!modal" class="mt-5">
    <div class="d-flex flex-column">
      <span>{{ "login.info-line-1" | translate }}</span>
      <div class="d-flex align-items-center" style="gap: 0.5rem">
        <button class=" btn btn-link px-0 m-0" (click)="showLoginPanel = !showLoginPanel">
          <h4 class="m-0 color-orange">
            {{ "login.signin" | translate }}
          </h4>
        </button>
        <h4 class="m-0">{{'shared.or' | translate}}</h4>
        <button class="btn btn-link px-0 m-0" (click)="redirectTo('register')">
          <h4 class="m-0 color-orange">
            {{ "login.register" | translate }}
          </h4>
        </button>
      </div>
    </div>
  </h4>

  <div *ngIf="modal || showLoginPanel" class="px-3">
    <form [formGroup]="loginForm" (submit)="onSubmit()" id="login-modal-form"
          (reset)="errorCode = null; formSubmitAttempt = false" class="mt-4">
      <div class="error text-right" *ngIf="errorCode !== null">
        <p [innerHTML]="'errors.' + errorCode | translate"></p>
      </div>

      <div class="form-group required w-100">
        <div class="d-flex align-items-center">
          <label for="email" class="form-control-description">{{ "login.form.email" | translate }}</label>
          <div class="col-8 pr-0 ml-auto">
            <input type="text" class="form-control" id="email" formControlName="email" letterOnly
                   excludepattern="[^A-Za-z]*" />
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="loginForm.get('email').invalid && formSubmitAttempt === true">
          <p *ngIf="loginForm.get('email')?.errors?.required">
            {{ "errors.10005" | translate }}
          </p>
          <p *ngIf="loginForm.get('email')?.errors?.invalid">
            {{ "errors.10102" | translate }}
          </p>
        </div>
      </div>

      <div class="form-group required w-100 mb-2">
        <div class="d-flex align-items-center">
          <label for="password" class="form-control-description">{{ "login.form.password" | translate }}</label>
          <div class="col-8 pr-0 ml-auto">
            <app-password-input id="password" formControlName="password"></app-password-input>
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="loginForm.get('password').invalid && formSubmitAttempt === true">
          <p *ngIf="loginForm.get('password')?.errors?.required">
            {{ "errors.10009" | translate }}
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-end align-items-center color-blue">
        <button class="btn btn-link" (click)="signInClick($event)">
          <span>{{ "login.signin" | translate }}</span>
        </button>
        | <button class="btn btn-link" (click)="forgotPasswordClick($event)">
          <span>{{ "login.forgotPassword" | translate }}</span>
        </button>
      </div>
    </form>
  </div>

</ng-container>

<app-loading *ngIf="loadingService.isLoading(loaderEnum.LOGIN)" [id]="loaderEnum.LOGIN"></app-loading>

<app-popup [isVisible]="showResetPasswordSuccessPopup">
  <div class="row pb-4">
    <div class="col-12">
      <h2>{{'personal.resetPassword.finalState.positive.title' | translate }}</h2>
      <div class="question">
        {{'personal.resetPassword.finalState.positive.content' | translate }}
      </div>
    </div>
  </div>
  <div class="row" footer>
    <div class="col-12 text-center">
      <button class="btn btn-default btn-navigation px-3 px-md-4 mb-sm-2"
              (click)="showResetPasswordSuccessPopup = !showResetPasswordSuccessPopup">
        {{ 'personal.button.ok' | translate | uppercase }}
      </button>
    </div>
  </div>
</app-popup>