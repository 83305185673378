import {Injectable} from '@angular/core';
import { ErrorHandler } from './error-handler';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private handlerCollection: Array<ErrorHandler> = new Array<ErrorHandler>();
  public constructor(
  ) {
  }

  public registerErrorHandler(handler: ErrorHandler) {
    this.handlerCollection.push(handler);
  }

  public handle(error: any): boolean {
    for (const handler of this.handlerCollection) {
      const handleResult = handler.handleError(error);
      if (handleResult === true) {
        return true;
      }
    }

    return false;
  }
}
