import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import uniq from 'lodash-es/uniq';
import { storageKey } from 'libs/core/src/app.const';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { MovieCopyModel } from 'libs/core/src/lib/model/movie-copy.model';
import { ScreeningAvailabilityStrategyContext } from 'libs/core/src/lib/model/strategy/screening-availability/screening-availability.strategy-context';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { MovieScheduleViewModel, ScreeningScheduleViewModel } from 'libs/shared/src/lib/page/screening/model/movie-schedule.view-model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';

@Component({
  template: '',
})
export class ScreeningListItemComponent implements OnInit {
  @Input() movieCopy: MovieCopyModel;
  @Input() cinema: CinemaViewModel;
  @Input() public movieSchedule: MovieScheduleViewModel = null;
  @Input() public showOnlyGeneralAdmission = false;

  public posterUrl: string = null;
  public pictureUrl: string = null;
  public defaultPosterUrl: string = null;
  public defaultPictureUrl: string = null;

  public showMore = false;
  public subtitlesCollection = new Array<string>();
  public languageCollection = new Array<string>();
  private screeningAvailabilityStrategyContext: ScreeningAvailabilityStrategyContext = null;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected router: Router,
    protected orderStateService: OrderStateService,
    protected stepsService: StepsService,
    protected dateTimeService: DateTimeService
  ) {
    this.screeningAvailabilityStrategyContext = new ScreeningAvailabilityStrategyContext(ScreeningAvailabilityStrategyContext.SALE_STRATEGY);
    this.defaultPosterUrl = make_url(environment, environment.constants.moviePosterPlaceholder);
    this.defaultPictureUrl = make_url(environment, environment.constants.moviePicturePlaceholder);
  }

  ngOnInit() {
    this.posterUrl = this.movieSchedule.poster;
    this.pictureUrl = this.movieSchedule.picture;

    this.subtitlesCollection = this.getUniqueSubtitlesCollection();
    this.languageCollection = this.getUniqueLanguageCollection();
  }

  onSelect(screening: ScreeningScheduleViewModel) {
    this.orderStateService.removeOrderWithRequest();
    this.setFlowType(screening);

    if (screening.isEvent) {
      this.orderStateService.setItem(storageKey.isEvent, 'true');
      this.orderStateService.setItem(storageKey.lastEventId, screening.id);
    } else {
      this.orderStateService.removeItem(storageKey.isEvent);
      this.orderStateService.removeItem(storageKey.lastEventId);
    }

    this.redirectOnNextPage(screening);
  }

  private setFlowType(screening: ScreeningScheduleViewModel) {
    if (screening.generalAdmission) {
      this.stepsService.FlowType = FlowType.Tickets;
    } else {
      this.stepsService.FlowType = FlowType.Standard;
    }
  }

  private redirectOnNextPage(screening: ScreeningScheduleViewModel) {
    switch (this.stepsService.FlowType) {
      case FlowType.Standard: {
        this.router.navigate(['screen'], {
          queryParams: {
            screeningId: screening.id,
            cinemaId: this.cinema.id,
          },
        });
        break;
      }
      case FlowType.Tickets: {
        this.router.navigate(['tickets'], {
          queryParams: {
            screeningId: screening.id,
            cinemaId: this.cinema.id,
          },
        });
      }
    }
  }

  private getUniqueSubtitlesCollection(): Array<string> {
    const subtitlesCollection = new Array<string>();
    this.movieSchedule.movieCopyCollection.forEach((x) => {
      if (x.subtitles && x.subtitles.length > 0) {
        subtitlesCollection.push(x.subtitles.trim().replace(' ', '').replace(/\s/, '').toLocaleLowerCase());
      }

      if (x.subtitles2 && x.subtitles2.length > 0) {
        subtitlesCollection.push(x.subtitles2.trim().replace(' ', '').replace(/\s/, '').toLocaleLowerCase());
      }
    });

    return uniq<string>(subtitlesCollection);
  }

  private getUniqueLanguageCollection(): Array<string> {
    const languageCollection: Array<string> = new Array<string>();
    this.movieSchedule.movieCopyCollection.forEach((x) => {
      if (x.movieLanguage && x.movieLanguage.length > 0) {
        languageCollection.push(x.movieLanguage.trim().replace(' ', '').replace(/\s/, '').toLocaleLowerCase());
      }
    });
    return uniq<string>(languageCollection);
  }

  isPastScreening(screening): boolean {
    return this.dateTimeService.isPast(screening.saleTimeTo);
  }
}
