import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import { GaTicketViewModel } from '../../../model/view-model/screening/ga/ga-ticket.view.model';
import { TicketViewModel } from '../../../model/view-model/shared/ticket/ticket.view.model';
import { GeneralAdmissionService } from './service/general-admission.service';

@Component({
  selector: 'app-screen-ticket-count-general-admission',
  templateUrl: './ticket-count-general-admission.component.html'
})
export class TicketCountGeneralAdmissionComponent implements OnInit, OnDestroy {
  @Output()
  public onReadyEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public ticketSelectedEventEmitter: EventEmitter<TicketViewModel> = new EventEmitter<TicketViewModel>();

  @Output()
  public ticketDroppedEventEmitter: EventEmitter<TicketViewModel> = new EventEmitter<TicketViewModel>();

  @Input()
  public set ticketList(value: Array<GaTicketViewModel>) {
    this._ticketList = value;

    value.forEach(ticket => {
      this.ticketLimitByGroup[ticket.seatGroupId] = ticket.availableAmount;

      if (this.ticketUsedByGroup[ticket.seatGroupId] === undefined) {
        this.ticketUsedByGroup[ticket.seatGroupId] = 0;
      }
    });
  }

  public get ticketList(): Array<GaTicketViewModel> {
    return this._ticketList;
  }

  @Input() limit = 0;

  @Input()
  public selectedTickets: { [key: string]: number } = {};
  
  @Input()
  public currency: string;

  private _ticketList: Array<GaTicketViewModel> = new Array<GaTicketViewModel>();
  ticketLimitByGroup: { [key: string]: number } = {};
  ticketUsedByGroup: { [key: string]: number } = {};
  private seatIdFakeState = 0;
  private myTicketsSubscription: Subscription = Subscription.EMPTY;
  sumAllTicketUsed = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTickets
  }

  constructor(
    private generalAdmissionService: GeneralAdmissionService
  ) {
  }

  public ngOnInit() {
    this.myTicketsSubscription = this.generalAdmissionService.myTicketsEventEmitter.subscribe((myTickets: Array<string>) => {
      for (const myTicketIdentifier of myTickets) {
        const ticket: GaTicketViewModel = this.ticketList.find(x => x.id === myTicketIdentifier);

        if (!ticket) {
          continue;
        }

        ticket.availableAmount++;
        this.onIncrementClick(ticket, false);
      }
    });

    this.onReadyEventEmitter.next(true);
  }

  public ngOnDestroy(): void {
    if (this.myTicketsSubscription !== Subscription.EMPTY) {
      this.myTicketsSubscription.unsubscribe();
      this.myTicketsSubscription = Subscription.EMPTY;
    }
  }

  public onDecrementClick(ticket: GaTicketViewModel) {
    const ticketId: string = ticket.id;
    const baseTicket: TicketViewModel = Object.assign(new TicketViewModel(), ticket);

    if (this.selectedTickets[ticketId] && this.selectedTickets[ticketId] > 0) {
      this.selectedTickets[ticketId]--;
      this.ticketUsedByGroup[ticket.seatGroupId]--;
      this.ticketDroppedEventEmitter.next(baseTicket);
    } else {
      this.selectedTickets[ticketId] = 0;
    }

    this.sumAllTicketUsed = this.countAllSelectedTickets();
  }

  public onIncrementClick(ticket: GaTicketViewModel, checkAvailability: boolean = true) {
    const ticketItem = this.cloneTicket(ticket);
    const ticketId: string = ticket.id;

    if (!this.selectedTickets[ticketId]) {
      this.selectedTickets[ticketId] = 0;
    }

    this.selectedTickets[ticketId] = this.selectedTickets[ticketId] + 1;

    const currentlyUsedTicketAmountInSeatGroup: number = this.ticketUsedByGroup[ticket.seatGroupId];
    const seatGroupLimitUsage: number = this.ticketLimitByGroup[ticket.seatGroupId];

    const t = this.ticketUsedByGroup[ticket.seatGroupId] - 1;

    if (checkAvailability === true && currentlyUsedTicketAmountInSeatGroup + 1 > seatGroupLimitUsage) {
      this.selectedTickets[ticketId]--;
      return;
    }

    const countAllSelectedTickets: number = this.countAllSelectedTickets();
    this.sumAllTicketUsed = countAllSelectedTickets;
    const baseTicket: TicketViewModel = Object.assign(new TicketViewModel(), ticketItem);

    if (countAllSelectedTickets > this.limit && checkAvailability === true) {
      this.selectedTickets[ticketId]--;
    } else {

      if (!this.ticketUsedByGroup[ticket.seatGroupId]) {
        this.ticketUsedByGroup[ticket.seatGroupId] = 0;
      }

      this.ticketUsedByGroup[ticket.seatGroupId]++;
      this.seatIdFakeState = this.seatIdFakeState + 1;
      baseTicket.seatId = this.seatIdFakeState.toString();

      this.ticketSelectedEventEmitter.next(baseTicket);
    }
  }

  private countAllSelectedTickets(): number {
    let sum = 0;
    for (const key of Object.keys(this.selectedTickets)) {
      sum += this.selectedTickets[key];
    }

    return sum;
  }

  public get totalQuantity(): number {
    return this.countAllSelectedTickets();
  } 

  public get totalValue(): number {
    let sum = 0;
    for (const key of Object.keys(this.selectedTickets)) {
      if (this._ticketList.find(o => o.id === key)) {
        sum += this.selectedTickets[key] * this._ticketList.find(o => o.id === key).price;
      }
    }

    return sum;
  }

  private cloneTicket(ticket: GaTicketViewModel): GaTicketViewModel {
    return Object.assign(new GaTicketViewModel(), ticket);
  }
}
