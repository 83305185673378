import { Inject, Input, OnDestroy, Renderer2, Directive } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ProviderParametersInterface } from './interface/provider-parameters.interface';
import { TagProviderComponentInterface } from './tag-provider.component.interface';

@Directive()
export class AbstractTagProviderComponent implements TagProviderComponentInterface, OnDestroy {
  @Input() parameters: ProviderParametersInterface;

  protected dynamicElements: Array<HTMLElement> = new Array<HTMLElement>();

  public constructor(protected renderer: Renderer2, @Inject(DOCUMENT) protected _document) {}

  public ngOnDestroy(): void {
    this.destroyDynamicElements();
  }

  protected addElement(element: HTMLElement, rootElement: any = null): void {
    this.dynamicElements.push(element);
    const el = rootElement || this._document.body;
    this.renderer.appendChild(el, element);
  }

  protected prependElement(element: HTMLElement, rootElement: any = null, refChild: HTMLElement = null): void {
    this.dynamicElements.push(element);
    const paternElement = rootElement || this._document.body;
    const refElement = refChild || paternElement.firstElementChild;
    this.renderer.insertBefore(paternElement, element, refElement);
  }

  protected destroyDynamicElements(): void {
    for (const element of this.dynamicElements) {
      element.remove();
    }
  }

  protected createScriptExternalElement(src: string, attributes: any = {}): HTMLElement {
    const element = this.renderer.createElement('script');
    element.src = src;
    for (const key of Object.keys(attributes)) {
      element[key] = attributes[key];
    }

    return element;
  }

  protected createScriptElement(source: string | HTMLElement, noScript: boolean = false): HTMLElement {
    const element = this.renderer.createElement(noScript ? 'noscript' : 'script');
    if (typeof source === 'string') {
      const textNode = this._document.createTextNode(source);
      element.appendChild(textNode);
    } else {
      element.appendChild(source);
    }

    return element;
  }

  protected createComment(value: string) {
    return this.renderer.createComment(value);
  }
}
