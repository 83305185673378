import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { storageKey } from '../../app.const';
import { LanguageDataProvider } from '../data-provider/language.data-provider';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public constructor(
    @Inject(ENVIRONMENT_TOKEN) environment: any,
    protected languageDataProvider: LanguageDataProvider,
    protected bsLocaleService: BsLocaleService,
    protected translateService: TranslateService,
    protected stateService: StateService
  ) {
    this.translateService.addLangs(environment.constants.language.available);
    this.initLang(this.searchLanguageInUrl(environment.constants.language.available), environment.constants.language.default);
  }

  public isTranslationExists(translationKey: string): boolean {
    return this.instant(translationKey) !== translationKey;
  }

  async useLanguage(locale: string) {
    // await the http request for the translation file
    await this.translateService.use(locale).toPromise();
  }

  public instant(key: string | Array<string>, interpolateParams?: Object): string | any {
    return this.translateService.instant(key, interpolateParams);
  }

  public use(lang: string): void {
    window.location.href = this.makeLocationHref(lang);
  }

  public getLangs() {
    return this.translateService.getLangs();
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }
  get getBrowserLang(): string {
    return this.translateService.getBrowserLang();
  }
  get getBrowserCultureLang(): string {
    return this.translateService.getBrowserCultureLang();
  }

  private makeLocationHref(lang) {
    const parts = window.location.pathname.split('/');
    parts[1] = lang;
    return parts.join('/') + window.location.search;
  }

  private searchLanguage(langs: string[]): string | null {
    return this.searchLanguageInUrl(langs) ?? this.searchLanguageInCookies(langs);
  }

  private searchLanguageInUrl(langs: string[]): string | null {
    return langs.find((lang) => window.location.pathname.includes(`/${lang}/`)) || null;
  }

  private searchLanguageInCookies(langs: string[]): string | null {
    return langs.find((lang) => this.stateService.getItem(storageKey.lang)) || null;
  }

  private initLang(lang: string, defaultLang: string): void {
    this.translateService.setDefaultLang(defaultLang);
    Settings.defaultLocale = defaultLang;
    lang = lang || defaultLang;
    this.useLanguage(lang);
    this.stateService.setItem(storageKey.lang, lang);
    this.bsLocaleService.use(lang);
  }
}
