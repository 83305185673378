import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { OrderStateModel } from 'libs/core/src/lib/model/state/order.state.model';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { NotSelectedPaymentProviderEvent } from '../../event/not-selected-payment-provider.event';
import { CreatePaymentRedirectUrlDto } from '../../../service/dto/create-payment-redirect-url.dto';
import { PaymentProviderPayMethodViewModel } from 'libs/core/src/lib/model/view-model/payment-provider-pay-method.view.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';

@Component({
  selector: 'app-payment-provider-conotoxia-component',
  templateUrl: './conotoxia-payment-provider.component.html'
})
export class ConotoxiaPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  public selectedPayMethodTypeCustom: string | null = null;
  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'cinkciarz';
  private orderState: OrderStateModel = null;
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private selectedPaymentTypeService: PaymentProviderStateService,
    private orderStateService: OrderStateService,
    private route: ActivatedRoute,
    private paymentHelperService: PaymentHelperService,
  ) {
    this.selectedPaymentTypeService.state$.subscribe((state) => {
      if (state) {
        this.selectedPayMethodTypeCustom = state.type;
      } else {
        this.selectedPayMethodTypeCustom = null;
      }
    });
  }

  ngOnInit() {
    this.orderState = this.orderStateService.getState();
    this.events.next(new WorkPaymentProviderEvent());
  }

  ngOnDestroy(): void {
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_CTX) {
      window.location.href = paymentModel.plainPayload;
    }
  }

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>(subscriber => {
      if (this.selectedPayMethodTypeCustom === null) {
        event.abort = true;
        this.events.emit(new NotSelectedPaymentProviderEvent());
        subscriber.next(event);
        subscriber.complete();
      }
      
      if (this.selectedPayMethodTypeCustom === PaymentMethodEnum.TYPE_CTX) {
        const request: CreatePaymentRedirectUrlDto = new CreatePaymentRedirectUrlDto();
        request.paymentChannel = this.environment.constants.paymentChannel;
        request.state = this.orderState;
        request.route = this.route;
        event.continueUrl = decodeURIComponent(this.paymentHelperService.createTarget(request));
        subscriber.next(event);
        subscriber.complete();
      }
    });
  }

  get conotoxiaPaymentMethod() {
    const conotoxiaModel = new PaymentProviderPayMethodViewModel();
    conotoxiaModel.id = 'cinkciarz';
    conotoxiaModel.imageUrl = this.getDefaultPaymentImageUrl();
    return conotoxiaModel;
  }

  getDefaultPaymentImageUrl() {
    return `/assets/images/cinkciarz_pay.png`;
  }
}
