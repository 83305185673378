import { Inject, Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import groupBy from 'lodash-es/groupBy';
import { DateTime } from 'luxon';
import { Guid } from 'libs/core/src/lib/helper/guid';
import { KeyValue } from 'libs/core/src/lib/helper/key-value';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/lib/injection.tokens';
import { IScreeningDetails } from 'libs/core/src/lib/interfaces';
import { Adapter } from 'libs/core/src/lib/model/adapter';
import { CinemaApiModel } from 'libs/core/src/lib/model/api-model/cinema/cinema.api.model';
import { MovieApiModel } from 'libs/core/src/lib/model/api-model/movie/movie.api.model';
import { TrailerApiModel } from 'libs/core/src/lib/model/api-model/movie/trailer.api.model';
import { RegionApiModel } from 'libs/core/src/lib/model/api-model/region/region.api.model';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { EventViewModel } from 'libs/core/src/lib/model/view-model/event/event.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { MovieViewModel } from 'libs/core/src/lib/model/view-model/movie/movie.view.model';
import { TrailerViewModel } from 'libs/core/src/lib/model/view-model/movie/trailer.view.model';
import { RegionViewModel } from 'libs/core/src/lib/model/view-model/region/region.view.model';
import { ScreenheadViewModel } from 'libs/core/src/lib/model/view-model/screen/screen-head.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { ScreeningViewModel } from 'libs/core/src/lib/model/view-model/screening/screening.view.model';
import { Category } from './category';
import { Like } from './like';
import { Media, MediaDetailsSize } from './media';
import { Membership } from './membership';
import { Package } from './package';
import { Post } from './post';
import { Product } from './product';
import { ProductList } from './product-list';

@Injectable({
  providedIn: 'root',
})
export class CinemaAdapter implements Adapter<CinemaViewModel> {
  adapt(item: any): CinemaViewModel {
    if (!item.custom_fields) {
      return null;
    }

    const cinemaApiModel = Object.assign(new CinemaApiModel(), item.custom_fields.raw_data);
    const cinemaViewModel = Object.assign(new CinemaViewModel(cinemaApiModel), {
      id: item.custom_fields.cinema_id,
      active: item.custom_fields.active,
      name: item.custom_fields.cinema_name,
      description: item.custom_fields.cinema_description,
      regionId: item.custom_fields.region_id,
      webUrl: item.custom_fields.web_url,
      mobileUrl: item.custom_fields.mobile_url,
    });

    return cinemaViewModel;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RegionAdapter implements Adapter<RegionViewModel> {
  adapt(item: any): RegionViewModel {
    if (!item.custom_fields) {
      return null;
    }

    const regionApiModel = Object.assign(new RegionApiModel(), {
      id: item.custom_fields.region_id ?? item.custom_fields.raw_data.id,
      region: item.custom_fields.title ?? item.custom_fields.raw_data.region,
      code: item.custom_fields.raw_data.code,
      cinemas: (item.custom_fields.active_cinemas ?? (item.custom_fields.raw_data.cinemas as any[]))
        .map((cinema: CinemaApiModel) => new CinemaViewModel(cinema))
        .filter((x) => Guid.isValid(x.id)),
    });

    const regionViewModel = new RegionViewModel(regionApiModel);
    regionViewModel.defaultCinemaId = Guid.isValid(item.custom_fields.default_cinema_id) ? item.custom_fields.default_cinema_id : null;

    return regionViewModel;
  }
}

@Injectable({
  providedIn: 'root',
})
export class EventAdapter implements Adapter<EventViewModel> {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  adapt(item: any): EventViewModel {
    const event = new EventViewModel(item.custom_fields.event_raw_data);
    if (item.custom_fields.background_image) {
      event.pictures.push(item.custom_fields.background_image);
    }

    event.firstPosterOrDefault = event.posters[0] || make_url(this.environment, this.environment.constants.moviePosterPlaceholder);

    const result = Object.assign(event, {
      name: item.custom_fields.title,
      description: item.custom_fields.description,
      likes: item.custom_fields.event_likes,
      likeByUser: item.custom_fields.is_event_liked_by_user,
      screenId: item.custom_fields.event_raw_data ? item.custom_fields.event_raw_data.screenId : '',
      link: item.link,
      priority: item.custom_fields.event_priority ? parseInt(item.custom_fields.event_priority) : Number.MAX_SAFE_INTEGER,
    });

    return result;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CategoryAdapter implements Adapter<Category> {
  adapt(item: any): Category {
    return new Category(item.id, item.code, item.name, item.link);
  }
}

@Injectable({
  providedIn: 'root',
})
export class LikeAdapter implements Adapter<Like> {
  adapt(item: any): Like {
    return new Like(item.likes, item.action === 'inserted');
  }
}

@Injectable({
  providedIn: 'root',
})
export class MediaAdapter implements Adapter<Media> {
  adapt(item: any): Media {
    return new Media(
      item.id,
      item.slug,
      item.link,
      item.alt_text,
      item.mime_type,
      item.source_url,

      item.media_details.width,
      item.media_details.height,
      item.media_details.file,

      new MediaDetailsSize(
        item.media_details.sizes.medium.file,
        item.media_details.sizes.medium.width,
        item.media_details.sizes.medium.height,
        item.media_details.sizes.medium.mime_type,
        item.media_details.sizes.medium.source_url
      ),
      new MediaDetailsSize(
        item.media_details.sizes.thumbnail.file,
        item.media_details.sizes.thumbnail.width,
        item.media_details.sizes.thumbnail.height,
        item.media_details.sizes.thumbnail.mime_type,
        item.media_details.sizes.thumbnail.source_url
      ),
      new MediaDetailsSize(
        item.media_details.sizes.full.file,
        item.media_details.sizes.full.width,
        item.media_details.sizes.full.height,
        item.media_details.sizes.full.mime_type,
        item.media_details.sizes.full.source_url
      )
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class MembershipAdapter implements Adapter<Membership> {
  adapt(item: any): Membership {
    return new Membership(item.card_type_id, item.voucher_id, item.voucher_item_id, item.can_be_reserved, item.link_to_description);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MovieAdapter implements Adapter<MovieViewModel> {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  adapt(item: any): MovieViewModel {
    const movie = new MovieViewModel(Object.assign(new MovieApiModel(), item.custom_fields.movie_raw_data));
    if (movie.originalTitle === '') {
      movie.originalTitle = movie.title;
    }
    movie.slug = item.slug;
    movie.firstPosterOrDefault = movie.posters[0] || make_url(this.environment, this.environment.constants.moviePosterPlaceholder);
    movie.postersOrDefaults = movie.posters || [make_url(this.environment, this.environment.constants.moviePosterPlaceholder)];
    movie.trailerThumbnail = item.custom_fields.movie_trailer_thumbnail;

    const trailers = groupBy(item.custom_fields.movie_trailers as TrailerApiModel[], 'lang');
    return Object.assign(movie, {
      title: item.custom_fields.title,
      description: item.custom_fields.description,
      likes: item.custom_fields.movie_likes,
      likeByUser: item.custom_fields.is_movie_liked_by_user,
      imdbRating: item.custom_fields.imdb_rating,
      slug: item.slug,
      link: item.link,
      posters: [...movie.posters, item.custom_fields.poster],
      trailersGroupByLang: Object.entries(trailers)
        .filter((x) => x[1])
        .map((x) => new KeyValue<TrailerViewModel[]>(x[0], x[1] as TrailerViewModel[])),
      priority: item.custom_fields.movie_priority ? parseInt(item.custom_fields.movie_priority) : Number.MAX_SAFE_INTEGER,
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class PostAdapter implements Adapter<Post> {
  adapt(item: any): Post {
    return new Post(
      item.id,
      item.slug,
      item.type,
      item.title.rendered,
      item.content ? item.content.rendered : null,
      item.excerpt ? item.excerpt.rendered : null,
      item.link,
      item.featured_media,
      item.custom_fields ?? {}
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PackageAdapter implements Adapter<Package> {
  adapt(item: any): Package {
    return new Package(item.ticket_type, item.minimal_ticket_quantity, item.maximum_ticket_quantity, item.price, item.title, item.description);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductListAdapter implements Adapter<ProductList> {
  adapt(item: any): ProductList {
    const raw_data: Product[] = item.custom_fields.items
      .filter((f) => this.isJson(f.custom_fields.raw_data))
      .map((x) => plainToInstance(Product, JSON.parse(x.custom_fields.raw_data)));

    return new ProductList(item.id, item.title.rendered, raw_data);
  }

  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

export class ScreeningDetails implements IScreeningDetails {
  constructor(
    public movie: MovieViewModel,
    public event: EventViewModel,
    public moviePrint: MoviePrintViewModel,
    public region: RegionViewModel,
    public cinema: CinemaViewModel,
    public screening: ScreeningViewModel,
    public screen: ScreenheadViewModel | ScreenViewModel
  ) {
    this.adapt();
  }

  regionName: string = '';
  cinemaName: string = '';
  screeningDate: DateTime = null;
  screenNumber: number = null;
  screenName: string = '';
  screenFeature: string = '';
  duration: number = null;
  movieLanguage: string = '';
  movieRelease: string = '';
  posterUrl: string = '';
  title: string = '';
  ageRestriction: string = '';

  adapt() {
    if (this.movie) {
      this.title = this.movie.title;
      this.duration = this.movie.duration;
      this.posterUrl = this.movie.firstPosterOrDefault;
      this.ageRestriction = this.movie.ageRestriction;
    }
    if (this.event) {
      this.posterUrl = this.event.posters[0] || this.posterUrl;
    }
    if (this.moviePrint) {
      this.movieLanguage = this.moviePrint.language;
      this.movieRelease = this.moviePrint.release;
    }
    if (this.region) {
      this.regionName = this.region.name;
    }
    if (this.cinema) {
      this.cinemaName = this.cinema.name;
    }
    if (this.screening) {
      this.screeningDate = this.screening.screeningTimeFrom;
    }
    if (this.screen) {
      this.screenNumber = this.screen.number;
      this.screenFeature = this.screen.feature;
      this.screenName = this.screen.name;
    }
  }
}
