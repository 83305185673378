import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ScreenDataProvider } from '../../data-provider/screen.data-provider';
import { SalesDocumentHttpService } from '../../http/sales-document.http.service';
import { ENVIRONMENT_TOKEN } from '../../injection.tokens';
import { OrderStateModel } from '../../model/state/order.state.model';
import { SalesDocumentViewModel } from '../../model/view-model/sales-document/sales-document.view.model';
import { OrderStateService } from '../../state/order.state.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
  };

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderStateService: OrderStateService,
    private screenDataProvider: ScreenDataProvider,
    private salesDocumentHttpService: SalesDocumentHttpService
  ) {}

  private isConfigured(): boolean {
    return document.getElementById('gtm_script') ? true : false;
  }

  public getDataLayer(): any[] {
    const window = this.browserGlobals.windowRef();
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }

  private resetDataLayer() {
    const dataLayer = this.getDataLayer();
    dataLayer.push(function () {
      this.reset();
    });
  }

  private pushOnDataLayer(tag: any): void {
    if (tag) {
      const dataLayer = this.getDataLayer();
      dataLayer.push(tag);
    }
  }

  private fillTagWithOrderInformation(tag: any) {
    const order: OrderStateModel = this.orderStateService.getState();

    if (order && order.order && order.order.screeningItems.length > 0) {
      tag.transaction_id = order.order.id;
      tag.value = order.order.totalValue;
      tag.coupon = order.order.voucher;
      tag.tax = order.order.totalTaxValue;
      tag.affiliation = order.cinema.name;
      tag.currency = this.environment.constants.currency;
      tag.email = order.order.userEmail;
      tag.phone = order.order.userPhone;
    }
  }

  private fillTagWithOrderItems(tag: any) {
    const order: OrderStateModel = this.orderStateService.getState();

    if (order && order.order && order.order.screeningItems.length > 0) {
      return this.screenDataProvider.findByScreeningId(order.cinema.id, order.order.screeningItems[0].screeningId, null).pipe(
        tap((s) => {
          tag.items = order.order.screeningItems.map((m) => ({
            item_id: s.id,
            item_name: s.movieName,
            item_category: s.movieGenres.map((m) => m.name).toString(),
            item_category2: m.name,
            item_variant: s.movieDate.toISO(),
            price: m.ticketPrice,
            quantity: m.quantity,
            affiliation: order.cinema.name,
            currency: this.environment.constants.currency,
          }));
        })
      );
    }

    return of(null);
  }

  public addToCart() {
    if (this.isConfigured()) {
      let tag: any = { event: 'add_to_cart' };

      this.fillTagWithOrderInformation(tag);
      this.fillTagWithOrderItems(tag).subscribe((s) => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  public beginCheckout() {
    if (this.isConfigured()) {
      let tag: any = { event: 'begin_checkout' };

      this.fillTagWithOrderInformation(tag);
      this.fillTagWithOrderItems(tag).subscribe((s) => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  public purchase() {
    if (this.isConfigured()) {
      let tag: any = { event: 'purchase' };

      this.fillTagWithOrderInformation(tag);
      this.fillTagWithOrderItems(tag).subscribe((s) => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  public addPaymentInfo(payment: string) {
    if (this.isConfigured()) {
      let tag: any = {
        event: 'add_payment_info',
        payment_type: payment,
      };

      this.fillTagWithOrderItems(tag).subscribe((s) => {
        this.pushOnDataLayer(tag);
      });
    }
  }

  private fillTagWithSalesDocumentItems(
    tag: any,
    cinemaId: string,
    salesDocumentViewModel: SalesDocumentViewModel,
    isPartialRefund: boolean,
    ticketNumbers: string[]
  ) {
    if (cinemaId && salesDocumentViewModel.id && salesDocumentViewModel.bookings?.length) {
      if (isPartialRefund) {
        return this.screenDataProvider.findByScreeningId(cinemaId, salesDocumentViewModel.bookings[0].screeningId, null).pipe(
          tap((s) => {
            salesDocumentViewModel.reservationItems = salesDocumentViewModel.reservationItems.filter((f) => ticketNumbers.includes(f.ticketNumber));
            tag.ecommerce.transaction_id = salesDocumentViewModel.bookings[0]?.bookingId;
            tag.ecommerce.currency = this.environment.constants.currency;
            tag.ecommerce.value = salesDocumentViewModel.reservationItems.reduce((a, b) => a + b.price, 0);
            tag.ecommerce.items = salesDocumentViewModel.reservationItems.map((m) => ({
              item_id: s.id,
              item_name: s.movieName,
              currency: this.environment.constants.currency,
              item_brand: salesDocumentViewModel.bookings[0]?.cinemaName,
              item_category: s.movieGenres.map((m) => m.name).toString(),
              item_variant: s.movieDate.toISO(),
              price: m.price,
              quantity: 1,
            }));
          })
        );
      } else {
        tag.ecommerce.transaction_id = salesDocumentViewModel.bookings[0]?.bookingId;
        return of(null);
      }
    }

    return of(null);
  }

  public refund(cinemaId: string, salesDocumentViewModel: SalesDocumentViewModel, isPartialRefund: boolean, ticketNumbers: string[]) {
    if (this.isConfigured()) {
      this.resetDataLayer();
      let tag: any = { event: 'refund', ecommerce: {} };

      this.fillTagWithSalesDocumentItems(tag, cinemaId, salesDocumentViewModel, isPartialRefund, ticketNumbers).subscribe((_) => {
        this.pushOnDataLayer(tag);
      });
    }
  }
}
