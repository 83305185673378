import { IMoviePackage, IScreeningCard, IScreeningCardItem } from "../../interfaces";

export abstract class ModelBuilder {

  public static makeSlide(model: IMoviePackage) {
    const result = {
        movie: model.movie,
        moviePrint: model.moviePrint,
        screenings: model.screenings,
    } as  IScreeningCardItem;

    if (model.moviePrint) {
      result.id = model.moviePrint.id;
      result.filterLanguages = [model.moviePrint.language, model.moviePrint.subtitles, model.moviePrint.subtitles2].filter(x => x).map(x => x.toLowerCase());
    }

    if (model.movie) {
        result.movie.posters = model.movie.postersOrDefaults;
        result.movie.pictures = model.movie.picturesOrDefaults;

        result.slug = model.movie.slug;
        result.countryYear = model.movie.countryYear;
        result.ageRestriction = model.movie.ageRestriction;

        result.genresString = model.movie.genreNames;
        result.filterGenres = model.movie.genres.map(o => o.id);
    }

    if (model.cinema) {
        result.location = model.cinema.name;
        result.filterCinemas = [model.cinema.id];
    }

    result.screenings = model.screenings;
    return result;
  }

  public static makeScreeningCard(model:IMoviePackage): IScreeningCard {
    return {
      id: model.moviePrint.movieId,
      title: model.movie.shortTitle,
      slug: model.movie.slug,
      agerating: model.movie.ageRestriction,
      poster: model.movie.firstPosterOrDefault,
      items: [this.makeSlide(model)],
    } as IScreeningCard;
  }

  public static makePicture(width: number, height: number, placeholder: boolean = true) {
    const index = Math.floor(Math.random() * 100);
    return placeholder ? 
      `https://via.placeholder.com/${width}x${height}` :
      `https://picsum.photos/${width}/${height}?random=`+ index;
  }
}
