import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { OrderStateService } from '../state/order.state.service';
import { ScreenApiModel } from '../model/api-model/screen/screen.api.model';
import { ScreenheadApiModel } from '../model/api-model/screen/screen-head.api.model';

@Injectable({
  providedIn: 'root',
})
export class ScreenHttpService {
  public static cacheBuster$ = new Subject<void>();

  private cinemaId: string = null;

  constructor(private http: HttpClient, private orderStateService: OrderStateService) {
    orderStateService.state$.subscribe((state) => (this.cinemaId = state?.cinemaId));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: ScreenHttpService.cacheBuster$,
    maxCacheCount: 5,
  })
  list(cinemaId: string | null = null, screeningId: string | null = null): Observable<ScreenApiModel[]> {
    return this.http.get<ScreenApiModel[]>(`/cinema/${cinemaId ? cinemaId : this.cinemaId}/screen`, {
      params: {
        screeningId: screeningId,
      },
    });
  }

  listOnlyHead(cinemaId: string | null = null, screeningId: string | null = null): Observable<ScreenheadApiModel[]> {
    return this.http.get<ScreenheadApiModel[]>(`/cinema/${cinemaId ? cinemaId : this.cinemaId}/screenhead`, {
      params: {
        screeningId: screeningId,
      },
    });
  }

  @Cacheable({
    cacheBusterObserver: ScreenHttpService.cacheBuster$,
  })
  findById(cinemaId: string | null = null, screenId: string): Observable<ScreenApiModel> {
    return this.http.get<ScreenApiModel>(`/cinema/${cinemaId ? cinemaId : this.cinemaId}/screen/${screenId}`);
  }
}
