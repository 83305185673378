import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { appProjectName } from 'libs/core/src/app.const';
import { SeatsOccupancyService } from 'libs/core/src/lib/service/seats-occupancy.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { MessageModel, MessageType } from 'libs/core/src/lib/model/message.model';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { SeparatorViewModel } from 'libs/core/src/lib/model/view-model/screening/separator/separator.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';

export interface ScreenSeatsComponentInterface {
  changeSeatState(s: string);
}

@Component({
  template: '',
})
export class ScreenSeatsComponent implements OnInit, ScreenSeatsComponentInterface {
  @Input() screen: ScreenViewModel = null;
  @Input() limit = 1;
  @Input() priceSeparatorsEnabled = false;
  @Input() clickedSeatGroupTypes: { [key: string]: boolean } = {};
  @Input() shouldShowPopupOnSelectedSeat = false;
  @Input() rowSeparators: Array<SeparatorViewModel> = new Array<SeparatorViewModel>();
  @Output() seatsSelectedEvent = new EventEmitter<[string[], boolean]>();
  @Output() seatPopupShowEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() lastSelectedSeatChange: EventEmitter<SeatViewModel> = new EventEmitter<SeatViewModel>();

  public lastSelectedSeat: SeatViewModel | null = null;
  public showSelectedSeatModal = false;
  public popupGroupTypeFilter: Array<string> | null = null;
  private debouncer: Subject<[Array<string>, boolean]> = new Subject<[Array<string>, boolean]>();
  private lastSeatModel: SeatViewModel;
  private lastSkipModal: boolean;
  private _seatIds: Array<string>;
  private isEmitted = true;

  private showPopupBefore = this.appService.isProject(appProjectName.BLUE, appProjectName.LANDMARK, appProjectName.ONEIL);
  private showPopupAfter = !this.appService.isProject(appProjectName.BLUE, appProjectName.LANDMARK, appProjectName.ONEIL);

  get occupiedStatus() {
    return OccupiedStatus;
  }

  get seatIds(): Array<string> {
    return this._seatIds;
  }

  @Input()
  set seatIds(value: Array<string>) {
    if (this.isEmitted) {
      this._seatIds = value;
    }
  }

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected seatOccupancyService: SeatsOccupancyService,
    protected modalService: BsModalService,
    protected appService: AppService,
    protected translateService: TranslateService,
    protected messageService: MessageService
  ) {
    this.debouncer
      .pipe(
        tap(() => (this.isEmitted = false)),
        debounceTime(10)
      )
      .subscribe((val) => {
        this.seatsSelectedEvent.emit(val);
        this.isEmitted = true;
      });

    this.popupGroupTypeFilter = environment.screen.seatPopup?.groupTypesFilter;
  }

  ngOnInit() {
    this.changeSeatState(this._seatIds, true);
  }

  private createObservableForCateringWarning(): Subject<boolean> {
    const cateringWarning = new Subject<boolean>();

    cateringWarning.subscribe((allow) => {
      if (allow) {
        this.seatOccupancyService.isCateringSelected = false;
        this.onSeatClick(this.lastSeatModel, this.lastSkipModal);
      } else {
        this.lastSeatModel.occupied = this.lastSeatModel.occupied === OccupiedStatus.Free ? OccupiedStatus.Mine : OccupiedStatus.Free;
      }
    });

    return cateringWarning;
  }

  public onSeatClick(seat: SeatViewModel, skipModal: boolean = false, oninit = false) {
    const addAction = seat.occupied === OccupiedStatus.Free;
    const ids: string[] = [seat.id];

    if (seat.groupConnectedSeats) {
      ids.push(...seat.groupConnectedSeats.map((o) => o.id));
    }

    if (!skipModal && !oninit) {
      const checkSeatsLimit = this.seatOccupancyService.checkSeatsLimit(this.seatIds, seat, this.limit);
      const checkMaxOccupancyLimit = this.seatOccupancyService.checkMaxOccupancyLimit(this.seatIds, seat);
      if (!checkSeatsLimit || !checkMaxOccupancyLimit) {
        const messageType = this.appService.isProject(appProjectName.SCHULMAN, appProjectName.VIOLET_CROWN, appProjectName.LANDMARK, appProjectName.ONEIL)
          ? MessageType.danger
          : MessageType.info;
        this.messageService.add(new MessageModel(messageType, this.translateService.instant('errors.105', { count: this.limit })));
        return;
      }

      if (this.showPopupBefore && seat.occupied === OccupiedStatus.Free && this.canShowPopup(seat, skipModal)) {
        return;
      }
    }

    if (!skipModal && this.seatOccupancyService.isCateringSelected) {
      this.lastSeatModel = seat;
      this.lastSkipModal = skipModal;

      const modal = this.modalService.show(ConfirmModalComponent);
      modal.content.subject = this.createObservableForCateringWarning();
      return;
    }

    //this.seatIds = ids;
    this.toggleOccupiedStatus(seat, oninit);
    if (!oninit) {
      if (addAction) {
        this._seatIds.push(...ids);
      } else {
        ids.forEach((id) => {
          const index = this._seatIds.indexOf(id, 0);
          if (index > -1) {
            this._seatIds.splice(index, 1);
          }
        });
      }
    }

    this.debouncer.next([this._seatIds, skipModal]);

    if (this.popupGroupTypeFilter) {
      let isMatched = false;
      for (const groupTypeX of seat.groupTypes) {
        for (const groupTypeFilter of this.popupGroupTypeFilter) {
          if (groupTypeFilter.trim().toLocaleUpperCase() === groupTypeX.trim().toLocaleUpperCase()) {
            isMatched = true;
            break;
          }
        }
      }
      if (isMatched === false) {
        return;
      }
    }

    if (this.showPopupAfter && seat.occupied === OccupiedStatus.Mine && this.shouldShowPopupOnSelectedSeat === true) {
      this.canShowPopup(seat, skipModal);
    }
  }

  private canShowPopup(seat: SeatViewModel, skipModal: boolean = false) {
    let seatGroupType: string = seat.transformGroupTypesToString().toLocaleLowerCase().trim();
    if (seatGroupType === 'none') {
      seatGroupType = 'standard';
    }

    this.lastSelectedSeat = seat;
    if (this.clickedSeatGroupTypes[seatGroupType] === undefined) {
      this.clickedSeatGroupTypes[seatGroupType] = false;
    }
    if (this.clickedSeatGroupTypes[seatGroupType] === true) {
      return false;
    }

    if (seat.groupDescriptionCollection.length > 0 && !skipModal) {
      this.showSelectedSeatModal = !this.clickedSeatGroupTypes[seatGroupType];
      this.clickedSeatGroupTypes[seatGroupType] = this.showSelectedSeatModal;

      if (this.clickedSeatGroupTypes[seatGroupType] === true) {
        this.lastSelectedSeatChange.next(this.lastSelectedSeat);
        this.seatPopupShowEvent.next(seatGroupType);
        return true;
      }
    }

    return false;
  }

  public changeSeatState(seatid: string | string[], oninit = false) {
    if (!seatid) {
      return;
    }

    if (typeof seatid === 'string') {
      seatid = [seatid];
    }

    if (this.screen) {
      for (const seatsRow of this.screen.pseats) {
        for (const seat of seatsRow) {
          if (seatid.includes(seat.id)) {
            this.onSeatClick(seat, true, oninit);
          }
        }
      }
    }
  }

  private toggleOccupiedStatus(seat: SeatViewModel, oninit: boolean) {
    if (oninit || seat.occupied === OccupiedStatus.Free) {
      seat.occupied = OccupiedStatus.Mine;
      seat.groupConnectedSeats = seat.groupConnectedSeats
        ? seat.groupConnectedSeats.map((c) => {
            c.occupied = OccupiedStatus.Mine;
            return c;
          })
        : null;
      return;
    }

    seat.occupied = OccupiedStatus.Free;
    seat.groupConnectedSeats = seat.groupConnectedSeats
      ? seat.groupConnectedSeats.map((c) => {
          c.occupied = OccupiedStatus.Free;
          return c;
        })
      : null;
  }

  public onClickedCloseModal(): void {
    this.lastSelectedSeat = null;
    this.showSelectedSeatModal = false;
  }

  hasMine(groups: SeatViewModel[]) {
    return groups.map((o) => o.occupied).includes(OccupiedStatus.Mine);
  }
}
