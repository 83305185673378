import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { ScreeningResponseModel } from '../model/response/screening.response.model';
import { ScreeningRequestModel } from '../model/request/screening.request.model';
import { ScreeningApiModel } from '../model/api-model/screening/screening.api.model';

@Injectable({
  providedIn: 'root',
})
export class ScreeningHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable()
  list(model: ScreeningRequestModel): Observable<ScreeningResponseModel[]> {
    return this.http.get<ScreeningResponseModel[]>(`/cinema/${model.cinema}/screening`, {
      params: {
        dateTimeFrom: model.dateTimeFrom,
        dateTimeTo: model.dateTimeTo,
      },
    });
  }

  @Cacheable()
  listByApiModel(model: ScreeningRequestModel): Observable<ScreeningApiModel[]> {
    return this.http.get<ScreeningApiModel[]>(`/cinema/${model.cinema}/screening`, {
      params: {
        dateTimeFrom: model.dateTimeFrom,
        dateTimeTo: model.dateTimeTo,
      },
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
  })
  findById(cinemaId: string, id: string): Observable<ScreeningResponseModel> {
    return this.http.get<ScreeningResponseModel>(`/cinema/${cinemaId}/screening/${id}`);
  }

  listByRegion(model: ScreeningRequestModel): Observable<ScreeningApiModel[]> {
    return this.http.get<ScreeningApiModel[]>(`/region/${model.regionId}/screening`, {
      params: {
        dateTimeFrom: model.dateTimeFrom,
        dateTimeTo: model.dateTimeTo,
        movieId: model.movieId,
      },
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
  })
  findByIdViaApiModel(cinemaId: string, id: string): Observable<ScreeningApiModel> {
    return this.http.get<ScreeningApiModel>(`/cinema/${cinemaId}/screening/${id}`);
  }

  getOccupancyList<T>(cinemaId: string, id: string): Observable<T> {
    return this.http.get<T>(`/cinema/${cinemaId}/screening/${id}/occupancy`);
  }

  getTicketList<T>(cinemaId: string, id: string, seatIds: string[]): Observable<Array<T>> {
    return this.http.get<Array<T>>(`/cinema/${cinemaId}/screening/${id}/tickets?seatIds=${seatIds.join(',')}`);
  }

  getScreeningsByMovieId(cinemaId: string, movieId: string, dateTimeFrom: string, dateTimeTo: string): Observable<ScreeningResponseModel[]> {
    return this.http
      .get<ScreeningResponseModel[]>(`/cinema/${cinemaId}/movie/${movieId}/screening?dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}`)
      .pipe(map((result) => plainToInstance(ScreeningResponseModel, result as object[])));
  }

  getScreeningsByMovieIdViaApiModel(cinemaId: string, movieId: string, dateTimeFrom: string, dateTimeTo: string): Observable<ScreeningApiModel[]> {
    return this.http
      .get<ScreeningApiModel[]>(`/cinema/${cinemaId}/movie/${movieId}/screening?dateTimeFrom=${dateTimeFrom}&dateTimeTo=${dateTimeTo}`)
      .pipe(map((result) => plainToInstance(ScreeningApiModel, result as object[])));
  }

  getTicketListGeneralAdmission<T>(cinemaId: string, screeningId: string): Observable<Array<T>> {
    return this.http.get<Array<T>>(`/cinema/${cinemaId}/screening/${screeningId}/ga/tickets`);
  }

  @Cacheable()
  getRowSeparators<T>(cinemaId: string, id: string): Observable<Array<T>> {
    return this.http.get<Array<T>>(`/cinema/${cinemaId}/screening/${id}/separators`);
  }
}
