<div class="panel-head">
  <label class="radio-container">
    <input
      type="radio"
      name="pay_method"
      (click)="paymentProviderClick()"
      [checked]="selected"
      value="blik">
    <span class="label">{{properTranslation}}</span>
    <span class="checkmark" [ngClass]="{'active': selected, 'noActive': !selected}"></span>
  </label>
  <div class="image">
    <img *ngIf="hasImage" [src]="paymentMethod.imageUrl"/>
    <img *ngIf="!hasImage && paymentImgUrl" [src]="paymentImgUrl" />
  </div>
</div>
<ng-content></ng-content>
