import {DateTime} from 'luxon';
import {Expose, Transform, Type} from 'class-transformer';

export class ScreeningResponseModel {

  @Expose()
  id: string;

  @Expose()
  screenId: string;

  @Expose()
  moviePrintId: string;

  @Expose()
  movieExternalId: string;

  @Expose()
  moviePrintExternalId: string;

  @Expose()
  generalAdmission: string;

  @Expose({ name: 'screeningTimeFrom' })
  @Type(() => DateTime)
  @Transform(({value}) => value ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  timeFrom: DateTime;

  @Expose({ name: 'screeningTimeTo' })
  @Type(() => DateTime)
  @Transform(({value}) => value ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  timeTo: DateTime;

  @Expose()
  screeningDuration: number;

  @Expose()
  audience: number;

  @Expose()
  isScreenSwapping: boolean;

  @Expose()
  availabilityStatus: number;

  @Expose()
  @Type(() => DateTime)
  @Transform(({value}) => value ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  reservationTimeTo: DateTime;

  @Expose()
  @Type(() => DateTime)
  @Transform(({value}) => value ? DateTime.fromISO(value, {setZone: true}) : null, { toClassOnly: true })
  saleTimeTo: DateTime;
}
