import { AfterViewInit, Component, DoCheck, EventEmitter, HostBinding, Inject, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { MobileDeviceUtils } from 'libs/core/src/lib/utilities/mobile-device-utils';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';

@Component({
  template: '',
})
export class ScreenSeatsSeatComponent implements OnInit, DoCheck, AfterViewInit {
  @ViewChild('seatComponent', { read: ViewContainerRef })
  public seatComponent: ViewContainerRef;

  @Input() seat: SeatViewModel;
  @Input() selectedSeatIds: Array<string>;
  @Input() seatsLimit: number;
  @Output() seatClickEvent = new EventEmitter<SeatViewModel>();

  @HostBinding('class.row-legend')
  public get isPlaceholder() {
    return this.seat.isPlaceholder || this.seat.occupied === OccupiedStatus.Locked;
  }

  groupOverridenColor: string;
  isEnabled: boolean;
  content: string;
  translationContent: string;

  get occupiedStatus() {
    return OccupiedStatus;
  }

  tooltipEnabled = false;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected translateService: TranslateService) {
    this.tooltipEnabled = environment.screen.enableSeatTooltip;
  }

  ngOnInit() {
    this.setContent();
    if (MobileDeviceUtils.isMobile()) {
      this.tooltipEnabled = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.tooltipEnabled === true && this.seatComponent) {
      (this.seatComponent.element.nativeElement as HTMLElement).setAttribute('data-tooltip-nns', '1');
    }
  }

  onOccupiedChange() {
    this.seatClickEvent.emit(this.seat);
  }

  ngDoCheck() {
    this.setGroupColor();
  }

  isDisabled() {
    const occupancyDisabled = this.seat.occupied === OccupiedStatus.Occupied;
    const blockedGroup = this.seat.occupied === OccupiedStatus.Locked || this.seat.hasAssignedGroupType('blocked');

    return !(occupancyDisabled || blockedGroup);
  }

  setGroupColor() {
    if (this.seat.occupied === OccupiedStatus.Free && this.seat.groupId != null) {
      this.groupOverridenColor = this.seat.groupColor;
    } else if (this.seat.occupied === OccupiedStatus.Free && this.seat.groupTypes.length === 1 && this.seat.groupTypes[0] === 'none') {
      this.groupOverridenColor = this.environment?.constants?.standardSeatGroupColor;
    } else {
      this.groupOverridenColor = null;
    }
  }

  getSeatClass(): string[] {
    const groups = this.seat.groups?.map((m) => m.name.toLowerCase()) ?? '';
    return [this.seat.occupied, ...this.seat.groupTypes, ...groups, this.seat.wheelchairSeat ? 'wheelchair' : ''];
  }

  setContent() {
    if (this.seat.occupied === OccupiedStatus.Locked) {
      return;
    }
    if (this.seat.occupied === OccupiedStatus.Occupied) {
      this.content = this.translateService.instant('screen.seats.seatTooltipOccupied');
      return;
    }
    this.content = this.translateService.instant('screen.seats.seatTooltip', {
      rowSymbol: this.seat.rowSymbol,
      colSymbol: this.seat.symbol,
    });
  }

  hasSomeTypes(types: string[]) {
    return this.seat.groupTypes.some((m) => types.indexOf(m) >= 0) || this.seat.groups?.some((g) => types.indexOf(g.name.toLowerCase()) >= 0);
  }
}
