import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Cacheable, LocalStorageStrategy} from 'ts-cacheable';
import { GenreApiModel } from '../model/api-model/genre/genre.api.model';

@Injectable({
  providedIn: 'root'
})
export class GenreHttpService {

  constructor(
    private http: HttpClient,
  ) {
  }
  
  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy
    }
  )
  list(): Observable<GenreApiModel[]> {
    return this.http.get<GenreApiModel[]>('/genres');
  }

  @Cacheable()
  findById(id: string): Observable<GenreApiModel> {
    return this.http.get<GenreApiModel>(`/genres/${id}`);
  }
}