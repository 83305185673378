import { Inject, Injectable, Injector } from '@angular/core';
import { WebComponentsService } from 'libs/webcomponent/src/lib/service/webcomponents.service';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { storageKey } from '../../app.const';
import { CinemaDataProvider } from '../data-provider/cinema.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';
import { CinemaApiModel } from '../model/api-model/cinema/cinema.api.model';
import { OrderStateModel } from '../model/state/order.state.model';
import { CinemaViewModel } from '../model/view-model/cinema/cinema.view.model';
import { IVoucher, OrderViewModel } from '../model/view-model/order/order.view.model';
import { PaymentMethodViewModel } from '../model/view-model/order/payment-method/payment-method.view.model';
import { RegionViewModel } from '../model/view-model/region/region.view.model';
import { UserCardViewModel } from '../model/view-model/user-history/card/user-card.view.model';
import { UserVoucherViewModel } from '../model/view-model/user-history/voucher/user-voucher.view.model';
import { CountdownComponentService } from '../service/countdown.service';
import { TotalizerService } from '../service/totalizer.service';
import { StateService } from './state.service';
import { delay, filter, last, switchMap, tap } from 'rxjs/operators';
import { DateTime, Settings } from 'luxon';
import { TokenStorageService } from '../service/token-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { PaymentProviderPayMethodRequestModel } from '../model/request/payment-provider-pay-method.request.model';
import { ProviderEnum } from '../enum/provider.enum';

@Injectable({
  providedIn: 'root',
})
export class OrderStateService extends StateService {
  private webComponentsService: WebComponentsService;
  private model: OrderStateModel = new OrderStateModel();
  private state = new BehaviorSubject<OrderStateModel>(null);
  state$: Observable<OrderStateModel>;
  voucher$ = new ReplaySubject<IVoucher[]>();

  private interceptLoadData = false;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private totalizerService: TotalizerService,
    private orderDataProvider: OrderDataProvider,
    private cinemaDataProvider: CinemaDataProvider,
    private injector: Injector,
    private countdownComponentService: CountdownComponentService,
    protected tokenStorageService: TokenStorageService,
    protected cookieService: CookieService
  ) {
    super(tokenStorageService, cookieService);
    this.state$ = this.state.asObservable().pipe(filter((v) => Boolean(v)));

    if (this.environment['cms']) {
      this.initData();
    }
  }

  public initData(orderId?: string, cinemaId?: string) {
    let storedCinemaId = this.getItem(storageKey.chosenCinema);
    let storedOrderId = this.getItem(storageKey.orderId);

    if (!this.environment['cms']) {
      this.loadData(orderId || storedOrderId, cinemaId || storedCinemaId || this.environment.constants.defaultCinemaId);
    } else {
      this.webComponentsService = <WebComponentsService>this.injector.get(WebComponentsService);
      this.webComponentsService.region$.subscribe((region: RegionViewModel) => {
        if (!region) {
          return;
        }

        if (storedCinemaId && region.cinemas.some((x) => x.id === storedCinemaId)) {
          storedCinemaId = storedCinemaId;
        } else if (region.defaultCinemaId && region.cinemas.some((x) => x.id === region.defaultCinemaId)) {
          storedCinemaId = region.defaultCinemaId;
        } else if (this.environment.constants.defaultCinemaId && region.cinemas.some((x) => x.id === this.environment.constants.defaultCinemaId)) {
          storedCinemaId = this.environment.constants.defaultCinemaId;
        } else {
          storedCinemaId = region.cinemas[0].id;
        }

        this.loadData(storedOrderId, storedCinemaId);
      });
    }
  }

  private loadData(orderId: string, cinemaId: string) {
    if (!cinemaId) {
      this.removeState();
      return;
    }

    const storedOrderId = this.getItem(storageKey.orderId);
    forkJoin({
      cinema: this.cinemaDataProvider.findByIdViaApiModel(cinemaId),
      order: storedOrderId && orderId ? this.orderDataProvider.findById(cinemaId, orderId) : of(null),
    })
      .pipe(delay(0))
      .subscribe({
        next: (res) => {
          if (!this.interceptLoadData) {
            this.setState(new OrderStateModel(res.cinema, res.order));
          }
        },
        error: (e) => this.removeState(),
      });
  }

  public refreshDataOnDemand() {
    let storedCinemaId = this.getItem(storageKey.chosenCinema);
    let storedOrderId = this.getItem(storageKey.orderId);
    this.loadData(storedOrderId, storedCinemaId);
  }

  getState(): OrderStateModel {
    return this.model;
  }

  getPaymentMethod(): PaymentMethodViewModel {
    const lsSelectedPaymentMethod = this.getItem(storageKey.selectedPaymentMethod);
    return lsSelectedPaymentMethod ? JSON.parse(lsSelectedPaymentMethod) : null;
  }

  setState(state: OrderStateModel) {
    if (state.cinema !== null) {
      this.setItem(storageKey.chosenCinema, state.cinemaId);
      Settings.defaultZoneName = state.cinema.timezone;
    } else {
      this.removeItem(storageKey.chosenCinema);
    }

    if (state.order !== null) {
      this.setItem(storageKey.orderId, state.orderId);
    } else {
      this.removeOrderFromSessionStorage();
    }

    this.model = state;
    this.state.next(state);
  }

  removeState() {
    this.setState(new OrderStateModel());
  }

  setCinema(cinema: CinemaApiModel) {
    this.setState(Object.assign(this.model ?? new OrderStateModel(), { cinema: new CinemaViewModel(cinema) }));
  }

  setCinemaById(cinemaId: string) {
    this.cinemaDataProvider.findByIdViaApiModel(cinemaId).subscribe((cinema) => this.setCinema(cinema));
  }

  setOrderWithCinemaId(order: OrderViewModel, cinemaId: string) {
    return this.cinemaDataProvider
      .findByIdViaApiModel(cinemaId)
      .pipe(tap((cinema) => this.setState(Object.assign(this.model ?? new OrderStateModel(), { order: order, cinema: cinema }))));
  }

  setOrder(order: OrderViewModel) {
    this.setState(Object.assign(this.model ?? new OrderStateModel(), { order: order }));
  }

  setVoucher(number: string, name?: string) {
    if (!number) {
      return;
    }

    const vouchers = this.getVouchers();
    vouchers.push({
      name: name,
      number: number,
      count: [...this.model.order?.fbItems, ...this.model.order?.screeningItems].filter((i) => i.hasVoucher()).length,
    });
    this.setItem(storageKey.vouchers, JSON.stringify(vouchers));
    this.voucher$.next(vouchers);
  }

  removeVoucher(number: string) {
    if (!number) {
      return;
    }

    const vouchers = this.getVouchers().filter((v) => v.number !== number);
    if (vouchers.length) {
      this.setItem(storageKey.vouchers, JSON.stringify(vouchers));
      this.voucher$.next(vouchers);
    } else {
      this.removeVouchers();
      this.voucher$.next([]);
    }
  }

  getVouchers(): IVoucher[] {
    return (JSON.parse(sessionStorage.getItem(storageKey.vouchers)) as IVoucher[]) || [];
  }

  setPaymentMethod(model: PaymentMethodViewModel) {
    this.setItem(storageKey.selectedPaymentMethod, JSON.stringify(model));
  }

  removeOrder() {
    this.totalizerService.clear();
    this.setState(Object.assign(this.model ?? new OrderStateModel(), { order: null }));
  }

  removeOrderFromSessionStorage(): void {
    this.removeFromSessionStorage();
    this.countdownComponentService.destroy();
  }

  removeCinema() {
    this.setState(Object.assign(this.model ?? new OrderStateModel(), { cinema: null }));
  }

  isOrderSavedInSessionStorage(): boolean {
    return Boolean(this.getItem(storageKey.orderId));
  }

  cinemaChosen(): boolean {
    return Boolean(this.getItem(storageKey.chosenCinema));
  }

  public useIsDisabled(item?: UserVoucherViewModel | UserCardViewModel): boolean {
    return this.orderNotExist() || this.model?.order?.isRewardsTransaction || item?.isUsed || (item instanceof UserVoucherViewModel && !item?.flgActive);
  }

  hasVoucherNumber(voucherNumber: string) {
    return this.model?.order?.hasVoucherNumber(voucherNumber);
  }

  public orderNotExist(): boolean {
    return !this.model || !this.model.order;
  }

  clearSessionStorage() {
    this.interceptLoadData = true;

    if (this.model && this.model.cinemaId && this.model.orderId && this.orderDataProvider.silentDelete(this.model.cinemaId, this.model.order)) {
      this.removeOrder();
    }
    this.removeUserSessionStorage();
    this.removeOrderFromSessionStorage();
  }

  removeOrderWithDelete(withDestroyCountdown: boolean = true): void {
    if (!this.model || !this.model.cinema || !this.model.order || !this.model.order.id) {
      return;
    }
    this.orderDataProvider.delete(this.model.cinema.id, this.model.order.id).subscribe(() => this.removeOrder());

    if (withDestroyCountdown) {
      this.countdownComponentService.destroy();
    }
  }

  removeOrderWithRequest(withDestroyCountdown: boolean = true): void {
    if (!this.model || !this.model.cinema || !this.model.order || !this.model.order.id) {
      return;
    }

    this.orderDataProvider.silentDelete(this.model.cinema.id, this.model.order);
    this.removeOrder();

    if (withDestroyCountdown) {
      this.countdownComponentService.destroy();
    }
  }

  public setLastScreeningId(screeningId: string): void {
    this.setItem(storageKey.lastScreeningId, screeningId);
  }

  public checkLastScreening(screeningId: string): boolean {
    return this.getItem(storageKey.lastScreeningId) !== screeningId;
  }

  public getMemberGetMemberPromotionId() {
    return this.getItem(storageKey.mgmPromotionId);
  }

  public setMemberGetMemberPromotionId(id: string) {
    return this.setItem(storageKey.mgmPromotionId, id);
  }

  public setEmbededPaymentUrl(value: string) {
    this.setItem(storageKey.embededPaymentUrl, value);
  }

  public getEmbededPaymentUrl() {
    return this.getItem(storageKey.embededPaymentUrl);
  }

  public hasVouchersOrGiftCards(): boolean {
    if (this.model.order) {
      return (
        [...this.model.order.screeningItems, ...this.model.order.fbItems].some((x) => x.hasVoucher()) || this.model.order.paymentMethods.some((x) => x.cardId)
      );
    }

    return false;
  }

  getReservationData() {
    const result = {
      id: this.getItem(storageKey.reservationId),
      cinemaId: this.getItem(storageKey.reservationCinemaId),
      screeningId: this.getItem(storageKey.reservationScreeningId),
      timeTo: DateTime.fromISO(this.getItem(storageKey.reservationScreeningTimeTo)),
    };

    return result.id && result.cinemaId ? result : null;
  }

  isEmailFilled() {
    return this.model.order?.isEmailFilled();
  }

  hasExternalPayments() {
    return this.model.order?.hasExternalPayments();
  }

  clearExternalPayments(provider: ProviderEnum): Observable<OrderViewModel> {
    return this.orderDataProvider.removeExternalPaymentMethod(new PaymentProviderPayMethodRequestModel(this.model.cinemaId, this.model.orderId, provider)).pipe(
      switchMap(() => this.orderDataProvider.findById(this.model?.cinemaId, this.model?.orderId)),
      tap({
        next: (o) => {
          this.setOrder(o);
        },
        error: (e) => {},
      })
    );
  }
}
