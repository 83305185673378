import { Component } from '@angular/core';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { ErrorHelper } from 'libs/core/src/lib/helper/error.helper';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { OneilLoyaltyInputComponent } from '../../loyalty-input/loyalty-input.component';

@Component({
  selector: 'app-voucher-input',
  templateUrl: './voucher-input.component.html',
})
export class OneilVoucherInputComponent extends OneilLoyaltyInputComponent {
  constructor(protected loadingService: LoadingService, protected orderStateService: OrderStateService, protected voucherService: VoucherService) {
    super(loadingService, orderStateService);
  }

  submit() {
    if (!this.value || !this.orderState?.order) {
      return;
    }

    if (this.onSubmit.observed) {
      this.onSubmit.emit(this.value);
      return;
    }

    this.errors = [];
    this.loadingService.showLoader(LoaderEnum.MAIN);

    this.voucherService.assignVoucherToOrderViaApiModel(this.orderState.cinemaId, this.orderState.orderId, this.value).subscribe({
      next: (res) => {
        this.orderStateService.setOrder(res);
        this.clear();
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
      error: (e) => {
        const error = ErrorHelper.getError(e);
        if (error) {
          switch (error.code) {
            case 460:
              this.errors = ['voucher_not_found'];
              break;
            case 461:
              this.errors = ['voucher_already_used'];
              break;
          }
        }

        if (!this.errors || this.errors?.length === 0) {
          this.errors = ['cannot_use'];
        }
        this.loadingService.hideLoader(LoaderEnum.MAIN);
      },
    });
  }
}
