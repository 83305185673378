import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-movie-attributes',
  templateUrl: './movie-attributes.component.html',
})
export class OneilMovieAttributesComponent implements OnInit {
  @Input() release: string;

  constructor() {}

  ngOnInit() {}

  existsMovieCopyAttribute(attribute: string) {
    const attributes = this.release?.split(',').map((a) => a.trim().toLowerCase());
    return attributes?.includes(attribute.toLowerCase());
  }
}
