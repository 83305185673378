import { Component, OnInit } from '@angular/core';
import { BookMovieListComponent } from 'libs/shared/src/lib/component/book-movie-list/book-movie-list.component';

@Component({
  selector: 'app-book-movie-list',
  templateUrl: './book-movie-list.component.html'
})
export class OneilBookMovieListComponent extends BookMovieListComponent implements OnInit {
  constructor() {
    super()
  }
}
