import { AbstractViewModel } from "../../abstract.view.model";
import { CateringApiModel, CateringArticleApiModel, CateringArticleGroupApiModel, CateringArticleItemModifierApiModel, CateringArticleModifierApiModel } from "../../api-model/order/catering.api.model";
import { OrderAliasApiModel } from "../../api-model/order/order-alias.api.model";

export class CateringViewModel extends AbstractViewModel<CateringApiModel> {
  groups = new Array<CateringArticleGroupViewModel>();
  articles = new Array<CateringArticleViewModel>();

  constructor(protected apiModel: CateringApiModel = new CateringApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.groups = this.apiModel.groupList.map(model => new CateringArticleGroupViewModel(model));;
    this.articles = this.apiModel.articleList.map(model => new CateringArticleViewModel(model));;
  }

  toApiModel(): CateringApiModel {
    return undefined;
  }
}

export class CateringArticleGroupViewModel extends AbstractViewModel<CateringArticleGroupApiModel> {
  id: string;
  name ?: string;
  description ?: string;
  parentGroupId ?: string;
  graphicUrl ?: string;

  constructor(protected apiModel: CateringArticleGroupApiModel = new CateringArticleGroupApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.parentGroupId = this.apiModel.parentGroupId;
    this.graphicUrl = this.apiModel.graphicUrl;
  }

  toApiModel(): CateringArticleGroupApiModel {
    return undefined;
  }
}

export class CateringArticleViewModel extends AbstractViewModel<CateringArticleApiModel> {
  id: string;
  parentGroupId ?: string;
  name ?: string;
  price: number;
  taxRate: number;
  description ?: string;
  isLocked: boolean;
  graphicUrl ?: string;
  subArticleList = new Array<CateringArticleViewModel>();
  replacementList = new Array<CateringArticleViewModel>();
  modifierCollectionList = new Array<CateringArticleModifierViewModel>();

  pickupTime: number;

  constructor(protected apiModel: CateringArticleApiModel = new CateringArticleApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.parentGroupId = this.apiModel.groupId;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.taxRate = this.apiModel.taxRate;
    this.description = this.apiModel.description;
    this.isLocked = this.apiModel.isLocked;
    this.graphicUrl = this.apiModel.graphicUrl;
    this.subArticleList = this.apiModel.subArticleList.map(model => new CateringArticleViewModel(model));
    this.replacementList = this.apiModel.replacementList.map(model => new CateringArticleViewModel(model));
    this.modifierCollectionList = this.apiModel.modifierList.map(model => new CateringArticleModifierViewModel(model));
  }

  toApiModel(): CateringArticleApiModel {
    return undefined;
  }
}

export class CateringArticleModifierViewModel extends AbstractViewModel<CateringArticleModifierApiModel> {
  id: string;
  type ?: string;
  name ?: string;
  isRequired: boolean;
  multiChoice: boolean;
  multiChoiceMax: number;
  multiChoiceMin: number;
  separateItem: boolean;
  itemCollection: Array<CateringArticleItemModifierViewModel> = new Array<CateringArticleItemModifierViewModel>();

  constructor(protected apiModel: CateringArticleModifierApiModel = new CateringArticleModifierApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.type = this.apiModel.type;
    this.name = this.apiModel.name;
    this.isRequired = this.apiModel.isRequired;
    this.multiChoice = this.apiModel.multiChoice;
    this.multiChoiceMax = this.apiModel.multiChoiceMax;
    this.multiChoiceMin = this.apiModel.multiChoiceMin;
    this.separateItem = this.apiModel.separateItem;
    this.itemCollection = this.apiModel.itemList.map(model => new CateringArticleItemModifierViewModel(model));;
  }

  toApiModel(): CateringArticleModifierApiModel {
    return undefined;
  }
}

export class CateringArticleItemModifierViewModel extends AbstractViewModel<CateringArticleItemModifierApiModel> {
  id: string;
  name ?: string;
  description ?: string;
  price: number;
  quantity: number;
  relatedItemList: Array<string> = new Array<string>();

  constructor(protected apiModel: CateringArticleItemModifierApiModel = new CateringArticleItemModifierApiModel()
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.price = this.apiModel.price;
    this.quantity = this.apiModel.quantity;
    this.relatedItemList = this.apiModel.relatedItemList;
  }

  toApiModel(): CateringArticleItemModifierApiModel {
    return undefined;
  }
}


