import { UserVoucherTransferViewModel } from './voucher-transfer/user-voucher-transfer.view.model';
import { UserCardTransferViewModel } from './card-transfer/user-card-transfer.view.model';
import { AbstractViewModel } from '../../abstract.view.model';
import { UserHistoryApiModel } from '../../api-model/user-history/user-history.api.model';
import { UserOrderViewModel } from './order/user-order.view.model';
import { UserTicketViewModel } from './ticket/user-ticket.view.model';
import { UserMoviePrintViewModel } from './movie-print/user-movie-print.view.model';
import { UserMovieViewModel } from './movie/user-movie.view.model';
import { UserEventViewModel } from './event/user-event.view.model';
import { UserSeatViewModel } from './seat/user-seat.view.model';
import { UserScreeningViewModel } from './screening/user-screening.view.model';
import { UserScreenViewModel } from './screen/user-screen.view.model';
import { UserCardViewModel } from './card/user-card.view.model';
import { UserCardTypeViewModel } from './card-type/user-card-type.view.model';
import { UserVoucherViewModel } from './voucher/user-voucher.view.model';
import { UserFbItemViewModel } from './fb-item/user-fb-item.view.model';
import { UserVoucherItemViewModel } from './voucher-item/user-voucher-item.view.model';
import { UserBookingViewModel } from './booking/user-booking.view.model';

export class UserHistoryViewModel extends AbstractViewModel<UserHistoryApiModel> {
  bookings: UserBookingViewModel[] = [];
  orders: UserOrderViewModel[] = [];
  tickets: UserTicketViewModel[] = [];
  moviePrints: UserMoviePrintViewModel[] = [];
  movies: UserMovieViewModel[] = [];
  events: UserEventViewModel[] = [];
  seats: UserSeatViewModel[] = [];
  screenings: UserScreeningViewModel[] = [];
  screens: UserScreenViewModel[] = [];
  cards: UserCardViewModel[] = [];
  cardTransfers: UserCardTransferViewModel[] = [];
  cardTypes: UserCardTypeViewModel[] = [];
  vouchers: UserVoucherViewModel[] = [];
  fbItems: UserFbItemViewModel[] = [];
  voucherItems: UserVoucherItemViewModel[] = [];
  voucherTransfers: UserVoucherTransferViewModel[] = [];

  constructor(protected apiModel: UserHistoryApiModel = new UserHistoryApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.bookings = this.apiModel.bookings.map((booking) => new UserBookingViewModel(booking));
    this.orders = this.apiModel.orders.map((order) => new UserOrderViewModel(order));
    this.tickets = this.apiModel.tickets.map((ticket) => new UserTicketViewModel(ticket));
    this.moviePrints = this.apiModel.moviePrints.map((moviePrint) => new UserMoviePrintViewModel(moviePrint));
    this.movies = this.apiModel.movies.map((movie) => new UserMovieViewModel(movie));
    this.events = this.apiModel.events.map((event) => new UserEventViewModel(event));
    this.seats = this.apiModel.seats.map((event) => new UserSeatViewModel(event));
    this.screenings = this.apiModel.screenings.map((screening) => new UserScreeningViewModel(screening));
    this.screens = this.apiModel.screens.map((screen) => new UserScreenViewModel(screen));
    this.cards = this.apiModel.cards.map((card) => new UserCardViewModel(card));
    this.cardTransfers = this.apiModel.cardTransfers.map((cardTransfer) => new UserCardTransferViewModel(cardTransfer));
    this.cardTypes = this.apiModel.cardTypes.map((cardType) => new UserCardTypeViewModel(cardType));
    this.vouchers = this.apiModel.vouchers.map((voucher) => new UserVoucherViewModel(voucher));
    this.fbItems = this.apiModel.fbItems.map((fbItem) => new UserFbItemViewModel(fbItem));
    this.voucherItems = this.apiModel.voucherItems.map((voucherItem) => new UserVoucherItemViewModel(voucherItem));
    this.voucherTransfers = this.apiModel.voucherTransfers.map((voucherTransfer) => new UserVoucherTransferViewModel(voucherTransfer));
  }

  toApiModel(): UserHistoryApiModel {
    return undefined;
  }
}
