import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { storageKey } from 'libs/core/src/app.const';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { RedirectionService } from 'libs/core/src/lib/service/redirection-service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { StateService } from 'libs/core/src/lib/state/state.service';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-positivecinema',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loaderEnum: typeof LoaderEnum = LoaderEnum;
  translationLoaded: boolean = false;

  @ViewChild('loginPopupTemplate') loginModal: TemplateRef<any>;

  constructor(
    protected translateService: TranslateService,
    protected title: Title,
    protected redirectionService: RedirectionService,
    protected stateService: StateService,
    protected authStateService: AuthStateService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected modalService: BsModalService
  ) {
    translateService.setDefaultLang(environment.constants.language.default);
    const lang = this.stateService.getItem(storageKey.lang);
    this.useLanguage(lang ? lang : environment.constants.language.default);
    this.makeRedirectionOnAppLaunch();
  }

  public ngOnInit(): void {
    this.authStateService.retrieveUserToken();

    this.router.events.subscribe(() => {
      this.title.setTitle(environment.metadata.title);
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.signin === 'true' && !this.authStateService.userIsLoggedAndTokenIsValid()) {
        this.modalService.show(this.loginModal, {});
      }
    });
  }

  async useLanguage(locale: string) {
    // await the http request for the translation file
    await this.translateService.use(locale).toPromise();
    this.translationLoaded = true;
  }

  private makeRedirectionOnAppLaunch(): void {
    let urlToRedirect: string | null = this.redirectionService.getRedirectionOnNextAppLaunch();
    this.redirectionService.removeRedirectionOnNextAppLaunch();
    if (!urlToRedirect) {
      return;
    }

    const queryParameters: URLSearchParams = new URLSearchParams(window.location.search);
    const urlParameters: string = queryParameters.toString();

    if (urlParameters && urlParameters.length > 0) {
      urlToRedirect = urlToRedirect + '&' + urlParameters;
    }

    window.location.href = urlToRedirect;
  }
}
