import { Component, OnInit } from '@angular/core';
import { environment } from 'apps/oneil/src/environments/environment';

@Component({
  selector: 'app-voucher-voucher-details-component',
  templateUrl: './voucher-details.component.html',
  styleUrls: ['./voucher-details.component.scss'],
})
export class OneilVoucherDetailsComponent implements OnInit {
  public env: any = {};

  public constructor() {
    this.env = environment;
  }

  public ngOnInit(): void {}
}
