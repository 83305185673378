import { forkJoin, iif, noop, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { UserDataProvider } from '../data-provider/user.data-provider';
import { CardTypeViewModel } from '../model/view-model/card/card-type.view.model';
import { CardViewModel } from '../model/view-model/account-items/card/card.view.model';
import { appProjectName } from '../../app.const';
import { AppService } from '../service/app.service';
import { CmsHelper } from './cms.helper';
import { AuthStateService } from '../state/auth.state.service';

@Injectable({
  providedIn: 'root',
})
export class CardHelper {
  public static membershipKey = 'Membership';
  public static loyaltyKey = 'Loyalty';

  constructor(
    protected userDataProvider: UserDataProvider,
    protected cardDataProvider: CardDataProvider,
    protected appService: AppService,
    protected cmsHelper: CmsHelper,
    protected authStateService: AuthStateService
  ) {}

  public findMembership(cinemaId?: string): Observable<MembershipCardInterface> {
    return iif(
      () => !this.authStateService.userIsLoggedAndTokenIsValid(),
      of(null),
      forkJoin({
        accountItems: this.userDataProvider.getAccountItemsViaApiModel(),
        cardTypes: cinemaId ? this.cardDataProvider.types(cinemaId) : of(null),
      }).pipe(
        mergeMap((response) => {
          let cardType: CardTypeViewModel;

          const card: CardViewModel = response.accountItems.cards.find(
            (x) => x.typeDescriptions.find((y) => y === CardHelper.membershipKey) && x.typeDescriptions.find((y) => y === CardHelper.loyaltyKey)
          );

          if (card && response.cardTypes) {
            cardType = response.cardTypes.find((x) => x.id === card.cardTypeId);
          }

          return of({
            card: card,
            cardType: cardType,
            canReserve: cardType ? cardType.reservationsAllowed : false,
          });
        })
      )
    );
  }

  findCard(cinemaId?: string): Observable<CardInterface> {
    if (!this.cmsHelper.canUseCms && !this.appService.isProject(appProjectName.ONEIL)) {
      return of(null);
    }

    return forkJoin({
      accountItems: this.userDataProvider.getAccountItemsViaApiModel(),
      cardTypes: cinemaId ? this.cardDataProvider.types(cinemaId) : of(null),
    }).pipe(
      mergeMap((response) => {
        let cardType: CardTypeViewModel;
        let card: CardViewModel;

        if (this.appService.isProject(appProjectName.ONEIL)) {
          card = response.accountItems.cards.find((x) => x.typeDescriptions.find((y) => y === CardHelper.loyaltyKey));
        } else {
          card = response.accountItems.cards.find(
            (x) => x.typeDescriptions.find((y) => y === CardHelper.membershipKey) && x.typeDescriptions.find((y) => y === CardHelper.loyaltyKey)
          );
        }

        if (card && response.cardTypes) {
          cardType = response.cardTypes.find((x) => x.id === card.cardTypeId);
        }

        return of({
          card: card,
          cardType: cardType,
          canReserve: cardType ? cardType.reservationsAllowed : false,
        });
      })
    );
  }
}

export interface CardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
}

export interface MembershipCardInterface extends CardInterface, CanReserveCardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
  canReserve: boolean;
}

export interface LoyaltyCardInterface extends CardInterface {
  cardType: CardTypeViewModel;
  card: CardViewModel;
}

export interface CanReserveCardInterface {
  canReserve: boolean;
}
