import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WorldpayRedirectHubComponent } from 'libs/shared/src/lib/page/payment/worldpay-redirect-hub/worldpay-redirect-hub.component';

@Component({
  selector: 'app-worldpay-redirect-hub',
  template: '',
  //styles: ['body { overflow: hidden !important; background-color: #191f29 !important;}'],
  encapsulation: ViewEncapsulation.None,
})
export class OneilWorldpayRedirectHubComponent extends WorldpayRedirectHubComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    window.top.location.href = this.redirectUrl;
  }
}
