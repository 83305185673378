import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationModel } from './notification.model';
import { NotificationType } from './notification-type.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notification = new Subject<NotificationModel>();
  notification$: Observable<NotificationModel>;

  constructor() {
    this.notification$ = this.notification.asObservable();
  }

  addNotification(notification: NotificationModel) {
    this.notification.next({ ...notification });
  }

  clear() {
    this.notification.next(new NotificationModel(null, NotificationType.CLEAR));
  }
}
