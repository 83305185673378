<ng-container *ngIf="currentTrailer">
  <ng-container [ngSwitch]="playerType">

    <ng-container *ngSwitchCase="'standalone'">
      <vg-player (onPlayerReady)="onPlayerReady($event)">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-controls vgFor="singleVideo" [vgAutohide]="true" [vgAutohideTime]="2">
          <vg-play-pause></vg-play-pause>

          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>

          <vg-mute></vg-mute>
          <vg-volume></vg-volume>
          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video #media [vgMedia]="media" [src]="currentTrailer.value" id="singleVideo" preload="auto" crossorigin
               [poster]="thumbnail"></video>
      </vg-player>
    </ng-container>

    <ng-container *ngSwitchCase="'embeded'">
      <div [innerHTML]="embeddedPlayer" id="embeded-player"></div>
    </ng-container>

  </ng-container>

  <div class="trailer-bar d-flex align-items-center justify-content-end pr-3 pr-md-5">
    <span> {{ 'details.trailer' | translate }} </span>
    <div class="d-flex">
      <ng-container *ngFor="let trailer of trailers | orderBy: 'key'">
        <button class="btn" [ngClass]="{'btn-link': trailer.key === currentTrailer.key}" *ngIf="trailer.value"
                (click)="changeTrailerLanguage(trailer)">{{ ('layout.header.languagechange.' + trailer.key) | translate }}</button>
      </ng-container>
    </div>
  </div>
</ng-container>