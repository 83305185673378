<div class="container p-0">
  <div class="d-flex align-items-center borderbottom">
    <div class="d-none d-sm-flex col-sm-3">
    </div>
    <div class="logocontainer brand ml-3 col-9 col-sm-6 text-sm-center">
      <a href="{{env.siteUrl}}">
        <img src="{{env.theme.assetsDir}}/images/logo.png">
      </a>
    </div>
    <div class="col-sm-3 px-0 px-sm-3">
      <app-user-status></app-user-status>
    </div>
  </div>
</div>
<app-order-background-component></app-order-background-component>