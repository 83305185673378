<div class="container p-0">
  <div class="screening-date d-flex align-items-center justify-content-between">
    <div>
      <app-screening-select-date [dateSelected]="dateSelected" (onDateChangeEvent)="onDateChange($event)">
      </app-screening-select-date>
    </div>
    <div>
      <div *ngIf="showCinemaSwitcher">
        <app-screening-select-cinema [cinemaList]="cinemaList" [cinema]="cinema"
                                     (onCinemaChangeEvent)="onCinemaChange($event)"></app-screening-select-cinema>
      </div>
    </div>
  </div>
</div>
<app-screening-list [movieCopyList]="movieCopyList" [movieScheduleCollection]="movieScheduleViewModelCollection"
                    [showOnlyGeneralAdmission]="showOnlyGeneralAdmission" [cinema]="cinema"
                    [smooth-in]="loadingService.isLoaded(loaderEnum.MAIN)">
</app-screening-list>