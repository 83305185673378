import {Component, OnInit} from '@angular/core';
import { FooterComponent } from 'libs/shared/src/lib/component/layout/footer/footer.component';
import { environment } from 'apps/oneil/src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class OneilFooterComponent extends FooterComponent implements OnInit {
  env = environment;
  
  constructor() {
    super(environment)
  }

  ngOnInit() {
  }
}