import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CardDataProvider } from 'libs/core/src/lib/data-provider/card.data-provider';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { UserDataProvider } from 'libs/core/src/lib/data-provider/user.data-provider';
import { OrderStateModel } from 'libs/core/src/lib/model/state/order.state.model';
import { CardViewModel } from 'libs/core/src/lib/model/view-model/account-items/card/card.view.model';
import { AccountItemsVoucherViewModel } from 'libs/core/src/lib/model/view-model/account-items/voucher/account-items-voucher.view.model';
import { CardPaymentService } from 'libs/core/src/lib/service/card-payment.service';
import { DateTimeService } from 'libs/core/src/lib/service/datetime.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { DateTime } from 'luxon';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, map, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface IAccountItem {
  model: AccountItemsVoucherViewModel | CardViewModel;
  modelType: string;
  typeName: string;
  name: string;
  description: string;
  expiryDate: DateTime;
  checked: boolean;
}

@UntilDestroy()
@Component({
  template: '',
})
export class AccountItemsComponent implements OnInit {
  @Input() title: string;
  @Input() availableTypes: string[] = ['card', 'voucher'];

  @Output() itemChangeState = new EventEmitter<IAccountItem>();
  @Output() onError = new EventEmitter<HttpErrorResponse>();

  public items: IAccountItem[];

  public vouchers: IAccountItem[];
  public giftCards: IAccountItem[];
  public discountCards: IAccountItem[];
  public prepaids: IAccountItem[];

  public loading = false;
  public isLogged = false;

  public orderState: OrderStateModel;
  public modalRef: BsModalRef;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected translator: TranslateService,
    protected authStateService: AuthStateService,
    protected userDataProvider: UserDataProvider,
    protected cardDataProvider: CardDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected voucherService: VoucherService,
    protected orderStateService: OrderStateService,
    protected messageService: MessageService,
    protected modalService: BsModalService,
    protected dateTimeService: DateTimeService,
    protected cardPaymentService: CardPaymentService
  ) {}

  ngOnInit(): void {
    combineLatest([this.orderStateService.state$, this.authStateService.state$], (order, user) => ({ order, user }))
      .pipe(untilDestroyed(this))
      .subscribe((r) => {
        this.orderState = r.order;
        this.checkUsedItems();

        if (r.user?.token) {
          this.loading = true;
          this.userDataProvider
            .getAccountItemsViaApiModel()
            .pipe(
              map((data) => {
                return [
                  ...(this.availableTypes.includes('voucher') ? data.vouchers : []).map((item) => this.makeAccoutnItem(item, 'voucher')),
                  ...(this.availableTypes.includes('card') ? data.cards.filter((c) => !c.expiryDate || !this.dateTimeService.isPast(c.expiryDate)) : []).map(
                    (item) => this.makeAccoutnItem(item, 'card')
                  ),
                ];
              }),
              tap((items) => {
                if (this.orderState.order) {
                  const vouchers = this.orderState.order.getVouchers();
                  if (vouchers) {
                    items.forEach((item) => {
                      if (item.modelType === 'voucher' && vouchers.some((v) => v.number === item.model.number)) {
                        item.checked = true;
                      }
                    });
                  }
                }

                this.items = items;
                this.vouchers = items.filter((f) => f.modelType === 'voucher');
                this.discountCards = items.filter((f) => f.modelType === 'card' && this.cardPaymentService.isDiscountCard(f.model?.type));
                this.giftCards = items.filter((f) => f.modelType === 'card' && this.cardPaymentService.isGiftCard(f.model?.type));
                this.prepaids = items.filter((f) => f.modelType === 'card' && this.cardPaymentService.isPrepaid(f.model?.type));
              })
            )
            .subscribe(() => {
              this.checkUsedItems();
              this.loading = false;
            });
        }
      });

    this.isLogged = this.authStateService.userIsLoggedAndTokenIsValid();
    this.authStateService.state$.subscribe((state) => {
      this.isLogged = this.authStateService.userIsLoggedAndTokenIsValid();
    });
  }

  checkUsedItems() {
    this.checkUsedVouchers();
    this.checkUsedCards();
    this.checkUsedDiscountCard();

    //this.items = [...(this.vouchers ?? []), ...(this.giftCards ?? []), ...(this.discountCards ?? []), ...(this.prepaids ?? [])];
  }

  checkUsedVouchers() {
    this.vouchers?.forEach((i) => (i.checked = this.orderState.order?.getVouchers().some((v) => v.number === i.model.number)));
  }

  checkUsedCards() {
    [...(this.giftCards ?? []), ...(this.prepaids ?? [])]?.forEach(
      (i) => (i.checked = Boolean(this.orderState.order?.paymentMethods?.find((paymentMethod) => paymentMethod.cardId === i.model.id)))
    );
  }

  checkUsedDiscountCard() {
    this.discountCards?.forEach((i) => (i.checked = this.orderState.order?.cardId === i.model.id));
  }

  useItem(item) {
    this.itemChangeState.emit(item);
  }

  removeVoucher(voucher: AccountItemsVoucherViewModel) {
    return this.voucherService.removeVoucherFromOrder(this.orderState.cinema.id, this.orderState.order.id, voucher.number).pipe(map(() => voucher));
  }

  makeAccoutnItem(model: CardViewModel | AccountItemsVoucherViewModel, type: string): IAccountItem {
    return {
      model: model,
      modelType: type,
      name: model.name,
      description: model.description,
      expiryDate: model.expiryDate,
    } as IAccountItem;
  }

  public showLoginPopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'login-modal' });
  }
}
