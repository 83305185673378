import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {plainToInstance} from 'class-transformer';
import { AgreementAggregationApiModel } from '../model/api-model/agreement/agreement-aggregation.api.model';
import { AgreementApiModel } from '../model/api-model/agreement/agreement.api.model';

@Injectable({
  providedIn: 'root'
})
export class UserAgreementHttpService {
  public agreementList: Observable<AgreementApiModel[]>;

  constructor(
    private http: HttpClient,
  ) {
  }

  listViaApiModel(): Observable<AgreementAggregationApiModel> {
    return this.http.get<AgreementAggregationApiModel[]>(`/user/agreement`).pipe(
      map((result) => plainToInstance(AgreementAggregationApiModel, result as object))
    );
  }
}
