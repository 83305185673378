import { Inject, Injectable } from '@angular/core';
import { map, merge, mergeMap, tap } from 'rxjs/operators';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { EventHttpService } from '../http/event.http.service';
import { GenreHttpService } from '../http/genre.http.service';
import { MovieCopyHttpService } from '../http/movie-copy.http.service';
import { EventRequestModel } from '../model/request/event.request.model';
import { EventViewModel } from '../model/view-model/event/event.view.model';
import { GenreApiModel } from '../model/api-model/genre/genre.api.model';
import { MovieHttpService } from '../http/movie.http.service';
import { MoviePrintApiModel } from '../model/api-model/movie/movie-print.api.model';
import { MoviePrintViewModel } from '../model/view-model/movie/movie-print.view.model';
import { MovieApiModel } from '../model/api-model/movie/movie.api.model';
import { MovieViewModel } from '../model/view-model/movie/movie.view.model';
import { CmsHelper } from '../helper/cms.helper';
import { WordpressHttpService } from 'libs/webcomponent/src/lib/http/wordpress.http.service';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class EventDataProvider {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private eventHttpService: EventHttpService,
    private genreHttpService: GenreHttpService,
    private movieCopyHttpService: MovieCopyHttpService,
    private movieHttpService: MovieHttpService,
    private wordpressHttpService: WordpressHttpService,
    private cmsHelper: CmsHelper
  ) {}

  public listViaApiModel(model: EventRequestModel, eventId?: string): Observable<EventViewModel[]> {
    return this.eventHttpService
      .listViaApiModel(model.cinemaId, eventId, model.dateTimeFrom, model.dateTimeTo)
      .pipe(map((result) => result.map((event) => new EventViewModel(event))));
  }

  findByIdViaApiModel(cinemaId: string, eventId: string, screeningId?: string, dateFrom?: string, dateTo?: string): Observable<EventViewModel> {
    return this.eventHttpService.listViaApiModel(cinemaId, eventId, dateFrom, dateTo).pipe(
      map((result) => result.filter((e) => !screeningId || e.screeningId === screeningId)[0]),
      mergeMap((eventResponse) => iif(() => !!eventResponse, of(new EventViewModel(eventResponse)), of(new EventViewModel()))),
      mergeMap((eventResponse) =>
        iif(
          () => !!eventResponse.items[0]?.moviePrintId,
          this.movieCopyHttpService.findByIdViaApiModel(eventResponse.items[0]?.moviePrintId).pipe(
            map((moviePrintApiModel: MoviePrintApiModel) => {
              eventResponse.movieCopy = new MoviePrintViewModel(moviePrintApiModel);
              return eventResponse;
            })
          ),
          of(eventResponse)
        )
      ),
      mergeMap((eventResponse) =>
        iif(
          () => !!eventResponse.movieCopy?.movieId,
          this.movieHttpService.findByIdViaApiModel(eventResponse.movieCopy?.movieId).pipe(
            map((movieApiModel: MovieApiModel) => {
              eventResponse.movieCopy.movie = new MovieViewModel(movieApiModel);
              return eventResponse;
            })
          ),
          of(eventResponse)
        )
      )
    );
  }

  getEvents(requestModel: EventRequestModel, eventId?: string) {
    const restEndpoint = this.eventHttpService.listViaApiModel(requestModel.cinemaId, eventId, requestModel.dateTimeFrom, requestModel.dateTimeTo);
    if (this.cmsHelper.canUseCms) {
      return forkJoin({
        restEvents: restEndpoint.pipe(map((result) => result.map((event) => new EventViewModel(event)))),
        wpEvents: this.wordpressHttpService.getEvents(),
      }).pipe(
        tap((result) => result.restEvents.map((event) => (event.priority = result.wpEvents.filter((e) => e.id === event.id)[0]?.priority))),
        tap((result) =>
          result.restEvents.map(
            (event) => (event.firstPosterOrDefault = event.posters[0] || make_url(this.environment, this.environment.constants.moviePosterPlaceholder))
          )
        ),
        map((r) => {
          return r.restEvents;
        })
      );
    }

    return restEndpoint.pipe(map((result) => result.map((event) => new EventViewModel(event))));
  }
}
