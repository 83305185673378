import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export abstract class LoginHelper {
  public static emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';

  public static isAccountInactive(code: number): boolean {
    return code === 1;
  }

  public static isPasswordInvalid(code: number): boolean {
    return code === 486;
  }

  public static isAccountNotFound(code: number): boolean {
    return code === -6;
  }

  public static getErrorMessage(code: number): string {
    switch (true) {
      case this.isAccountInactive(code):
        return '10012';
      case this.isPasswordInvalid(code):
        return '10014';
      case this.isAccountNotFound(code):
        return '10013';
      default:
        return '10008';
    }
  }

  public static passwordIsValid(password: AbstractControl, email: AbstractControl): boolean {
    return password.value && password.value.length !== 0 &&
      !password?.errors?.pattern &&
      (email.value ? !password.value.includes(email.value) : true);
  }

  public static invalidPassword(password: AbstractControl, email: AbstractControl): ValidationErrors | null {
    return password.value && email.value && password.value.length !== 0 && email.value.length !== 0 && password.value.includes(email.value) ? {invalid: true} : null;
  }

  public static clearBirthdayDate(form: FormGroup): void {
    if (!form) {
      return;
    }

    for (const field of ['day', 'month', 'year']) {
      const value = form.get(field).value;

      if (value && value.length) {
        form.get(field).setValue(value.toString().replace(/[^0-9]/gi, ''));
      }
    }
  }

  private static isNullOrEmpty(value: any) {
    return value === null || value === undefined || value as string === '';
  }
}
