<ng-container *ngIf="personalForm">
  <form [formGroup]="personalForm" (submit)="onSubmit()">
    <div class="row mb-3">
      <div class="d-flex align-items-center col-3 form-control-description">
        {{'personal.personal.form.email' | translate}}*
      </div>
      <div class="col-9">
        <div class="form-group required m-0">
          <input type="text" class="form-control" [disableControl]="formLocked" id="email"
                 [ngClass]="{'border border-danger': personalForm.get('email')?.errors && touchedFormControls['email'] }"
                 formControlName="email" />
        </div>
      </div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" appFormErrors="userEmail"
           [errors]="formErrors"></div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" *ngIf="touchedFormControls['email']">
        <p class="mb-1" *ngIf="personalForm.get('email')?.errors?.required"> {{'errors.10003' | translate }} </p>
        <p class="mb-1" *ngIf="personalForm.get('email')?.errors?.email"> {{'errors.10102' | translate }} </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="d-flex align-items-center col-3 form-control-description" style="line-height: 1rem;">
        {{'personal.personal.form.emailRepeat' | translate}}*
      </div>
      <div class="col-9">
        <div class="form-group required m-0">
          <input type="text" class="form-control" [disableControl]="formLocked"
                 [ngClass]="{'border border-danger': personalForm.get('emailRepeat')?.errors && touchedFormControls['emailRepeat'] }"
                 id="emailRepeat" formControlName="emailRepeat" autocomplete="off" />
        </div>
      </div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" appFormErrors="userEmail"
           [errors]="formErrors"></div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" *ngIf="touchedFormControls['emailRepeat']">
        <p class="mb-1" *ngIf="personalForm.get('emailRepeat')?.errors?.required">
          {{'errors.10003' | translate }}
        </p>
        <p class="mb-1" *ngIf="personalForm.get('emailRepeat')?.errors?.emailsNotMatches">
          {{'errors.10104' | translate }}
        </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="d-flex align-items-center col-3 form-control-description">
        {{'personal.personal.form.firstname' | translate}}
      </div>
      <div class="col-9">
        <div class="form-group required m-0">
          <input type="text" class="form-control" [disableControl]="formLocked"
                 [ngClass]="{'border border-danger': personalForm.get('name')?.errors && touchedFormControls['name'] }"
                 id="name" formControlName="name" />
        </div>
      </div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" appFormErrors="userFirstName"
           [errors]="formErrors"></div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" *ngIf="touchedFormControls['name']">
        <p class="mb-1" *ngIf="personalForm.get('name')?.errors?.required">
          {{'errors.10001' | translate }}
        </p>
        <p class="mb-1" *ngIf="personalForm.get('name')?.errors?.maxlength">
          {{'errors.10106' | translate }}
        </p>
      </div>
    </div>

    <div class="row mb-3">
      <div class="d-flex align-items-center col-3 form-control-description">
        {{'personal.personal.form.phone' | translate}}
      </div>
      <div class="col-9">
        <div class="form-group required m-0">
          <input type="text" class="form-control" [disableControl]="formLocked"
                 [ngClass]="{'border border-danger': personalForm.get('phone')?.errors && touchedFormControls['phone'] }"
                 id="phone" formControlName="phone" mask="000 000 0000" showMaskTyped="true" />
        </div>
      </div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" appFormErrors="userPhone"
           [errors]="formErrors"></div>
      <div class="help-block with-errors col-9 pl-0 text-right ml-auto mr-0" *ngIf="touchedFormControls['phone']">
        <p class="mb-1" *ngIf="personalForm.get('phone')?.errors?.required">
          {{'errors.10010' | translate }}
        </p>
        <p class="mb-1" *ngIf="personalForm.get('phone')?.errors?.pattern">
          {{'errors.10103' | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 d-flex">
        <div class="form-group required offset-3">
          <input type="checkbox" class="form-control form-control-checkbox w-100" [disableControl]="formLocked"
                 [ngClass]="{'border border-danger': personalForm.get('customAgreement')?.errors && touchedFormControls['customAgreement'] }"
                 id="customAgreement" formControlName="customAgreement" />
          <div class="help-block with-errors col-12 text-right" appFormErrors="customAgreement" [errors]="formErrors">
          </div>
        </div>
        <div>
          <span [innerHTML]="'personalAndPayment.agreement' | translate"></span>
          <div class="help-block with-errors col-12 pl-0 text-sm-left" *ngIf="touchedFormControls['customAgreement']">
            <p class="mb-1" *ngIf="personalForm.get('customAgreement')?.errors?.required">
              {{'errors.10015' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>