import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderEnum } from '../enum/loader.enum';
import { LoaderState } from '../model/loading/loader-state.model';
import { ILoadingConfig, LoadingConfig } from '../model/loading/loading-config';
import { LoadingStatus } from '../model/loading/loading-status.enum';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loaderState: BehaviorSubject<LoaderState> = new BehaviorSubject<LoaderState>(null);
  public loaderState$: Observable<LoaderState>;

  private loaderMapState: BehaviorSubject<Map<string, LoadingStatus>> = new BehaviorSubject<Map<string, LoadingStatus>>(null);
  public loaderMapState$: Observable<Map<string, LoadingStatus>>;

  public loadingConfig: ILoadingConfig;
  public loadingStatus: LoadingStatus;
  public loadingStatusMap = new Map<string, LoadingStatus>();

  constructor(@Optional() @Inject('loadingConfig') private config: ILoadingConfig) {
    this.loadingConfig = this.config || new LoadingConfig();
    this.loaderState$ = this.loaderState.asObservable();
    this.loaderMapState$ = this.loaderMapState.asObservable();
  }

  public showLoader(id: string) {
    this.loadingStatus = LoadingStatus.pending;
    this.loadingStatusMap.set(id.toLowerCase(), LoadingStatus.pending);
    this.loaderState.next(new LoaderState(id, true));
    this.loaderMapState.next(this.loadingStatusMap);
  }

  public hideLoader(id: string, timeout: number = 50) {
    this.loadingStatus = LoadingStatus.success;
    this.loadingStatusMap.set(id.toLowerCase(), LoadingStatus.success);
    setTimeout(() => {
      this.loaderState.next(new LoaderState(id, false));
      this.loaderMapState.next(this.loadingStatusMap);
    }, timeout);
  }

  public isLoaded(id?: LoaderEnum): boolean {
    if (!id) {
      return;
    }

    const status = this.loadingStatusMap?.get(id.toLowerCase());
    return status && status !== LoadingStatus.pending;
  }

  public isLoading(id?: LoaderEnum | string): boolean {
    if (!id) {
      return;
    }

    const status = this.loadingStatusMap?.get(id.toLowerCase());
    return status && status === LoadingStatus.pending;
  }
}
