import { Component, Input, OnInit } from '@angular/core';
import { CinemaViewModel } from 'libs/core/src/lib/model/view-model/cinema/cinema.view.model';
import { MoviePrintViewModel } from 'libs/core/src/lib/model/view-model/movie/movie-print.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { ScreenViewModel } from 'libs/core/src/lib/model/view-model/screen/screen.view.model';

@Component({
  template: '',
})
export class BookMovieListComponent implements OnInit {
  @Input() cinema: CinemaViewModel = null;
  @Input() screen: ScreenViewModel = null;
  @Input() movieCopy: MoviePrintViewModel = null;
  @Input() tickets: ScreeningItemViewModel[] = [];

  constructor() {}

  ngOnInit(): void {}

  getTicketsGroupByName() {
    const map = new Map(Array.from(this.tickets, (obj) => [obj['name'], []]));
    this.tickets.forEach((obj) => map.get(obj['name']).push(obj));
    return Array.from(map.values());
  }

  getTicketSeatsByGroup(group: any) {
    if (!group || group.length === 0) {
      return;
    }

    const tickets = this.tickets.filter((f) => f.seatId && f.ticketId === group[0].ticketId);
    return tickets.length > 0 ? tickets.map((m) => m.row?.concat(m.seat)).join(', ') : '';
  }

  getTicketSeats() {
    return this.tickets
      .filter((f) => f.seatId)
      .map((m) => m.row.concat(m.seat))
      .join(', ');
  }
}
