<div class="date-picker-container d-flex align-items-center">
    <div class="date-picker-bar">
        <ng-template [ngIf]="!label" [ngIfElse]="onlyLabel">
            <div class="d-flex align-items-center pointer" (click)="dp.toggle()">
                <i class="link pc-icon-calendar2"></i>
                <span class="ml-3">
                    <span>{{date | dateTime: format : 2 }}</span>
                </span>
            </div>
        </ng-template>
    </div>
    <div class="date-picker-paginator d-flex ml-auto">
        <i class="pc-icon-chevron-normal-left pointer p-2" (click)="dateDecrease()"
           [ngClass]="{'disabled': canPrev(this.date.minus({days: this.step}))}"></i>
        <i class="pc-icon-chevron-normal-right pointer p-2" (click)="dateIncrease()"></i>
    </div>
</div>
<div #dp="bsDatepicker" bsDatepicker [bsValue]="bsDatepickerValue" [bsConfig]="bsConfig" [container]="'app-date-picker'"
     (bsValueChange)="onDatepickerValueChange($event)" [outsideClick]="true" triggers="" [isOpen]="dp.isOpen">
</div>
<ng-template #onlyLabel>
    <div class="d-inline-flex align-items-center">
        <i class="pc-icon-calendar2"></i>
        <span class="mx-3">{{label}}</span>
    </div>
</ng-template>