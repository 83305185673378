import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutTemplateType } from 'libs/core/src/lib/enum/layout-template.enum';
import { AllowedGuard } from 'libs/core/src/lib/guard/allowed.guard';
import { ErrorGuard } from 'libs/core/src/lib/guard/error.guard';
import { HomePageComponent } from 'libs/shared/src/lib/page/home/home.component';
import { LeavePageGuard } from 'libs/core/src/lib/guard/leave-page.guard';
import { OneilMessagePageComponent } from './page/message/message.component';
import { OneilOrderSummaryPageComponent } from './page/order-summary/order-summary.component';
import { OneilPaymentPageComponent } from './page/payment/payment.component';
import { OneilBasketPageComponent } from './page/basket/basket.component';
import { OneilScreenPageComponent } from './page/screen/screen.component';
import { OneilScreeningPageComponent } from './page/screening/screening.component';
import { OneilCoreComponent } from './component/core/core.component';
import { TimeZoneTestPageComponent } from 'libs/shared/src/lib/page/test/tz.component';
import { OneilPersonalPageComponent } from './page/personal/personal.component';
import { OneilUserPageComponent } from './page/user/user-page.component';
import { LoggedGuard } from 'libs/core/src/lib/guard/logged.guard';
import { OneilGiftCardsPageComponent } from './page/gift-cards/gift-cards-page.component';
import { OneilRefundPageComponent } from './page/refund/refund.component';
import { OneilUserRegisterComponent } from './page/user/register/user-register.component';
import { OneilWorldpayRedirectHubComponent } from './page/worldpay/worldpay-redirect-hub.component';

const routes: Routes = [
  {
    path: 'p',
    component: OneilWorldpayRedirectHubComponent,
    data: {
      pageIdentify: 'paymentstate',
      templateType: LayoutTemplateType.Simple,
    },
  },
  {
    path: '',
    component: OneilCoreComponent,
    data: {
      templateType: LayoutTemplateType.Default,
    },
    children: [
      {
        path: '',
        component: HomePageComponent,
        data: {
          pageIdentify: 'home',
        },
      },
      {
        path: 'test/timezone',
        component: TimeZoneTestPageComponent,
        data: {
          pageIdentify: 'test-timezone',
        },
      },
      {
        path: 'screening',
        component: OneilScreeningPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'screening',
        },
      },
      {
        path: 'screen',
        component: OneilScreenPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'screen',
        },
      },
      {
        path: 'basket',
        component: OneilBasketPageComponent,
        canActivate: [AllowedGuard],
        canDeactivate: [LeavePageGuard],
        data: {
          pageIdentify: 'basket',
        },
      },
      {
        path: 'personal',
        component: OneilPersonalPageComponent,
        canActivate: [AllowedGuard],
        data: {
          pageIdentify: 'personal',
        },
      },
      {
        path: 'payment',
        component: OneilPaymentPageComponent,
        canActivate: [AllowedGuard],
        canDeactivate: [LeavePageGuard],
        data: {
          pageIdentify: 'payment',
        },
      },
      {
        path: 'order/summary',
        component: OneilOrderSummaryPageComponent,
        data: {
          pageIdentify: 'summary',
        },
      },
      {
        path: 'refund',
        component: OneilRefundPageComponent,
        data: {
          pageIdentify: 'refund',
        },
      },
      {
        path: 'user/:displaymode/:token',
        component: OneilUserRegisterComponent,
        data: {
          pageIdentify: 'user',
        },
      },
      {
        path: 'user/:displaymode',
        component: OneilUserRegisterComponent,
        data: {
          pageIdentify: 'user',
        },
      },
      {
        path: 'user',
        canActivate: [LoggedGuard],
        component: OneilUserPageComponent,
        data: {
          pageIdentify: 'user',
        },
      },
      {
        path: 'gc',
        component: OneilGiftCardsPageComponent,
      },
      {
        path: 'error/:id',
        component: OneilMessagePageComponent,
        canActivate: [ErrorGuard],
        data: {
          pageIdentify: 'error',
        },
      },
      {
        path: '**',
        redirectTo: 'error/404',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: true,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
