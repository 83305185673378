import { forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { storageKey } from '../../app.const';
import { CardDataProvider } from '../data-provider/card.data-provider';
import { OrderDataProvider } from '../data-provider/order.data-provider';
import { OrderStateModel } from '../model/state/order.state.model';
import { OrderViewModel } from '../model/view-model/order/order.view.model';
import { TranslationService } from '../service/translation.service';
import { XOR } from '../tool/number/number';
import { CardHelper } from './card.helper';
import { AuthStateService } from '../state/auth.state.service';
import { OrderStateService } from '../state/order.state.service';
import { StateService } from '../state/state.service';
import { OrderApiModel } from '../model/api-model/order/order.api.model';

@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  private orderState: OrderStateModel;
  private orderStateSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private cardDataProvider: CardDataProvider,
    private orderDataProvider: OrderDataProvider,
    private cardHelper: CardHelper,
    private translationService: TranslationService,
    private authStateService: AuthStateService,
    private orderStateService: OrderStateService,
    private stateService: StateService
  ) {
    this.orderStateSubscription = this.orderStateService.state$.subscribe((orderState) => {
      this.orderState = orderState;
    });
  }

  public updateOrderMembershipCard(): Observable<OrderViewModel> {
    if (this.authStateService.userIsLoggedAndTokenIsValid()) {
      return this.cardHelper.findMembership(this.orderState.cinemaId).pipe(
        mergeMap((membership) => {
          const orderExist = this.orderState?.orderId ?? null;

          if (orderExist === null) {
            this.stateService.removeItem(storageKey.orderAgreements);
          }

          if (this.orderState.order && membership?.card) {
            return this.cardDataProvider.assignCardToOrder(this.orderState.cinemaId, this.orderState.order.id, membership.card.id);
          } else {
            return of(this.orderState?.order);
          }
        })
      );
    }

    return of(this.orderState?.order);
  }

  public createOrderIfNeeded(
    orderState: OrderStateModel,
    callback: (orderModel: OrderViewModel) => Observable<OrderViewModel>,
    assignMembership: boolean = false,
    isRewardsOperation: boolean = false
  ): Observable<OrderViewModel> {
    const orderExist = orderState?.orderId ?? null;

    if (orderExist === null) {
      this.stateService.removeItem(storageKey.orderAgreements);
    }

    return forkJoin({
      membership: iif(
        () => this.authStateService.userIsLoggedAndTokenIsValid() && assignMembership && orderExist === null,
        this.cardHelper.findMembership(orderState.cinemaId),
        of(null)
      ),
      order: iif(
        () => orderExist === null,
        this.orderDataProvider.create(orderState.cinemaId).pipe(map((res) => new OrderViewModel(res))),
        of(orderState.order)
      ),
    }).pipe(
      mergeMap((res) => {
        if (res.membership && res.membership.card) {
          return this.cardDataProvider.assignCardToOrder(orderState.cinemaId, res.order.id, res.membership.card.id);
        }

        return of(res.order);
      }),
      mergeMap((order) => {
        if (order.getCount() > 0 && XOR(isRewardsOperation, order.isRewardsTransaction)) {
          return throwError({ message: this.translationService.instant('rewards.combineTransaction'), order: order });
        }

        if (callback) {
          return callback(order).pipe(catchError((error) => throwError({ message: error.error.error.message, order: order })));
        }

        return of(null);
      })
    );
  }

  createIfNeeded(): Observable<OrderViewModel> {
    if (!this.orderState.order) {
      return forkJoin({
        order: this.orderDataProvider.create(this.orderState.cinema.id).pipe(map((x) => new OrderViewModel(x))),
        card: this.authStateService.userIsLoggedAndTokenIsValid() ? this.cardHelper.findCard(this.orderState.cinema.id) : of(null),
      }).pipe(
        switchMap((res) => {
          return res.card?.card ? this.cardDataProvider.assignCardToOrder(this.orderState.cinema.id, res.order.id, res.card?.card?.id) : of(res.order);
        })
      );
    } else {
      return of(this.orderState.order);
    }
  }

  update(cinemaId: string, order: OrderApiModel): Observable<OrderViewModel> {
    console.log('#update in helper');
    return forkJoin({
      order: this.orderDataProvider.update(cinemaId, order),
      card: this.authStateService.userIsLoggedAndTokenIsValid() ? this.cardHelper.findCard(this.orderState.cinema.id) : of(null),
    }).pipe(
      switchMap((res) => {
        return res.card?.card ? this.cardDataProvider.assignCardToOrder(this.orderState.cinema.id, res.order.id, res.card?.card?.id) : of(res.order);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.orderStateSubscription !== Subscription.EMPTY) {
      this.orderStateSubscription.unsubscribe();
      this.orderStateSubscription = Subscription.EMPTY;
    }
  }
}
