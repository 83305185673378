import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  environment: any;

  constructor(
    @Inject(LOCALE_ID) private _localeId: string,
    @Inject(ENVIRONMENT_TOKEN) private env: any
  ) {
    this.environment = env;
  }

  transform(
    value: number,
    currencyCode: string = this.environment.constants.currency,
    digitsInfo?: string,
  ): string | null {
    return formatCurrency(
      value,
      this._localeId,
      getCurrencySymbol(currencyCode, 'wide') + ' ',
      currencyCode,
      digitsInfo,
    );
  }
}
