export enum CmsNavigateEnum {
  movie = 'movie',
  event = 'event',
  screen = 'screen',
  cinema = 'cinema',
  screenings = 'screenings',
  userCart = 'userCart',
  reservation = 'reservation',
  watchlist = 'watchlist',
  slug = 'slug',
  rewards = 'rewards',
  orderStatus = 'orderStatus',
  club = 'club',
  membership = 'membership',
  myAccount = 'myAccount',
  signUpLearnMore = 'signUpLearnMore'
}
