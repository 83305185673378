import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {instanceToPlain, plainToInstance} from 'class-transformer';
import { map, tap } from 'rxjs/operators';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { ReservationApiModel } from '../model/api-model/reservation/reservation.api.model';
import { ReservationConfirmRequestModel, ReservationRequestModel, ReservationToOrderRequestModel } from '../model/api-model/reservation/reservation.request.model';
import { OrderApiModel } from '../model/api-model/order/order.api.model';
import { OrderHttpService } from './order.http.service';
import { UserHttpService } from './user.http.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(
    private http: HttpClient,
  ) {
  }

  @Cacheable(
    {
      storageStrategy: LocalStorageStrategy,
      cacheBusterObserver: ReservationHttpService.cacheBuster$,
      cacheModifier: ReservationHttpService.cacheModifier$
    }
  )
  findById(reservationId: string, cinemaId:string): Observable<ReservationApiModel> {
    return this.http.get<ReservationApiModel>(`/cinema/${cinemaId}/reservation/${reservationId}`);
  }

  create(cinemaId:string, requestModel: ReservationRequestModel) {
    return this.http.post<ReservationApiModel>(`/cinema/${cinemaId}/reservation`, instanceToPlain(requestModel))
      .pipe(
        tap(() => ReservationHttpService.cacheBuster$.next()),
        map(res => {
          return plainToInstance(ReservationApiModel, res as Object, {strategy: 'excludeAll'});
        })
      );
  }

  confirm(reservationId: string, cinemaId:string, requestModel: ReservationConfirmRequestModel) {
    return this.http.post<ReservationApiModel>(`/cinema/${cinemaId}/reservation/${reservationId}/confirm`, instanceToPlain(requestModel))
      .pipe(
        map(res => {
          return plainToInstance(ReservationApiModel, res as Object, {strategy: 'excludeAll'});
        })
      );
  }

  patchSeat(reservationId: string, cinemaId:string, requestModel: ReservationRequestModel) {
    return this.http.patch<ReservationApiModel>(`/cinema/${cinemaId}/reservation/${reservationId}/seat`, instanceToPlain(requestModel)).pipe(
      tap(console.log),
      tap(res => ReservationHttpService.cacheModify(res.id, res))
    );
  }

  delete(reservationId: string, cinemaId:string) {
    return this.http.delete(`/cinema/${cinemaId}/reservation/${reservationId}`)
      .pipe(
        tap(() => {
          UserHttpService.cacheBuster$.next(),
          ReservationHttpService.cacheBuster$.next()
        })
      );
  }

  addReservationToOrder(orderId:string, cinemaId: string, requestModel: ReservationToOrderRequestModel) {
    return this.http.post<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/reservation`, instanceToPlain(requestModel))
    .pipe(
      tap(res => OrderHttpService.cacheModify(res.id, res)),
      map(res => {
        return plainToInstance(OrderApiModel, res as Object, {strategy: 'excludeAll'});
      })
    );
  }

  private static cacheModify(key: string, responseData: Object): void {
    ReservationHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find(p => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response,
        {
          ...responseData
        });

      return data;
    });
  }
}
