import { ComponentFactory, ComponentFactoryResolver, Injectable } from '@angular/core';
import { ProviderEnum } from 'libs/core/src/lib/enum/provider.enum';
import { BasysComponent } from '../provider/basys/basys.component';
import { BlueWhaleComponent } from '../provider/blue-whale/blue-whale.components';
import { FacebookComponent } from '../provider/facebook/facebook.component';
import { GtagComponent } from '../provider/gtag/gtag.component';
import { GtmComponent } from '../provider/gtm/gtm.component';
import { PayuComponent } from '../provider/payu/payu.component';

import { TagProviderComponentInterface } from '../provider/tag-provider.component.interface';

@Injectable({
  providedIn: 'root',
})
export class ProviderComponentFactory {
  public constructor(private resolver: ComponentFactoryResolver) {}

  public getFactory(providerType: string): ComponentFactory<TagProviderComponentInterface> {
    if (providerType === ProviderEnum.GTAG) {
      return this.resolver.resolveComponentFactory(GtagComponent);
    }

    if (providerType === ProviderEnum.GTM) {
      return this.resolver.resolveComponentFactory(GtmComponent);
    }

    if (providerType === ProviderEnum.FACEBOOK) {
      return this.resolver.resolveComponentFactory(FacebookComponent);
    }

    if (providerType === ProviderEnum.PAYU) {
      return this.resolver.resolveComponentFactory(PayuComponent);
    }

    if (providerType === ProviderEnum.BASYS) {
      return this.resolver.resolveComponentFactory(BasysComponent);
    }

    if (providerType === ProviderEnum.BLUE_WHALE) {
      return this.resolver.resolveComponentFactory(BlueWhaleComponent);
    }

    throw new Error('Unknown provider type ' + providerType);
  }
}
