import { Component } from '@angular/core';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { ScreeningListComponent } from 'libs/shared/src/lib/component/screening/list/list.component';

@Component({
  selector: 'app-screening-list',
  templateUrl: './list.component.html'
})
export class OneilScreeningListComponent extends ScreeningListComponent {
  constructor(protected loadingService: LoadingService) {
    super(loadingService)
  }
}
