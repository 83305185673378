import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ui-radio-button',
  templateUrl: './radio-button.component.html',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioButtonComponent), multi: true }],
})
export class RadioButtonComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('inputElement', { static: true })
  public radioElement: ElementRef;

  @Input() label: string | null = null;
  @Input() name: string | null = null;
  @Input() id: string | null = null;
  @Input() value: any = null;

  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  public isDisabled = false;

  public isChecked = false;

  constructor(private renderer: Renderer2) {}

  public ngOnInit() {
    this.updateAttributes();
  }
  public ngOnChanges(): void {
    this.updateAttributes();
  }

  public onChange = (value: any) => {};

  public onTouched = () => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.value = obj;
    this.onChange(obj);
  }

  public onClick(event: Event): void {
    this.isChecked = true;

    this.renderer.setAttribute(this.radioElement.nativeElement, 'checked', 'checked');
  }

  private updateAttributes(): void {
    if (!this.radioElement) {
      return;
    }

    if (this.name) {
      this.renderer.setAttribute(this.radioElement.nativeElement, 'name', this.name);
    } else {
      this.renderer.removeAttribute(this.radioElement.nativeElement, 'name');
    }

    if (this.id) {
      this.renderer.setAttribute(this.radioElement.nativeElement, 'id', this.id);
    } else {
      this.renderer.removeAttribute(this.radioElement.nativeElement, 'id');
    }

    this.renderer.setAttribute(this.radioElement.nativeElement, 'value', this.value);
    this.writeValue(this.value);
  }
}
