<div class="row justify-content-center" [smooth-in]="loadingService.loadingStatus && loadingService.loadingStatus
     !=='pending'">
  <div class="col-12 col-sm-10 col-md-8 col-lg-6">
    <h1>PERSONAL DETAILS</h1>

    <form [formGroup]="profileForm" (submit)="onSubmit()">
      <div class="form-group w-100">
        <div class="d-flex align-items-center">
          <label for="gender" class="col-form-label pl-0">{{'user.user-my-profile.form.gender' | translate}}</label>
          <div class="col-9 pr-0 ml-auto">
            <app-dropdown [dropdownItemTemplate]="dropdownItemTemplate" formControlName="gender"
                          [ngModel]="user?.gender" (optSelect)="onSelect($event, 'id')"
                          [options]="genderService.list()">
            </app-dropdown>
          </div>
        </div>
      </div>

      <div class="form-group required w-100">
        <div class="d-flex align-items-center">
          <label for="firstName"
                 class="col-form-label pl-0">{{ "user.user-my-profile.form.firstName" | translate }}</label>
          <div class="col-9 pr-0 ml-auto">
            <input type="text" class="form-control" id="firstName" formControlName="firstName" letterOnly />
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="profileForm.get('firstName').touched && profileForm.get('firstName').invalid">
          <p *ngIf="profileForm.get('firstName')?.errors?.required">
            {{ "errors.10015" | translate }}
          </p>
        </div>
      </div>

      <div class="form-group required w-100">
        <div class="d-flex align-items-center">
          <label for="lastName"
                 class="col-form-label pl-0">{{ "user.user-my-profile.form.lastName" | translate }}</label>
          <div class="col-9 pr-0 ml-auto">
            <input type="text" class="form-control" id="lastName" formControlName="lastName" letterOnly />
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="profileForm.get('lastName').touched && profileForm.get('lastName').invalid">
          <p *ngIf="profileForm.get('lastName')?.errors?.required">
            {{ "errors.10015" | translate }}
          </p>
        </div>
      </div>

      <div class="form-group required w-100">
        <div class="d-flex align-items-center">
          <label for="email" class="col-form-label pl-0">{{ "user.user-my-profile.form.email" | translate }}</label>
          <div class="col-9 pr-0 ml-auto">
            <input type="text" class="form-control" id="email" formControlName="email" letterOnly />
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="profileForm.get('email').touched && profileForm.get('email').invalid">
          <p *ngIf="profileForm.get('email')?.errors?.required">
            {{ "errors.10015" | translate }}
          </p>
          <p *ngIf="profileForm.get('email')?.errors?.pattern">
            {{ "errors.10102" | translate }}
          </p>
        </div>
      </div>

      <div class="form-group w-100">
        <div class="d-flex align-items-center">
          <label for="phone" class="col-form-label pl-0">{{ "user.user-my-profile.form.phone" | translate }}</label>
          <div class="col-9 pr-0 ml-auto">
            <input matInput mask="000-000-0000" type="text" class="form-control" id="phone" formControlName="phone"
                   [showMaskTyped]="true" />
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="profileForm.get('phone').touched && profileForm.get('phone').invalid">
          <p *ngIf="profileForm.get('phone')?.errors?.pattern">
            {{ "errors.10103" | translate }}
          </p>
        </div>
      </div>

      <div class="form-group w-100">
        <div class="d-flex align-items-center">
          <label for="birthday"
                 class="col-form-label pl-0">{{ "user.user-my-profile.form.birthday" | translate }}</label>
          <div class="col-9 pr-0 ml-auto">
            <app-date formControlName="birthday" [disabledFutureDate]="true"
                      [hasError]="profileForm.get('birthday').touched && profileForm.get('birthday').invalid"></app-date>
          </div>
        </div>
        <div class="help-block with-errors col-12 text-right"
             *ngIf="profileForm.get('birthday').touched && profileForm.get('birthday').invalid">
          <p *ngIf="profileForm.get('birthday')?.errors?.invalidDate">
            {{'errors.10105' | translate }}
          </p>
        </div>
      </div>

      <h1 class="mt-5">MY PREFERENCES</h1>

      <h3 class="my-3">Preferred Sites</h3>
      <app-checklist [items]="favouriteCinemas" formControlName="favouriteCinemas"></app-checklist>

      <h3 class="my-3">Preferred Genres</h3>
      <app-checklist [items]="favouriteGenres" formControlName="favouriteGenres"></app-checklist>

      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-next mt-4" [disabled]="profileForm.invalid || !profileForm.touched">
          <span>{{ "user.user-my-profile.form.save" | translate }}</span>
        </button>
      </div>
    </form>

  </div>
</div>

<ng-template #dropdownItemTemplate let-option="option">
  {{option.name}}
</ng-template>