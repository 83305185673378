import { Pipe, PipeTransform } from '@angular/core';

export enum TimeParseFormatIn {
  minutes = 'M',
  seconds = 'S',
}

@Pipe({
  name: 'timeParse',
})
export class TimeParsePipe implements PipeTransform {
  transform(value: number, ...args): string {
    if (!value) {
      return '';
    }

    value = Math.floor(value);

    const formatIn = args[0];
    const formatOut = args[1];
    let result: string = null;

    if (formatIn === 'M') {
      value = value * 60;
    }

    let param1 = 0;
    let param2 = 0;

    switch (formatOut) {
      case 'MM:SS':
        const minutes = Math.floor(value / 60);
        const seconds = value - minutes * 60;
        result = String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
        break;
      case 'HH:MM':
        [param1, param2] = this.getHoursAndMinutes(value);

        result = String(param1).padStart(2, '0') + ':' + String(param2).padStart(2, '0');
        break;
      case 'HH h MM m':
        [param1, param2] = this.getHoursAndMinutes(value);

        result = String(param1).padStart(1, '0') + 'h ' + String(param2).padStart(2, ' 0') + 'min';
        break;
      default:
        result = String(value);
        break;
    }

    return result;
  }

  private getHoursAndMinutes(value: number): Array<number> {
    const hours: number = Math.floor(value / 60 / 60);
    const minutes: number = (value - hours * 60 * 60) / 60;

    return [hours, minutes];
  }
}
