import { AfterViewChecked, Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { StepsService } from 'libs/core/src/lib/service/steps.service';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { appProjectName } from 'libs/core/src/app.const';
import { FlowType } from 'libs/core/src/lib/model/component/steps/flow-type.model';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  template: '',
})
export class StepsComponent implements OnInit, AfterViewChecked {
  flowTypeEnum: typeof FlowType = FlowType;

  @Output() stepClick = new EventEmitter<any>();
  @Input() step: number = null;
  public isVoucherSale = false;
  public cateringSaleEnabled: boolean;

  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected translator: TranslateService,
    protected titleService: Title,
    protected stepsService: StepsService,
    protected cateringStateService: CateringStateService,
    protected appService: AppService
  ) {
    this.cateringStateService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.cateringSaleEnabled = value;
    });
  }

  ngOnInit() {
    this.cateringStateService.checkAvailability();
  }

  ngAfterViewChecked() {
    let prefix = '';
    let t = 'label';

    if (this.stepsService.FlowType !== FlowType.Standard && this.step === 1) {
      t = 'select_' + this.stepsService.FlowType;
    }

    if (this.environment.steps && this.environment.steps.prefixes) {
      for (const prefixName of Object.keys(this.environment.steps.prefixes)) {
        if (this.environment.steps.prefixes[prefixName]({ env: this.environment })) {
          prefix = 'prefixes.' + prefixName + '.';
          break;
        }
      }
    }

    const title: string =
      this.environment.metadata.title +
      ' - ' +
      this.translator.instant(this.getTranslationSection() + '.' + prefix + this.addCateringOffset().toString() + '.' + t);
    this.titleService.setTitle(title);
  }

  getTranslationSection() {
    if (this.stepsService.FlowType === FlowType.GiftCard) {
      return 'stepsGC';
    }

    return 'steps';
  }

  click(event: any) {
    this.stepClick.emit();
  }

  protected addCateringOffset(): number {
    let stepTemp = this.step;

    return !this.appService.isProject(appProjectName.HELIOS) && !this.cateringSaleEnabled && stepTemp !== 1 ? ++stepTemp : stepTemp;
  }

  back(event) {
    window.history.back();
  }

  canShowStepBack(index: number) {
    return index === (this.step || 0) - 1;
  }

  getSteps() {
    let temp = [1, 2, 3, 4];

    if (this.stepsService.FlowType === FlowType.Tickets) {
      temp.splice(0, 1);
    }

    if (this.stepsService.FlowType === FlowType.GiftCard) {
      temp = [1, 2, 3];
    }

    return temp;
  }
}
