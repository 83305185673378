import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { storageKey } from 'libs/core/src/app.const';
import { BasketPageModel } from 'libs/core/src/lib/model/page/basket/basket.page.model';
import { OrderViewModel } from 'libs/core/src/lib/model/view-model/order/order.view.model';
import { ScreeningItemViewModel } from 'libs/core/src/lib/model/view-model/order/screening-item/screening-item.view.model';
import { SeatTranslatorService } from 'libs/core/src/lib/service/seat/seat-translator.service';
import { TotalizerService } from 'libs/core/src/lib/service/totalizer.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { BasketFeeComponentInterface } from '../basket-fee/basket-fee.component';
import { ChangeTicketEvent } from './event/change-ticket.event';
import { TicketViewModel } from 'libs/core/src/lib/model/view-model/shared/ticket/ticket.view.model';
import { SeatViewModel } from 'libs/core/src/lib/model/view-model/screen/seat/seat.view.model';
import { StateService } from 'libs/core/src/lib/state/state.service';

@Component({
  template: '',
})
export abstract class BasketListComponent implements OnInit {
  @Input() public basketPageModel: BasketPageModel;
  @Input() public orderModel: OrderViewModel;
  @Input() public vouchers: string[];
  @Output() public changeEvent: EventEmitter<ChangeTicketEvent> = new EventEmitter<ChangeTicketEvent>();
  @Output() public deleteVoucherEvent = new EventEmitter<string>();
  @ViewChild('basketFeeComponent', { static: true }) private basketFeeComponent: BasketFeeComponentInterface;
  @Output() public deleteSingleVoucherItemEvent = new EventEmitter();

  public total = 0;
  public seatArray = [];
  private skippingRomanDigitsInRows = false;
  private skippingRomanDigitsInCols = false;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, protected totalizerService: TotalizerService, protected stateService: StateService) {}

  public ngOnInit() {
    this.recalculateTotal();
    const moviePosterPlaceholder = make_url(this.environment, this.environment.constants.moviePosterPlaceholder);
    this.basketPageModel.movie.posters = [this.basketPageModel.movie.posters[0]] || [moviePosterPlaceholder];
    const poster = this.basketPageModel.movie.posters[0];

    if (poster === undefined || poster === null || poster === '') {
      this.basketPageModel.movie.posters[0] = moviePosterPlaceholder;
    }

    const seats = this.basketPageModel.screen.seats;
    this.skippingRomanDigitsInRows = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.rowNumber));
    this.skippingRomanDigitsInCols = SeatTranslatorService.isNotRomanDigit(seats.map((elem) => elem.legendCol));

    this.setSeats(this.skippingRomanDigitsInRows, this.skippingRomanDigitsInCols);
    this.afterOnInit();
  }

  public abstract afterOnInit();

  public onTicketChange(ticketId, seatId) {
    this.stateService.setItem(storageKey.isExtraFeesSelected, 'false');
    const changeEvent = new ChangeTicketEvent(seatId, ticketId);
    this.recalculateTotal();
    this.changeEvent.emit(changeEvent);
  }

  setSeats(skippingRomanDigitsInRows: boolean, skippingRomanDigitsInCols: boolean) {
    this.orderModel.screeningItems.forEach((item) => {
      const seat: SeatViewModel = this.basketPageModel.screen.findSeatById(item.seatId);
      if (seat) {
        seat.translateRow = SeatTranslatorService.translateValue(seat.rowNumber, skippingRomanDigitsInRows);
        seat.translateCol = SeatTranslatorService.translateValue(seat.legendCol, skippingRomanDigitsInCols);
        this.seatArray.push(seat);
      }
    });

    this.seatArray = orderBy(this.seatArray, ['translateRow', 'translateCol']);
    return this.seatArray;
  }

  public getOrderItem(seatId): ScreeningItemViewModel {
    return this.orderModel.screeningItems.find((c) => c.seatId === seatId);
  }

  public deleteVoucher(number: string = null): void {
    this.deleteVoucherEvent.emit(number);
  }

  public recalculateTotal() {
    this.total = this.orderModel.screeningItems.reduce((acc, curr) => {
      const ticket = this.basketPageModel.findTicketByScreeningItemIdAndTicketId(curr.id, curr.ticketId);
      if (ticket) {
        acc += ticket.priceWithExtraFee;
      }
      return acc;
    }, 0);

    this.totalizerService.setTotalPrice(this.total);

    if (this.basketFeeComponent) {
      const bf = this.basketFeeComponent.getTotal();
      this.total += bf;
    }
  }

  public onTicketSelect(ticket: TicketViewModel, item: ScreeningItemViewModel) {
    if (item.hasVoucher()) {
      this.deleteSingleVoucher(item);
    } else {
      this.onTicketChange(ticket.id, item.seatId);
    }
  }
  public deleteSingleVoucher(item: ScreeningItemViewModel) {
    this.deleteSingleVoucherItemEvent.emit(item);
  }

  public selectedTicketsHaveOptionalFees(): boolean {
    return this.basketPageModel && this.basketPageModel.tickets.some((x) => x.extraFees.some((y) => y.isOptional));
  }
}
