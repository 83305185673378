<div class="row">
    <div class="d-flex col-7 col-md-5 col-lg-7">
        <div class="form-group required m-0 w-100">
            <input type="text" class="form-control w-100 h-100" [disabled]="formLocked" [(ngModel)]="value"
                   (ngModelChange)="onChange($event)" [placeholder]="'gift-card.input.label' | translate" />
        </div>
    </div>
    <div class="col-5">
        <button class="btn btn-next w-100" [disabled]="formLocked"
                (click)="submit()">{{ 'gift-card.input.button' | translate }}</button>
    </div>
    <div class="col-12 mt-2 errors" *ngIf="errors && errors.length > 0">
        <div class="error m-0" *ngFor="let error of errors">
            {{ ('gift-card.input.error.' + error) | translate }}
        </div>
    </div>
</div>