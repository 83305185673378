<div class="screen-seats-container">

  <div class="header-template-container">
    <ng-content select="header"></ng-content>
  </div>

  <div *ngFor="let seatRow of screen.pseats; index as i">
    <div *ngIf="rowSeparators && rowSeparators[i] && rowSeparators[i].enabled  && rowSeparators[i].name"
         class="text-center">
      <div class="strike">
        <span>
          <span style="font-weight: bold">{{ rowSeparators[i].name }}</span> - {{env.constants.currency}} <span
                style="font-weight: bold">{{ rowSeparators[i].price }}</span>
        </span>
      </div>
    </div>

    <div class="screen-row">
      <div class="screen-placeholder row-legend">
        <span class="row-symbol">{{screen.rowSymbols[i]}}</span>
      </div>
      <ng-container *ngFor="let seat of seatRow; index as j">
        <div *ngIf="seat.screenElements.length" class="screen-placeholder"
             [ngClass]="['size-placeholder-'+seat.getGroupConnectedSeatsLength(), seat.transformScreenElementsToString()]">
        </div>
        <div *ngIf="!seat.screenElements.length" class="screen-placeholder"
             [ngClass]="[seat.transformGroupTypesToString(),'size-placeholder-'+seat.getGroupConnectedSeatsLength()]">
          <app-screen-seats-seat *ngIf="!seat.IsMultiSeat && seat.occupied !== occupiedStatus.Locked" [seat]="seat"
                                 [selectedSeatIds]="seatIds" [seatsLimit]="limit"
                                 (seatClickEvent)='onSeatClick($event)'>
          </app-screen-seats-seat>
          <app-screen-seats-multiseat *ngIf="seat.IsMultiSeat && seat.occupied !== occupiedStatus.Locked" [seat]="seat"
                                      [selectedSeatIds]="seatIds" [seatsLimit]="limit" [seats]="screen.seats"
                                      (seatClickEvent)='onSeatClick($event)'></app-screen-seats-multiseat>
        </div>
      </ng-container>
      <div class="screen-placeholder row-legend">
        <span class="row-symbol">{{screen.rowSymbols[i]}}</span>
      </div>
    </div>

  </div>

  <div class="footer-template-container mt-3">
    <div class="back-row">
      {{ 'screen.backrow' | translate | uppercase }}
    </div>
  </div>

</div>