import { OccupiedStatus } from 'libs/core/src/lib/enum/occupied-status.enum';
import { SeatGroupDisplayMode } from 'libs/core/src/lib/enum/seat-group-display-mode.enum';
import { SeatGroupSalesMode } from 'libs/core/src/lib/enum/seat-group-sales-mode.enum';
import { ISeatModel } from 'libs/core/src/lib/interfaces';
import { AbstractViewModel } from '../../../abstract.view.model';
import { SeatApiModel } from '../../../api-model/screen/seat/seat.api.model';
import { SeatGroupViewModel } from './seat-group.view.model';

export class SeatViewModel extends AbstractViewModel<SeatApiModel> implements ISeatModel {
  id: string;
  symbol: string;
  kind: string;
  colId: string;
  rowId: string;
  groupId: string;
  wheelchairSeat: boolean;

  rowNumber: string;
  isPlaceholder: boolean;
  groupColor: string;
  rowSymbol: string;
  colNumber: string;
  legendCol: string;
  translateRow: number;
  translateCol: number;
  occupied: OccupiedStatus = OccupiedStatus.Free;
  groupConnectedSeats: Array<SeatViewModel>;
  groupTypes: Array<string> = ['none'];
  groups: SeatGroupViewModel[];
  groupDescriptionCollection: Array<string> = [];
  defaultGroupDescription: string | null;
  defaultSeatGroupName: string | null;
  romanToArabicRow: number | null;
  romanOrArabicCol: number | null;
  rowNumberNumerable: number | null;
  screenElements: string[] = [];

  connectSeat: string = '';

  constructor(protected apiModel: SeatApiModel = new SeatApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.symbol = this.legendCol = this.apiModel.symbol;
    this.kind = this.apiModel.kind;
    this.colId = this.apiModel.colId;
    this.rowId = this.apiModel.rowId;
    this.groupId = this.apiModel.groupId;
    this.wheelchairSeat = this.apiModel.wheelchairSeat;
  }

  toApiModel(): SeatApiModel {
    return undefined;
  }

  toString() {
    return this.symbol;
  }

  get IsMultiSeat(): boolean {
    return (
      this.groupTypes.indexOf('couch') >= 0 ||
      this.groupTypes.indexOf('lounge') >= 0 ||
      this.groups?.some((s) => s.seatIds.length > 1 && s.salesMode === SeatGroupSalesMode.Together)
    );
  }

  public getGroupConnectedSeatsLength() {
    return (this.groupConnectedSeats?.length || 0) + 1;
  }

  public hasAssignedGroupType(groupType: string): boolean {
    for (let type of this.groupTypes) {
      type = type.trim();
      if (type.toLocaleLowerCase() === groupType.toLocaleLowerCase()) {
        return true;
      }
    }
    return false;
  }

  public transformGroupTypesToString(): string {
    return this.groupTypes.join(' ');
  }
  public transformScreenElementsToString(): string {
    return this.screenElements.join(' ');
  }
}
