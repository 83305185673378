<div class="book-tickets">

  <div class="book-tickets-top">
    <div class="book-tickets-top-label">{{"ticketssummary.seat"| translate}}</div>
    <div class="book-tickets-top-field">{{"ticketssummary.ticketType"| translate}}</div>
  </div>

  <div *ngIf="orderModel.hasVoucher()" class="bg-white">
    <div class="row mx-4 px-0 py-3 pt-sm-0 pb-sm-3 voucher-container" style="gap: 0.5rem">
      <ng-container *ngFor="let voucher of orderModel?.getVouchers()">
        <div class="col-12 voucher-box">
          <span class="voucher-icon"></span>
          <div class="d-flex flex-wrap justify-content-between px-2" style="flex-grow: 1;">
            <span class="font-weight-normal voucher-name">{{ voucher.name }}</span>
            <span class="font-weight-normal voucher-name"> {{ voucher.number }}</span>
          </div>
          <span class="remove-icon" (click)="deleteVoucher(voucher.number)"></span>
        </div>
      </ng-container>
    </div>
  </div>

  <hr class="thin">

  <div class="book-tickets-list mt-2">
    <div class="d-flex mb-2 book-tickets-list-item" *ngFor="let seat of seatArray">
      <div class="my-auto col-5 seat-column p-0 book-tickets-top-label label">
        <ng-container *ngIf="seat != null">
          <span>{{"ticketssummary.row"| translate}}:&nbsp;{{ seat.rowNumber }},
            {{"ticketssummary.seat"| translate}}:&nbsp;{{ seat.symbol }}</span>
        </ng-container>
      </div>
      <div class="col-7 dropdown-container px-0" *ngIf="seat !== null">
        <ng-container *ngVar="getOrderItem(seat.id) let item">
          <app-dropdown [ngModel]="item.ticketId" [isReadOnly]="item.hasVoucher()"
                        (optSelect)="onTicketSelect($event, item)"
                        [options]="basketPageModel.findTicketsByScreeningItemIdAndApplyVoucherChanges(item)|sort:'price'">
          </app-dropdown>
        </ng-container>
      </div>
      <hr class="thin">
    </div>
  </div>

</div>