import { Component } from '@angular/core';
import { ModalComponent } from 'libs/shared/src/lib/component/modal/modal.component';
import { OneilRefundFailModalComponent } from './refund/refund-fail/refund-fail.component';
import { OneilRefundSuccessModalComponent } from './refund/refund-success/refund-success.component';
import { OneilRefundModalComponent } from './refund/refund/refund.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class OneilModalComponent extends ModalComponent {
  templates = {
    refundSuccess: OneilRefundSuccessModalComponent,
    refundFail: OneilRefundFailModalComponent,
    refundSelector: OneilRefundModalComponent,
  };
}
