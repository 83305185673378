import { Expose, Type } from 'class-transformer';
import { UserPaymentApiModel } from './user-payment.api.model';

export class UserOrderApiModel {
  @Expose()
  id: string;

  @Expose()
  bookingId: string;

  @Expose()
  reservationId: string;

  @Expose()
  saleDate: string;

  @Expose()
  @Type(() => UserPaymentApiModel)
  payments: UserPaymentApiModel[];

  @Expose()
  totalValue: number;

  @Expose()
  totalNetValue: number;

  @Expose()
  totalTaxValue: number;

  @Expose()
  orderNumber: string;

  @Expose()
  pointsAffected: number;
}
