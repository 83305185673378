import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { PaymentProviderConfigRequestModel } from 'libs/core/src/lib/model/request/payment-provider-config.request.model';
import { OrderStateModel } from 'libs/core/src/lib/model/state/order.state.model';
import { PaymentViewModel } from 'libs/core/src/lib/model/view-model/payment.view.model';
import { PaymentConfigViewModel } from 'libs/core/src/lib/model/view-model/payment/config/payment.config.view.model';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { ENVIRONMENT_TOKEN } from 'libs/core/src/public-api';
import { Observable, Subscription, defer, finalize, forkJoin, iif, of, switchMap, tap, throwError } from 'rxjs';
import { AbstractTagProviderComponent } from '../../../../tag-manager/provider/abstract-tag-provider.component';
import { DonePaymentProviderEvent } from '../../event/done-payment-provider.event';
import { ErrorPaymentProviderEvent } from '../../event/error-payment-provider.event';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { WorkPaymentProviderEvent } from '../../event/work-payment-provider.event';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { FiservEventModel } from './fiserv.model';
import { GoogleTagManagerService } from 'libs/core/src/lib/service/analytics-services/google-tag-manager.service';
import { PaymentMethodViewModel } from 'libs/core/src/lib/model/view-model/order/payment-method/payment-method.view.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { OrderPaymentRequestModel } from 'libs/core/src/lib/model/request/order-payment.request.model';
import { LoaderEnum } from 'libs/core/src/lib/enum/loader.enum';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';
import { RxjsHelper } from 'libs/core/src/lib/helper/rxjs.helper';
import { PaymentProviderStateEnum } from '../../model/payment-provider-status.enum';

@Component({
  selector: 'app-payment-provider-fiserv-component',
  templateUrl: './fiserv-payment-provider.component.html',
})
export class FiservPaymentProviderComponent extends AbstractTagProviderComponent implements PaymentProviderComponentInterface, OnInit, OnDestroy {
  PaymentProviderStateEnum = PaymentProviderStateEnum;
  @Output() public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'fiserv';
  public paymentProviderConfig: PaymentConfigViewModel | null;

  public ajaxTokenizerUrl: string = null;
  public fiservToken: FiservEventModel;
  public fiservEvent: FiservEventModel;
  public returnUrl: string = null;
  public paymentInitialized = false;
  public paymentProviderState: PaymentProviderStateEnum = PaymentProviderStateEnum.Initial;

  private orderState: OrderStateModel = null;
  private readonly paymentChannel: string | null = null;
  private paymentProviderInitSubscription: Subscription = Subscription.EMPTY;
  private css: string;

  @HostListener('window:message', ['$event'])
  handleKeyDown(event: any) {
    let token: FiservEventModel;

    if (this.paymentProviderState !== PaymentProviderStateEnum.InProgress) {
      this.paymentProviderState = PaymentProviderStateEnum.Initial;
    }

    try {
      token = JSON.parse(event.data) as FiservEventModel;
    } catch (e) {
      return;
    }

    if (token?.cardTyping !== undefined) {
      return;
    }

    if (token?.errorCode != '0' || token?.validationError) {
      this.fiservEvent = token;
    }

    if (token?.message && token?.expiry) {
      this.fiservToken = token;
    }
  }

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    protected renderer: Renderer2,
    @Inject(DOCUMENT) protected _document,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private googleTagManagerService: GoogleTagManagerService,
    private router: Router,
    private navigationHelperService: NavigationHelperService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private rxjsHelper: RxjsHelper
  ) {
    super(renderer, _document);
    this.paymentChannel = environment.constants.paymentChannel;
  }

  public ngOnInit(): void {
    this.orderState = this.orderStateService.getState();
    fetch('assets/css/fiserv.min.css')
      .then((response) => response.text())
      .then((css) => (this.css = css));
  }

  public ngOnDestroy(): void {
    if (this.paymentProviderInitSubscription !== Subscription.EMPTY) {
      this.paymentProviderInitSubscription.unsubscribe();
      this.paymentProviderInitSubscription = Subscription.EMPTY;
    }
  }

  private initPaymentProviderWidget(): Observable<PaymentConfigViewModel> {
    const paymentProviderConfigRequest: PaymentProviderConfigRequestModel = new PaymentProviderConfigRequestModel(
      this.orderState.cinema.id,
      this.orderState.order.id,
      this.paymentProviderIdentifier,
      this.paymentChannel
    );

    return this.orderDataProvider.getPaymentProviderConfig(paymentProviderConfigRequest).pipe(
      tap({
        next: (paymentProviderConfigModel) => {
          this.paymentProviderConfig = paymentProviderConfigModel;
          this.ajaxTokenizerUrl = paymentProviderConfigModel.providerConfiguration
            ? paymentProviderConfigModel.providerConfiguration.url_iframe +
              '&css=' +
              encodeURIComponent(this.css) +
              '&sendcardtypingevent=false' +
              '&invalidcreditcardevent=true' +
              '&invalidcvvevent=true' +
              '&invalidexpiryevent=true' +
              '&invalidinputevent=true' +
              '&unique=true' +
              '&enhancedresponse=true' +
              '$inactivityto=50' +
              '$tokenizewheninactive=true'
            : null;

          this.events.next(new DonePaymentProviderEvent());
        },
        error: (e: HttpErrorResponse) => this.events.next(new ErrorPaymentProviderEvent(e)),
      })
    );
  }

  onInitPayment(paymentMethod: PaymentMethodViewModel): Observable<any> {
    this.loadingService.showLoader(LoaderEnum.PAYMENT);
    const paymentInitModel = new PaymentPreInitModel(this.getContinueUrl());

    let g$ = !this.paymentInitialized
      ? this.onPreInitPayment(paymentInitModel).pipe(
          tap({
            next: () => {
              this.orderStateService.setPaymentMethod(paymentMethod);
              this.googleTagManagerService.addPaymentInfo(paymentMethod?.name);
              this.paymentInitialized = true;
              this.loadingService.hideLoader(LoaderEnum.PAYMENT);
            },
          })
        )
      : defer(() => {
          this.paymentProviderState = PaymentProviderStateEnum.InProgress;
          return this.rxjsHelper.executeAfter(
            200,
            () => !this.fiservToken,
            switchMap(() =>
              iif(
                () => !!this.fiservToken,
                this.orderDataProvider.getPayment(this.createPaymentRequest(paymentMethod.identifier, paymentInitModel)).pipe(
                  switchMap((paymentModel) => {
                    return forkJoin({
                      payment: of(paymentModel),
                      order: this.orderDataProvider.findById(this.orderStateService.getState()?.cinemaId, this.orderStateService.getState()?.orderId),
                    });
                  }),
                  tap({
                    next: (v) => {
                      this.orderStateService.setOrder(v.order);
                      this.onPostInitPayment(v.payment);
                    },
                    error: (e) => {
                      this.loadingService.hideLoader(LoaderEnum.PAYMENT);
                    },
                    complete: () => {
                      this.loadingService.hideLoader(LoaderEnum.PAYMENT);
                    },
                  }),
                  finalize(() => {
                    this.paymentProviderState = PaymentProviderStateEnum.Complete;
                  })
                ),
                throwError(() => {
                  this.paymentProviderState = PaymentProviderStateEnum.Error;
                  this.loadingService.hideLoader(LoaderEnum.PAYMENT);
                })
              )
            ),
            2000
          );
        });

    return g$;
  }

  onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    const preInitPaymentEvent: PaymentPreInitModel = new PaymentPreInitModel();
    return this.initPaymentProviderWidget().pipe(
      tap(() => {
        preInitPaymentEvent.continueUrl = event['continueUrl'];
      }),
      switchMap(() => {
        return new Observable<PaymentPreInitModel>((subscriber) => {
          subscriber.next(preInitPaymentEvent);
          subscriber.complete();
        });
      })
    );
  }

  onPostInitPayment(paymentModel: PaymentViewModel): void {
    window.location.href = this.getContinueUrl();
    this.events.next(new WorkPaymentProviderEvent());
  }

  onLoadIFrame() {
    this.events.next(new DonePaymentProviderEvent());
  }

  getContinueUrl(): string {
    let urlTree: string = '';

    urlTree = this.router
      .createUrlTree([this.navigationHelperService.getNextRoute(this.route.snapshot)], {
        queryParams: {
          orderId: this.orderState.order.id,
          cinemaId: this.orderState.cinema.id,
        },
      })
      .toString();

    return `${window.location.origin}/#${urlTree}`;
  }

  createPaymentRequest(paymentProviderIdentifier: string, preInitPaymentEvent: PaymentPreInitModel): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.cinemaId = this.orderState.cinema.id;
    orderPaymentRequest.orderId = this.orderState.order.id;
    orderPaymentRequest.paymentChannel = this.paymentChannel;
    orderPaymentRequest.paymentProviderIdentifier = paymentProviderIdentifier;
    orderPaymentRequest.continueUrl = preInitPaymentEvent.continueUrl;
    orderPaymentRequest.paymentData = preInitPaymentEvent.paymentData;
    orderPaymentRequest.intPayMethodType = preInitPaymentEvent.intPayMethodType;
    orderPaymentRequest.intPayMethodValue = preInitPaymentEvent.intPayMethodValue;
    orderPaymentRequest.paymentToken = this.fiservToken?.message;
    orderPaymentRequest.expiry = this.fiservToken?.expiry;
    console.log('createPaymentRequest', this.fiservToken);

    return orderPaymentRequest;
  }
}
