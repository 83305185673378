import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChoiceSliderItem } from './model/choice-slider-item';

@Component({
  selector: 'app-choice-slider',
  templateUrl: './choice-slider.component.html',
  styleUrls: ['./choice-slider.component.scss'],
})
export class ChoiceSliderComponent implements OnInit {
  @ViewChild('elements') elementsBoxRef: ElementRef;

  @Input() items: ChoiceSliderItem[] = [];
  @Input() selectedItem: ChoiceSliderItem;
  @Output() selectedItemChange: EventEmitter<ChoiceSliderItem> = new EventEmitter<ChoiceSliderItem>();

  public elementsOffset = 0;

  private readonly imageWidth = 115;

  constructor() {}

  ngOnInit(): void {}

  select(value: ChoiceSliderItem): void {
    this.selectedItem = value;
    this.selectedItemChange.emit(value);
  }

  leftArrow(): void {
    if (this.canLeft()) {
      this.elementsOffset--;
    }
  }

  rightArrow(): void {
    if (this.canRight()) {
      this.elementsOffset++;
    }
  }

  canLeft(): boolean {
    return this.items && this.elementsOffset > 0;
  }

  canRight(): boolean {
    return this.items && this.elementsOffset + this.maxElements < this.items.length;
  }

  get maxElements(): number {
    return Math.floor(this.elementsBoxWidth / this.imageWidth);
  }

  get elementsBoxWidth(): number {
    return this.elementsBoxRef ? this.elementsBoxRef.nativeElement.offsetWidth : 0;
  }
}
