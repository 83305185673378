<h4 class="expandeable" style="cursor: pointer;" (click)="isCollapsedFilmRating = !isCollapsedFilmRating">
    <i class="fas" [ngClass]="isCollapsedFilmRating ? 'fa-plus-circle mb-4' : 'fa-minus-circle mb-2'"></i>
    Film Rating Policy
</h4>
<div [collapse]="isCollapsedFilmRating">
    <table style="margin:1rem 20px 1rem 28px;font-size:12px">
        <tbody>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">G</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">General Audiences. All ages permitted.</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">PG</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Parental Guidance suggested.</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">PG-13</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Parents are strongly cautioned.</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">R</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Restricted. No one under the age of 17 is admitted
                        unless accompanied by an adult guardian 21 years of age or older. Picture ID is required for
                        anyone who appears to be under the age of 25. *No children 5 years of age or under will be
                        admitted to an "R" rated film at any time.</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef;width:50px;" valign="top">
                    NC-17</td>
                <td style="width:15px;">&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Absolutely no one under the age of 17.</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<h4 class="expandeable mb-4" style="cursor: pointer;" (click)="isCollapsedTicketAge = !isCollapsedTicketAge">
    <i class="fas" [ngClass]="isCollapsedTicketAge ? 'fa-plus-circle' : 'fa-minus-circle'"></i>
    Ticketing &amp; Age Policy
</h4>
<div [collapse]="isCollapsedTicketAge">
    <table style="margin:0 20px 0px 28px;font-size:12px">
        <tbody>
            <tr>
                <td colspan="3">
                    <p class="mb-2">Select the number and type of tickets you wish to
                        buy. Please note seats are reserved on a best available basis. You can buy a maximum of 10
                        tickets per transaction.</p>

                    <p class="mb-2">All voucher tickets will require proof of
                        identification when collecting tickets or having tickets checked by ushers.</p>

                    <p class="mb-2">Matinee shows are all shows before 5:30 PM</p>
                    <hr class="mb-2">
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef;width:50px;" valign="top">
                    Under 2</td>
                <td style="width:15px;">&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px;width:100%;">No charge. Child must share seat. Child
                        cannot take up their own seat.</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">2 - 11</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Child ticketing</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">12 - 61
                </td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Adult ticketing</p>
                </td>
            </tr>
            <tr>
                <td class="text-nowrap text-right font-weight-bold" style="color:#00aeef" valign="top">62 &amp; Up
                </td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                    <p style="margin-bottom:10px">Senior ticketing</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>