import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { getDropdownDays, getDropdownMonths, getDropdownYears } from 'libs/core/src/lib/date/date.helper';
import { DateTime } from 'luxon';

@Component({
  template: '',
})
export class DateComponent implements ControlValueAccessor, Validator {
  @Input() hasError = false;
  @Input() minimumAge: number = null;
  @Input() disabledFutureDate = true;
  date: DateTime = null;
  year: string = null;
  month: string = null;
  day: string = null;

  days = getDropdownDays(1, 31);
  months = getDropdownMonths(1, 12);
  years = getDropdownYears();

  onChange = (date) => {};
  onTouched = () => {};

  touched = false;
  disabled = false;

  private panValue(value: string | number) {
    if (typeof value === 'number') {
      value = value.toString();
    }

    return value && value.length ? value.padStart(2, '0') : '';
  }

  onYearChange(event) {
    if (!this.disabled) {
      this.year = event?.target?.value || event.id;
      this.onDateChange('y');
    }
  }

  onMonthChange(event) {
    if (!this.disabled) {
      this.month = this.panValue(event?.target?.value || event.id);
      this.onDateChange('m');
    }
  }

  onDayChange(event) {
    if (!this.disabled) {
      this.day = this.panValue(event?.target?.value || event.id);
      this.onDateChange('d');
    }
  }

  onDateChange(field: string) {
    const year = parseInt(this.year);
    const month = parseInt(this.month);
    const day = parseInt(this.day);
    if ((field === 'y' && month && day) || (field === 'm' && year && day) || (field === 'd' && year && month)) {
      this.markAsTouched();
      this.date = DateTime.utc(year, month, day);
    } else {
      this.date = null;
    }

    this.onChange(this.date);
  }

  writeValue(date: DateTime) {
    this.date = date;
    if (date) {
      this.year = date.year.toString();
      this.month = this.panValue(date.month);
      this.day = this.panValue(date.day);
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.hasError = false;
    const date = control.value as DateTime;
    const now = DateTime.utc().startOf('day');
    if (date) {
      const yearsDiff = date.diff(now, 'years').years;
      if (!date.isValid || date.year < 1900) {
        this.hasError = true;
        return { invalidDate: { date } };
      }

      if (this.minimumAge && yearsDiff > -this.minimumAge) {
        this.hasError = true;
        return { minimumAge: { requiredAge: this.minimumAge, actualAge: yearsDiff } };
      }

      if (this.disabledFutureDate && now < date) {
        this.writeValue(now);
      }
    }
  }
}
