import {Component} from '@angular/core';
import { ScreenSeatsSelectedListComponent } from 'libs/shared/src/lib/component/seats-selected-list/seats-selected-list.component';

@Component({
  selector: 'app-screen-seats-selected-list',
  templateUrl: './seats-selected-list.component.html'
})
export class OneilScreenSeatsSelectedListComponent extends ScreenSeatsSelectedListComponent {
 
}
