import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { OrderHttpService } from '../http/order.http.service';
import { SalesDocumentHttpService } from '../http/sales-document.http.service';
import { OrderAliasApiModel } from '../model/api-model/order/order-alias.api.model';
import { CanBeRefundedRequest } from '../model/request/order-refund.request.models';
import { RefundTransactionRequestModel } from '../model/request/refund-transaction-payment.request.model';
import { RefundTransactionResponseModel } from '../model/response/refund-transaction.response.model';
import { CanBeRefundViewModel } from '../model/view-model/sales-document/can-be-refund.view.model';
import { SalesDocumentPaymentViewModel } from '../model/view-model/sales-document/salesdocument-payment.view.model';
import { RefundViewModel } from '../model/view-model/sales-document/refund.view.model';

@Injectable({
  providedIn: 'root'
})
export class RefundDataProvider {
  constructor(
    private salesDocumentHttpService: SalesDocumentHttpService,
    private orderHttpService: OrderHttpService,
  ) {
  }

  public canBeRefunded(cinemaId: string, request: CanBeRefundedRequest) {
    return this.salesDocumentHttpService.canBeRefunded(cinemaId, request);
  }

  public canBeRefundedViaApiModel(cinemaId: string, request: CanBeRefundedRequest): Observable<CanBeRefundViewModel> {
    return this.salesDocumentHttpService.canBeRefundedViaApiModel(cinemaId, request).pipe(
      map(x => new CanBeRefundViewModel(x))
    );
  }

  public getSalesDocument(cinemaId: string, salesDocumentId: string, request: CanBeRefundedRequest) {
    return forkJoin([
      this.salesDocumentHttpService.getSalesDocumentPayment(cinemaId, salesDocumentId),
      this.salesDocumentHttpService.getSalesDocumentById(cinemaId, salesDocumentId),
      this.salesDocumentHttpService.canBeRefunded(cinemaId, request)
    ]).pipe(
      map(([refundTransactionPaymentResponse, salesDocumentResponseModel, canBeRefundedResponse]) => {
        return {refundTransactionPaymentResponse, salesDocumentResponseModel, canBeRefundedResponse};
      })
    );
  }

  public refund(cinemaId: string, salesDocumentId: string, request: RefundTransactionRequestModel): Observable<RefundTransactionResponseModel> {
    return this.salesDocumentHttpService.refund(cinemaId, salesDocumentId, request);
  }

  public refundViaApiModel(cinemaId: string, salesDocumentId: string, request: RefundTransactionRequestModel): Observable<RefundViewModel> {
    return this.salesDocumentHttpService.refundViaApiModel(cinemaId, salesDocumentId, request).pipe(
      map(x => new RefundViewModel(x))
    );
  }

  public getSalesDocumentPaymentViaApiModel(cinemaId: string, salesDocumentId: string): Observable<SalesDocumentPaymentViewModel[]> {
    return this.salesDocumentHttpService.getSalesDocumentPaymentViaApiModel(cinemaId, salesDocumentId).pipe(
      map(x => x.map(y => new SalesDocumentPaymentViewModel(y)))
    );
  }

  public findAliasByBookingId(bookingId: string): Observable<OrderAliasApiModel> {
    return this.orderHttpService.findAliasByBookingIdViaApiModel(bookingId);
  }

  public getSalesDocumentById(cinemaId: string, salesDocumentId: string) {
    return this.salesDocumentHttpService.getSalesDocumentById(cinemaId, salesDocumentId);
  }
}
