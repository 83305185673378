import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { EventApiModel } from '../model/api-model/event/event.api.model';

@Injectable({
  providedIn: 'root',
})
export class EventHttpService {
  public static cacheBuster$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: EventHttpService.cacheBuster$,
    maxAge: 3600000,
  })
  public listViaApiModel(cinemaId: string, eventId?: string, dateTimeFrom?: string, dateTimeTo?: string): Observable<EventApiModel[]> {
    const params = {};

    if (dateTimeFrom) {
      params['dateTimeFrom'] = dateTimeFrom;
    }

    if (dateTimeTo) {
      params['dateTimeTo'] = dateTimeTo;
    }

    return this.http.get<EventApiModel[]>(`/cinema/${cinemaId}/event${eventId ? `/${eventId}` : ''}`, { params });
  }
}
