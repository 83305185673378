import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {plainToInstance} from 'class-transformer';
import {Cacheable} from 'ts-cacheable';
import { SpeakingTypeApiModel } from '../model/api-model/speaking-type.api.model';

@Injectable({
  providedIn: 'root'
})
export class SpeakingTypeHttpService {
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  constructor(
    private http: HttpClient,
  ) {
  }

  @Cacheable()
  public list(): Observable<SpeakingTypeApiModel[]> {
    return this.http
      .get('/speakingType')
      .pipe(
        map(result => plainToInstance(SpeakingTypeApiModel, result as object[]))
      );
  }
}
