import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardPaymentService } from 'libs/core/src/lib/service/card-payment.service';
import { AuthStateService } from 'libs/core/src/lib/state/auth.state.service';

@Component({
  selector: 'app-voucher-card-input',
  templateUrl: './voucher-card-input.component.html',
  styleUrls: ['./voucher-card-input.component.scss'],
})
export class OneilVoucherCardInputComponent {
  @Output()
  public voucherAdd: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public formLocked = false;

  @Input()
  set errors(value: Array<string>) {
    this._errors = value;
    if (!value.length) {
      this.currentInput = null;
    }
  }

  get errors() {
    return this._errors;
  }

  private _errors = new Array<string>();
  public currentInput: string | null = null;

  constructor(protected authStateService: AuthStateService, protected cardPaymentService: CardPaymentService) {}

  public onValueChange(event: string): void {
    this.currentInput = event as any;
  }

  public onSubmitButtonClicked(event: Event): void {
    if (this.currentInput && !this.formLocked) {
      this.voucherAdd.emit(this.currentInput);
    }
  }
}
