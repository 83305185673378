import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { ScreenApiModel } from '../../api-model/screen/screen.api.model';
import { OccupancyModel } from '../../occupancy.model';
import { GenreViewModel } from '../genre/genre.view.model';
import { MoviePrintViewModel } from '../movie/movie-print.view.model';
import { MovieViewModel } from '../movie/movie.view.model';
import { RatingViewModel } from '../rating.view.model';
import { OccupancyViewModel } from '../screening/occupancy/occupancy.view.model';
import { ScreeningViewModel } from '../screening/screening.view.model';
import { TagGroupViewModel } from '../tag-group.view.model';
import { ScreenColViewModel } from './screen-col.view.model';
import { ScreenElementViewModel } from './screen-element.view.model';
import { ScreenRowViewModel } from './screen-row.view.model';
import { SeatBlockedViewModel } from './seat/seat-blocked.view.model';
import { SeatGroupViewModel } from './seat/seat-group.view.model';
import { SeatViewModel } from './seat/seat.view.model';

export class ScreenViewModel extends AbstractViewModel<ScreenApiModel> {
  id: string;
  name: string;
  type: string;
  feature: string;
  number: number;
  rows: ScreenRowViewModel[];
  cols: ScreenColViewModel[];
  seats: SeatViewModel[];
  groups: SeatGroupViewModel[];
  defaultSeatGroupDescription: string;
  defaultSeatGroupName: string;
  blockedList: SeatBlockedViewModel[];
  screenGroupId: string;
  ffaNumber: string;
  proCinema: string;
  screenElements: ScreenElementViewModel[];

  pseats: SeatViewModel[][];
  maxOccupancy: number;
  audience: number;
  generalAdmission: boolean;
  movieType: string;
  movieSoundType: string;
  movieName: string;
  movieId: string;
  movieDate: DateTime;
  moviePremiere: DateTime;
  movieSymbol: string;
  movieLanguage: string;
  movieSpeakingType: string;
  movieDuration: number;
  movieScreenX: string;
  movieDescription: string;
  movieSubtitles: string[] = [];
  movieGenres: GenreViewModel[] = [];
  movieTags: TagGroupViewModel[] = [];
  movieRating: RatingViewModel[] = [];
  posterUrl: string;
  pictureUrl: string;
  moviePrintType: string;
  screeningDuration: number;
  isScreenSwapping: boolean;
  screeningAvailabilityStatus: number;
  occupancy?: OccupancyModel = null;
  saleTimeTo?: DateTime = null;
  reservationTimeTo?: DateTime = null;
  screeningTimeFrom?: DateTime = null;
  screeningTimeTo?: DateTime = null;
  rowSymbols: string[];

  isEvent = false;
  movieCopyRelease: string;

  constructor(protected apiModel: ScreenApiModel = new ScreenApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.type = this.apiModel.type;
    this.feature = this.apiModel.feature;
    this.number = this.apiModel.number;
    this.rows = this.apiModel.rows?.map((model) => new ScreenRowViewModel(model));
    this.cols = this.apiModel.cols?.map((model) => new ScreenColViewModel(model));
    this.seats = this.apiModel.seats?.map((model) => new SeatViewModel(model));
    this.groups = this.apiModel.groups?.map((model) => new SeatGroupViewModel(model));
    this.defaultSeatGroupDescription = this.apiModel.defaultSeatDescription;
    this.defaultSeatGroupName = this.apiModel.defaultSeatGroupName;
    this.blockedList = this.apiModel.blockedList?.map((model) => new SeatBlockedViewModel(model));
    this.screenGroupId = this.apiModel.screenGroupId;
    this.ffaNumber = this.apiModel.ffaNumber;
    this.proCinema = this.apiModel.proCinema;
    this.screenElements = this.apiModel.screenElements?.map((model) => new ScreenElementViewModel(model));

    this.seats?.forEach((item) => {
      const sourceSeat = this.seats.find((sourceItem) => sourceItem.id === item.id);
      const row = this.rows.find((row) => sourceSeat.rowId === row.id);
      if (row) {
        item.rowNumber = row.legend;
      }
    });
  }

  toApiModel(): ScreenApiModel {
    return undefined;
  }

  toString(): string {
    return `${this.name}`;
  }

  public findSeatById(id: string): SeatViewModel {
    const seat = this.seats.filter(function (item) {
      return item.id === id;
    });

    return seat.length > 0 ? seat[0] : null;
  }

  public findSeatByIdFlat(id: string): SeatViewModel {
    const seat = this.flatSeats(this.pseats).filter(function (item) {
      return item.id === id;
    });

    return seat.length > 0 ? seat[0] : null;
  }

  private flatSeats(seats) {
    return seats.flat();
  }

  public getGenresName(separator): string {
    return this.movieGenres.map((res) => res.name).join(separator);
  }

  public getRatingByCinemaGroupId(groupId: string, property: string = 'symbol'): string {
    return this.movieRating
      .filter((res) => res.cinemaGroupId === groupId)
      .map((res) => res[property])
      .join(' ');
  }

  public makeScreenModelForMovie(
    movieCopyModel: MoviePrintViewModel,
    movie: MovieViewModel,
    screening: ScreeningViewModel,
    genres: GenreViewModel[],
    occupancy: OccupancyViewModel
  ) {
    this.movieName = movie.title;
    this.movieDate = screening.screeningTimeFrom;
    this.moviePremiere = movie.premiereDate;
    this.movieDuration = movie.duration;
    this.movieId = movie.id;
    this.posterUrl = movie.posters[0];
    this.pictureUrl = movie.pictures[0];
    this.movieDescription = movie.description;
    this.generalAdmission = screening.generalAdmission;
    this.movieGenres = genres;
    this.movieRating = movie.ratings;
    this.maxOccupancy = screening.maxOccupancy;
    this.id = screening.id;
    this.screeningDuration = screening.screeningDuration;
    this.isScreenSwapping = screening.isScreenSwapping;
    this.screeningAvailabilityStatus = screening.availabilityStatus;
    this.occupancy = occupancy;
    this.reservationTimeTo = screening.reservationTimeTo;
    this.saleTimeTo = screening.saleTimeTo;
    this.screeningTimeFrom = screening.screeningTimeFrom;
    this.screeningTimeTo = screening.screeningTimeTo;

    if (movieCopyModel) {
      // if is not event
      this.movieLanguage = movieCopyModel.language;
      this.moviePrintType = movieCopyModel.printType;
      this.movieSoundType = movieCopyModel.soundType;
      this.movieCopyRelease = movieCopyModel.release;
      this.movieSpeakingType = movieCopyModel.speakingType;

      const subtitles = movieCopyModel.subtitles;
      const subtitles2 = movieCopyModel.subtitles2;
      if (subtitles.length > 0) {
        this.movieSubtitles.push(subtitles);
      }

      if (subtitles2.length > 0) {
        this.movieSubtitles.push(subtitles2);
      }
    }

    return this;
  }
}
