import {Expose} from 'class-transformer';

export class SpeakingTypeApiModel {

  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

}
