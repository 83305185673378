import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, iif, noop, Observable, of } from 'rxjs';
import { AuthStateModel } from '../model/state/auth.state.model';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { map, switchMap, tap } from 'rxjs/operators';
import { DomainService } from '../service/domain.service';
import { UserDataProvider } from '../data-provider/user.data-provider';
import { ReservationHttpService } from '../http/reservation.http.service';
import { UserHttpService } from '../http/user.http.service';
import { UserApiModel } from '../model/api-model/user/user.api.model';
import { UserTokenResponseModel } from '../model/response/user-token.response.model';
import { StateService } from './state.service';
import { TokenStorageService } from '../service/token-storage.service';
import { SocialAuthService } from 'libs/shared/src/lib/module/auth/public-api';
import { UserLoginResponseModel } from '../model/response/user-login.response.model';
import { UserLoginRequestModel } from '../model/request/user-login.request.model';
import { appProjectName, cookieKey, storageKey } from '../../app.const';
import { AppService } from '../service/app.service';
import { getTopLevelDomain } from 'libs/shared/src/lib/function/custom-function';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService extends StateService {
  public state$: Observable<AuthStateModel>;
  public state = new BehaviorSubject<AuthStateModel>(null);
  private jwtHelperService: JwtHelperService;

  constructor(
    protected cookieService: CookieService,
    protected domainService: DomainService,
    protected userDataProvider: UserDataProvider,
    protected tokenStorageService: TokenStorageService,
    protected socialAuthService: SocialAuthService,
    protected appService: AppService
  ) {
    super(tokenStorageService, cookieService);
    this.jwtHelperService = new JwtHelperService();
    this.state$ = this.state.asObservable();
    if (!appService.isProject(appProjectName.HELIOS)) {
      this.retrieveUserToken();
    }
  }

  retrieveUserTokenFromCookie() {
    if (this.cookieService.get(cookieKey.external_user_token) && this.cookieService.get(cookieKey.external_user_r_token)) {
      const cookieToken = this.cookieService.get(cookieKey.external_user_token);

      this.userDataProvider.get(cookieToken).subscribe((user) => {
        this.setState(
          plainToInstance(AuthStateModel, {
            token: cookieToken,
            refreshToken: this.cookieService.get(cookieKey.external_user_r_token),
            user: user,
          })
        );
      });
    }
  }

  retrieveUserToken() {
    if (localStorage.getItem(storageKey.token)) {
      this.setState(plainToInstance(AuthStateModel, JSON.parse(localStorage.getItem(storageKey.token)) as Object));
    }
  }

  public setState(state: AuthStateModel) {
    if (state !== null) {
      localStorage.setItem(storageKey.token, JSON.stringify(instanceToPlain(state)));

      if (this.appService.isProject(appProjectName.HELIOS)) {
        this.setCookies(state);
      }
      this.state.next(state);
    }
  }

  private setCookies(state: AuthStateModel) {
    this.cookieService.set(
      cookieKey.external_user_token,
      state.token,
      this.jwtHelperService.getTokenExpirationDate(state.token),
      '/',
      getTopLevelDomain(window.location.hostname),
      true,
      'Lax'
    );

    this.cookieService.set(
      cookieKey.external_user_r_token,
      state.refreshToken,
      this.jwtHelperService.getTokenExpirationDate(state.token),
      '/',
      getTopLevelDomain(window.location.hostname),
      true,
      'Lax'
    );
  }

  public getToken(): string {
    if (this.appService.isProject(appProjectName.HELIOS)) {
      const token = this.cookieService.get(cookieKey.external_user_token);
      return token && !this.jwtHelperService.isTokenExpired(token) ? token : null;
    }

    return this.state.value?.token && !this.jwtHelperService.isTokenExpired(this.state.value.token) ? this.state.value?.token : null;
  }

  public userIsLoggedAndTokenIsValid(): boolean {
    return this.isLogged() && !this.jwtHelperService.isTokenExpired(this.state.value.token);
  }

  public refreshToken(): Observable<UserTokenResponseModel> {
    return this.userDataProvider
      .loginByToken({
        client_id: this.state.value.user.id,
        refresh_token: this.state.value.refreshToken,
        grant_type: 'refresh_token',
      })
      .pipe(
        tap((resultLogin) => {
          this.setState(
            plainToInstance(
              AuthStateModel,
              Object.create({
                token: resultLogin.token,
                refreshToken: resultLogin.refresh_token,
                user: this.state.value.user,
              }) as Object
            )
          );
        })
      );
  }

  public getUser(): UserApiModel {
    return this.state.value !== null ? this.state.value.user : null;
  }

  public setUser(user: UserApiModel) {
    this.state?.value ? (this.state.value.user = user) : noop();
    this.state.next(this.state.value);
  }

  public isLogged(): boolean {
    return this.state.value !== null;
  }

  isLogged$(): Observable<boolean> {
    return this.state$.pipe(
      map((m) => {
        return m && !this.jwtHelperService.isTokenExpired(m.token);
      })
    );
  }

  public login(requestModel: UserLoginRequestModel) {
    return this.userDataProvider.login(requestModel).pipe(switchMap((data) => iif(() => data !== null, this.handleToken(data), of(null))));
  }

  public logout() {
    this.state.next(null);

    // clearing cache
    this.removeUserSessionStorage();
    this.removeFromSessionStorage();
    this.clearAuthStorageAndCookies();

    this.socialAuthService.signOut();

    ReservationHttpService.cacheBuster$.next();
    UserHttpService.cacheBuster$.next();
  }

  public handleToken(response: UserLoginResponseModel) {
    return iif(
      () => response !== null,
      this.userDataProvider.get(response.token).pipe(
        tap((user) => {
          UserHttpService.cacheBuster$.next();
          this.setState(
            plainToInstance(
              AuthStateModel,
              Object.create({
                token: response.token,
                refreshToken: response.refresh_token,
                user: instanceToPlain(user),
              }) as Object
            )
          );
        })
      ),
      of(null)
    );
  }
}
