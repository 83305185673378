import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TranslateService } from '@ngx-translate/core';
import 'reflect-metadata';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLocaleData } from '@angular/common';

import en from '@angular/common/locales/en';
import enGB from '@angular/common/locales/en-GB';
import pl from '@angular/common/locales/pl';
import deCH from '@angular/common/locales/de-CH';
import frCH from '@angular/common/locales/fr-CH';
import arSA from '@angular/common/locales/ar-SA';
import localePlExtra from '@angular/common/locales/extra/pl';
import { ConfigService } from 'libs/core/src/lib/service/config-service';

declare var make_url: any;

registerLocaleData(en, 'en-US');
registerLocaleData(enGB, 'en-GB');
registerLocaleData(pl, 'pl-PL', localePlExtra);
registerLocaleData(frCH, 'fr-CH');
registerLocaleData(deCH, 'de-CH');
registerLocaleData(arSA, 'ar-SA');

const getCulture = (locale) => {
  const item = environment.constants.language.cultureMap.find((o) => o.key === locale);
  return item ? item.value : locale;
};

const APP_CONFIG_KEY = 'app_conf';
const APP_CONFIG_PATH = '/assets/config.json';
const startApp = (config) => {
  ConfigService.setEnvironment(config, environment);
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      providers: [
        {
          provide: LOCALE_ID,
          useFactory: (translate: TranslateService) => getCulture(translate.currentLang),
          deps: [TranslateService],
        },
      ],
    })
    .catch((err) => console.error(err));
};
const sd = sessionStorage.getItem(APP_CONFIG_KEY);
if (!!sd) {
  startApp(JSON.parse(sd));
} else {
  fetch(make_url(environment, APP_CONFIG_PATH))
    .then((response: Response) => response.json())
    .then((config) => {
      sessionStorage.setItem(APP_CONFIG_KEY, JSON.stringify(config));
      startApp(config);
    });
}
