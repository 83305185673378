import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataBuilderInterface } from 'libs/shared/src/lib/component/personal/interface/form-data.builder.interface';
import { PersonalComponent } from 'libs/shared/src/lib/component/personal/personal.component';
import { FormBuilder } from './form.builder';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
})
export class OneilPersonalComponent extends PersonalComponent implements OnInit {

  getFormGroup(formDataBuilderInterface: FormDataBuilderInterface): FormGroup {
    return new FormBuilder().getForm(formDataBuilderInterface);
  }
}
