import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SocialAuthServiceConfig } from 'libs/shared/src/lib/module/auth/public-api';
import { environment } from '../../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, make_url(environment, `/assets/i18n/`), '.json?t=' + Math.floor(new Date().getTime() / 3600000));
}

export function SocialAuthServiceConfigFactory() {
  return {
    autoLogin: false,
    providers: [],
    onError: (err) => {
      console.error(err);
    },
  } as SocialAuthServiceConfig;
}

export const appConfigFactory = () => environment;
