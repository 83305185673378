import { ModifierTypeEnum } from "../../enum/modifier-type.enum";

export class CateringAggregationViewModel {
  groups: Array<CateringAggregationArticleGroupViewModel> = new Array<CateringAggregationArticleGroupViewModel>();
  articles: Array<CateringAggregationArticleViewModel> = new Array<CateringAggregationArticleViewModel>();
}

export class CateringAggregationArticleGroupViewModel {
  id: string;
  name ?: string;
  description ?: string;
  parentGroup ?: CateringAggregationArticleGroupViewModel;
  graphicUrl ?: string;
  articles: Array<CateringAggregationArticleViewModel> = new Array<CateringAggregationArticleViewModel>();
}

export class CateringAggregationArticleViewModel {
  id: string;
  basketItemId?: string;
  parentGroup ?: CateringAggregationArticleGroupViewModel;
  name ?: string;
  price: number;
  taxRate: number;
  description ?: string;
  voucherName ?: string;
  voucherNumber ?: string;
  isLocked: boolean;
  graphicUrl ?: string;
  graphicIsLoaded = false;
  subArticleList: Array<CateringAggregationArticleViewModel> = new Array<CateringAggregationArticleViewModel>();
  replacementList: Array<CateringAggregationArticleViewModel> = new Array<CateringAggregationArticleViewModel>();
  modifierArticleList: Array<CateringAggregationArticleModifierViewModel> = new Array<CateringAggregationArticleModifierViewModel>();
  selectedQuantity: number | null = null;
  selectedCombinationHash: string | null = null;
}

export class CateringAggregationArticleModifierViewModel {
  id: string;
  type ?: string;
  name ?: string;
  isRequired: boolean;
  multiChoice: boolean;
  multiChoiceMax: number;
  multiChoiceMin: number;
  separateItem: boolean;
  itemCollection: Array<CateringAggregationArticleModifierItemViewModel> = new Array<CateringAggregationArticleModifierItemViewModel>();
}

export class CateringAggregationArticleModifierItemViewModel {
  id: string;
  name ?: string;
  description ?: string;
  price: number;
  type ?: string;
  quantity: number;
  relatedModifiers: Array<CateringAggregationArticleModifierViewModel> =
    new Array<CateringAggregationArticleModifierViewModel>();

  isNoTypeModifierItem(): boolean {
    return this.type === ModifierTypeEnum.NO_TYPE;
  }
}



