import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BasketDataProvider } from 'libs/core/src/lib/data-provider/basket.data-provider';
import { OrderDataProvider } from 'libs/core/src/lib/data-provider/order.data-provider';
import { AppService } from 'libs/core/src/lib/service/app.service';
import { CountdownComponentService } from 'libs/core/src/lib/service/countdown.service';
import { HeaderService } from 'libs/core/src/lib/service/header.service';
import { MessageService } from 'libs/core/src/lib/service/message.service';
import { NavigationHelperService } from 'libs/core/src/lib/service/navigation/navigation-helper.service';
import { ResponseValidatorService } from 'libs/core/src/lib/service/validator/response-validator.service';
import { VoucherService } from 'libs/core/src/lib/service/voucher.service';
import { CateringStateService } from 'libs/core/src/lib/state/catering.state.service';
import { OrderStateService } from 'libs/core/src/lib/state/order.state.service';
import { environment } from 'apps/oneil/src/environments/environment';
import { BasketPageComponent } from 'libs/shared/src/lib/page/basket/basket.component';
import { LoadingService } from 'libs/core/src/lib/service/loading.service';

@Component({
  selector: 'app-page-basket',
  templateUrl: './basket.component.html',
})
export class OneilBasketPageComponent extends BasketPageComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected orderStateService: OrderStateService,
    protected basketDataProvider: BasketDataProvider,
    protected orderDataProvider: OrderDataProvider,
    protected navigationHelperService: NavigationHelperService,
    protected messageService: MessageService,
    protected translate: TranslateService,
    protected countdownComponentService: CountdownComponentService,
    protected voucherService: VoucherService,
    protected responseValidatorService: ResponseValidatorService,
    protected modalService: BsModalService,
    protected appService: AppService,
    protected headerService: HeaderService,
    protected cateringStateService: CateringStateService,
    protected loadingService: LoadingService
  ) {
    super(
      environment,
      router,
      route,
      orderStateService,
      basketDataProvider,
      orderDataProvider,
      navigationHelperService,
      messageService,
      translate,
      countdownComponentService,
      voucherService,
      responseValidatorService,
      modalService,
      appService,
      headerService,
      cateringStateService,
      loadingService
    );
  }
}
