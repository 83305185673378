import { enableProdMode } from '@angular/core';

export abstract class ConfigService {
  public static setEnvironment(config, environment) {
    if (!config) return;

    environment.siteUrl = config.siteUrl ?? environment.siteUrl;
    environment.apiUrl = config.apiUrl ?? environment.apiUrl;
    environment.backUrl = config.backUrl ?? environment.backUrl;
    environment.homepageUrl = config.homepageUrl ?? environment.homepageUrl;
    environment.siteUrlSecond = config.siteUrlSecond ?? environment.siteUrlSecond;
    environment.virtualCinema = config.virtualCinema ?? environment.virtualCinema;
    environment.activeLogger = config.activeLogger ?? environment.activeLogger;

    if (config.constants) {
      environment.constants.clearMessageOnPageChange = config.constants.clearMessageOnPageChange ?? environment.constants.clearMessageOnPageChange;
      environment.constants.saveCurrentUrlToCookie = config.constants.saveCurrentUrlToCookie ?? environment.constants.saveCurrentUrlToCookie;
      environment.constants.moviePosterPlaceholder = config.constants.moviePosterPlaceholder ?? environment.constants.moviePosterPlaceholder;
      if (config.constants['baseMoviePoster']) {
        //backward compatibility
        environment.constants.moviePosterPlaceholder = config.constants['baseMoviePoster'];
      }
      environment.constants.moviePicturePlaceholder = config.constants.moviePicturePlaceholder ?? environment.constants.moviePicturePlaceholder;
      environment.constants.moviePosterPlaceholderOnError =
        config.constants.moviePosterPlaceholderOnError ?? environment.constants.moviePosterPlaceholderOnError;
      if (config.constants['baseMoviePosterOnError']) {
        //backward compatibility
        environment.constants.moviePosterPlaceholderOnError = config.constants['baseMoviePosterOnError'];
      }
      environment.constants.currency = config.constants.currency ?? environment.constants.currency;
      environment.constants.country = config.constants.country ?? environment.constants.country;
      environment.constants.countdownStartInit = config.constants.countdownStartInit ?? environment.constants.countdownStartInit;
      environment.constants.deleteOrderOnWindowUnload = config.constants.deleteOrderOnWindowUnload ?? false;
      environment.constants.paymentDebug = config.constants.paymentDebug ?? environment.constants.paymentDebug;
      environment.constants.defaultCinemaId = config.constants.defaultCinemaId ?? environment.constants.defaultCinemaId;
      environment.constants.defaultRegionId = config.constants.defaultRegionId ?? environment.constants.defaultRegionId;
      environment.constants.voucherSaleEnabled = config.constants.voucherSaleEnabled ?? environment.constants.voucherSaleEnabled;
      environment.constants.recaptcha = config.constants.recaptcha ?? environment.constants.recaptcha;
      environment.constants.paymentRedirectUrl = config.constants.paymentRedirectUrl ?? environment.constants.paymentRedirectUrl;
      if (config.constants.language) {
        environment.constants.language.default = config.constants.language.default ?? environment.constants.language.default;
        environment.constants.language.available = config.constants.language.available ?? environment.constants.language.available;
        environment.constants.language.cultureMap = config.constants.language.cultureMap ?? environment.constants.language.cultureMap;

        if (config.constants.language.displaySwitcher !== null) {
          environment.constants.language.displaySwitcher = config.constants.language.displaySwitcher;
        }
      }
      environment.constants.phoneProviderNumbers = config.constants.phoneProviderNumbers ?? environment.constants.phoneProviderNumbers;
      environment.constants.refundAllowTimeBeforeScreening =
        config.constants.refundAllowTimeBeforeScreening ?? environment.constants.refundAllowTimeBeforeScreening;
      if (config.constants['skip2fa']) {
        environment.constants['skip2fa'] = config.constants['skip2fa'];
      }
      if (config.constants['userDataChecklist']) {
        environment.constants['userDataChecklist'] = config.constants['userDataChecklist'];
      }
      if (config.constants['maxSelectedTickets']) {
        environment.constants['maxSelectedTickets'] = config.constants['maxSelectedTickets'];
      }

      if (config.constants['showCinemaSwitcher'] === false) {
        environment.constants['showCinemaSwitcher'] = false;
      } else {
        environment.constants['showCinemaSwitcher'] = true;
      }

      environment.constants.reservationTimeControlMode = config.constants.reservationTimeControlMode ?? environment.constants.reservationTimeControlMode;
      environment.constants.enableFB = config.constants.enableFB ?? environment.constants.enableFB;
      environment.constants.enableTicketsDescription = config.constants.enableTicketsDescription ?? environment.constants.enableTicketsDescription;
      environment.constants.standardSeatGroupColor = config.constants.standardSeatGroupColor ?? environment.constants.standardSeatGroupColor;
    }

    if (config.tagManager) {
      environment.tagManager.providers = config.tagManager.providers ?? environment.tagManager.providers;
    }

    if (config.screen) {
      environment.screen.enableSeatTooltip = config.screen.enableSeatTooltip ?? environment.screen.enableSeatTooltip;
      environment.screen.showPopupOnSelectedSeat = config.screen.showPopupOnSelectedSeat ?? environment.screen.showPopupOnSelectedSeat;
      environment.screen.reduceRequestsForScreeningItems = config.screen.reduceRequestsForScreeningItems ?? environment.screen.reduceRequestsForScreeningItems;
      environment.screen.reduceRequestsForTicket = config.screen.reduceRequestsForTicket ?? environment.screen.reduceRequestsForTicket;
      environment.screen.periodBetweenRequestsInScreen = config.screen.periodBetweenRequestsInScreen ?? environment.screen.periodBetweenRequestsInScreen;
      environment.screen.removeOrderWhenUserLeavePage = config.screen.removeOrderWhenUserLeavePage ?? environment.screen.removeOrderWhenUserLeavePage;
    }

    if (config.pages) {
      if (config.pages.screening) {
        environment.pages.screening.defaultStartDate = config.pages.screening.defaultStartDate ?? environment.pages.screening.defaultStartDate;
      }

      if (environment.pages['mvoucher'] && config.pages.mvoucher) {
        environment.pages['mvoucher'].bundles = config.pages.mvoucher.bundles ?? environment.pages['mvoucher'].bundles;
        environment.pages['mvoucher'].timer = config.pages.mvoucher.timer ?? environment.pages['mvoucher'].timer;
      }

      if (config.pages.screen?.legend?.asPopup) {
        environment.pages.screen.legend.asPopup = config.pages.screen.legend.asPopup ?? environment.pages.screen.legend.asPopup;
      }
    }

    if (config['scripts']) {
      environment['scripts'] = Object.assign(environment['scripts'] ?? {}, config['scripts']);
    }

    if (config['brightcove']) {
      environment['brightcove'] = Object.assign(environment['brightcove'] ?? {}, config['brightcove']);
    }

    if (config['cms']) {
      if (config['cms']['apiUrl']) {
        environment['cms']['apiUrl'] = config['cms']['apiUrl'];
      }
      if (config['cms']['params']) {
        environment['cms']['params'] = Object.assign(environment['cms']['params'] ?? {}, config['cms']['params']);
      }
      if (config['cms']['routes']) {
        environment['cms']['routes'] = Object.assign(environment['cms']['routes'] ?? {}, config['cms']['routes']);
      }
    }

    if (config['loader']) {
      if (config['loader']['backdropBorderRadius']) {
        environment['loader']['backdropBorderRadius'] = config['loader']['backdropBorderRadius'];
      }
      if (config['loader']['backdropBackgroundColour']) {
        environment['loader']['backdropBackgroundColour'] = config['loader']['backdropBackgroundColour'];
      }
      if (config['loader']['fullScreenBackdrop']) {
        environment['loader']['fullScreenBackdrop'] = config['loader']['fullScreenBackdrop'];
      }
      if (config['loader']['animationType']) {
        environment['loader']['animationType'] = config['loader']['animationType'];
      }
      if (config['loader']['primaryColour']) {
        environment['loader']['primaryColour'] = config['loader']['primaryColour'];
      }
      if (config['loader']['secondaryColour']) {
        environment['loader']['secondaryColour'] = config['loader']['secondaryColour'];
      }
      if (config['loader']['tertiaryColour']) {
        environment['loader']['tertiaryColour'] = config['loader']['tertiaryColour'];
      }
      if (config['loader']['style']) {
        environment['loader']['style'] = config['loader']['style'];
      }
      if (config['loader']['size']) {
        environment['loader']['size'] = config['loader']['size'];
      }
      if (config['loader']['lottie']) {
        environment['loader']['lottie'] = config['loader']['lottie'];
      }
    }

    if (config['externalAuthProviders']) {
      if (config['externalAuthProviders']['google']) {
        environment['externalAuthProviders']['google'] = config['externalAuthProviders']['google'];
      }
      if (config['externalAuthProviders']['facebook']) {
        environment['externalAuthProviders']['facebook']['clientId'] =
          config['externalAuthProviders']['facebook']['clientId'] ?? environment['externalAuthProviders']['facebook']['clientId'];
        environment['externalAuthProviders']['facebook']['supportedLocales'] =
          config['externalAuthProviders']['facebook']['supportedLocales'] ?? environment['externalAuthProviders']['facebook']['supportedLocales'];
      }
      if (config['externalAuthProviders']['apple']) {
        environment['externalAuthProviders']['apple']['clientId'] =
          config['externalAuthProviders']['apple']['clientId'] ?? environment['externalAuthProviders']['apple']['clientId'];
        environment['externalAuthProviders']['apple']['supportedLocales'] =
          config['externalAuthProviders']['apple']['supportedLocales'] ?? environment['externalAuthProviders']['apple']['supportedLocales'];
      }
    }

    if (config['postprocesorUrl']) {
      environment['postprocesorUrl'] = config['postprocesorUrl'];
    }

    if (config['additionalNotification']) {
      if (config['additionalNotification']['requiredTicketId']) {
        environment['additionalNotification']['requiredTicketId'] = config['additionalNotification']['requiredTicketId'];
      }
      if (config['additionalNotification']['messages']) {
        environment['additionalNotification']['messages'] = config['additionalNotification']['messages'];
      }
    }

    this.initialize(environment);
  }

  public static initialize(environment) {
    if (environment.production) {
      enableProdMode();
      if (!environment.activeLogger) {
        window.console.log = function () {}; // disable any console.log debugging statements in production mode
        window.console.warn = function () {}; // disable any console.warn debugging statements in production mode
        // window.console.error = function () { };
      }
    }
  }
}
