import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OrderHttpService } from '../http/order.http.service';
import { VoucherHttpService } from '../http/voucher.http.service';
import { VoucherApiModel } from '../model/api-model/voucher/voucher.api.model';
import { VoucherViewModel } from '../model/view-model/voucher/voucher.view.model';
import { OrderDataProvider } from './order.data-provider';
import { VoucherPurchaseViewModel } from '../model/view-model/voucher/voucher-purchase.view.model';

@Injectable({
  providedIn: 'root',
})
export class VoucherDataProvider {
  constructor(private voucherHttpService: VoucherHttpService, private orderDataProvider: OrderDataProvider) {}

  public getVoucher(cinemaId: string, voucherNumber: string): Observable<VoucherViewModel> {
    return this.voucherHttpService.getVoucher(cinemaId, voucherNumber).pipe(map((result: VoucherApiModel) => new VoucherViewModel(result)));
  }

  putVoucherToOrder(cinemaId: string, orderId: string, voucherNumber: string, limitUsage?: number) {
    return this.voucherHttpService.putVoucherToOrder(cinemaId, orderId, { voucherNumber, limitUsage }).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  removeVoucherFromOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.voucherHttpService.removeVoucherFromOrder(cinemaId, orderId, voucherNumber).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      mergeMap(() => this.orderDataProvider.findById(cinemaId, orderId))
    );
  }

  removeVoucherFromOrderItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.voucherHttpService.removeVoucherFromOrderItem(cinemaId, orderId, itemId, voucherNumber);
  }

  assignVoucherToOrderItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.voucherHttpService.assignVoucherToOrderItem(cinemaId, orderId, itemId, voucherNumber);
  }

  info(cinemaId: string, voucherNumber: string): Observable<VoucherViewModel> {
    return this.voucherHttpService.info(cinemaId, voucherNumber).pipe(map((result: VoucherApiModel) => new VoucherViewModel(result)));
  }

  getVouchersToPurchase(cinemaId: string): Observable<VoucherPurchaseViewModel[]> {
    return this.voucherHttpService.getVouchersToPurchase(cinemaId).pipe(map((result) => result.map((m) => new VoucherPurchaseViewModel(m))));
  }
}
