import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'libs/shared/src/lib/component/cart/service/cart.service';
import { ScreenNavigationComponent } from 'libs/shared/src/lib/component/screen/navigation/navigation.component';
import { environment } from 'apps/oneil/src/environments/environment';
import { LoadingConfig } from 'libs/core/src/lib/model/loading/loading-config';

@Component({
  selector: 'app-screen-navigation',
  templateUrl: './navigation.component.html',
})
export class OneilScreenNavigationComponent extends ScreenNavigationComponent implements OnInit, OnDestroy {
  constructor(protected cartService: CartService, protected route: ActivatedRoute) {
    super(environment, cartService, route);
  }

  config: LoadingConfig = {};
}
